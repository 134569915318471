import { API_URL } from '../config/connect';

export const submitSmsCode = async (smsCode, taskId, phoneNumber) => {
    try {
        const response1 = await fetch(`${API_URL}/api/v1/auth/mfa/${taskId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ sms_code: smsCode, taskId, phoneNumber }),
        });

        if (!response1.ok) {
            const errorData = await response1.json();
            throw new Error(errorData.message || 'Ошибка при верификации SMS-кода');
        }

        const data1 = await response1.json();
        return data1.task_id;
    } catch (error) {
        console.error('Ошибка верификации SMS-кода:', error.message);
        throw error;
    }
};

export const loginWithTaskId = async (task_id_from_response1, typeAuth) => {
    try {
        const response2 = await fetch(`${API_URL}/api/v1/auth/${typeAuth}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'task_id': task_id_from_response1,
            },
        });

        if (!response2.ok) {
            const errorData = await response2.json();
            throw new Error(errorData.message || 'Ошибка при выполнении запроса на вход');
        }

        const data2 = await response2.json();

        // Handle signup-specific logic
        if (typeAuth === 'signup' && data2.task_id) {
            console.log('Signup task_id:', data2.task_id);

            // Make the login request using the new task_id
            const loginResponse = await fetch(`${API_URL}/api/v1/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'task_id': data2.task_id, // Use the new task_id
                },
            });

            if (!loginResponse.ok) {
                const loginErrorData = await loginResponse.json();
                throw new Error(loginErrorData.message || 'Ошибка при выполнении запроса на вход после регистрации');
            }

            const loginData = await loginResponse.json();
            return loginData;
        }

        return data2;
    } catch (error) {
        console.error('Ошибка входа:', error.message);
        throw error;
    }
};

export const sendSms = async (taskId) => {
    try {
        const response = await fetch(
            `${API_URL}/api/v1/auth/mfa/${taskId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );

        const data = await response.json();

        if (!response.ok) {
            const errorMessage = data?.error?.message || 'Ошибка при отправке SMS';
            throw new Error(errorMessage);
        }
    } catch (err) {
    }
};

export const saveTokens = (access_token, refresh_token, biometric_token) => {
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
    localStorage.setItem('biometric_token', biometric_token);
};