import axios from 'axios';
import { API_URL } from "../config/connect";

export const getFilterAnnouncements = async (filters = {}) => {
    try {
        const queryParams = new URLSearchParams();

        if (filters.q) queryParams.append('q', filters.q);
        if (filters.sort_by_date) queryParams.append('sort_by_date', filters.sort_by_date);
        if (filters.sort_by_price) queryParams.append('sort_by_price', filters.sort_by_price);
        if (filters.is_vip !== undefined) queryParams.append('is_vip', filters.is_vip);
        if (filters.category_id) queryParams.append('category_id', filters.category_id);
        if (filters.subcategory_id) queryParams.append('subcategory_id', filters.subcategory_id);
        if (filters.country) queryParams.append('country', filters.country);
        if (filters.province) queryParams.append('province', filters.province);
        if (filters.locality) queryParams.append('locality', filters.locality);
        if (filters.area) queryParams.append('area', filters.area);
        if (filters.latitude) queryParams.append('latitude', filters.latitude);
        if (filters.longitude) queryParams.append('longitude', filters.longitude);
        if (filters.min_price) queryParams.append('min_price', filters.min_price);
        if (filters.max_price) queryParams.append('max_price', filters.max_price);
        if (filters.negotiable !== undefined) queryParams.append('negotiable', filters.negotiable);
        if (filters.barter !== undefined) queryParams.append('barter', filters.barter);
        if (filters.page) queryParams.append('page', filters.page);
        if (filters.limit) queryParams.append('limit', filters.limit);

        const response = await axios.get(`${API_URL}/api/v1/announcements?${queryParams.toString()}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching filtered announcements:', error);
        throw error;
    }
};