import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import '../style/MainImageSlider.css';
import leftArrow from '../../../assets/icons/leftarrow.svg';
import rightArrow from '../../../assets/icons/rightarrow.svg';
import activeDotIcon from '../../../assets/icons/activedot.svg';
import defDotIcon from '../../../assets/icons/defdot.svg';
import eyeIcon from '../../../assets/icons/eye.svg';
import placeholderImage from '../../../assets/images/noimage.png';

import Icon from '../../../common/components/Icon';
import { t } from 'i18next';

const MainImageSlider = ({ id, images = [], description, views, videoUrl, isMobile, isLoading, handlePublish }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imageError, setImageError] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const touchStartX = useRef(0);

    const handleNext = () => {
        if (images.length > 0) {
            setCurrentImageIndex((currentImageIndex + 1) % images.length);
            setImageError(false);
        }
    };

    const handlePrevious = () => {
        if (images.length > 0) {
            setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
            setImageError(false);
        }
    };

    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = (e) => {
        const touchEndX = e.changedTouches[0].clientX;
        if (touchStartX.current - touchEndX > 50) {
            handleNext();
        } else if (touchEndX - touchStartX.current > 50) {
            handlePrevious();
        }
    };

    const extractYouTubeEmbedUrl = (url) => {
        const videoIdMatch = url.match(/[?&]v=([^&#]+)/);
        return videoIdMatch ? `https://www.youtube.com/embed/${videoIdMatch[1]}` : null;
    };

    const embedUrl = videoUrl ? extractYouTubeEmbedUrl(videoUrl) : null;

    return (
        <div
            className="main_slider_container"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
        >
            <img
                src={
                    imageError || images.length === 0
                        ? placeholderImage
                        : images[currentImageIndex]
                }
                alt={`Slide ${currentImageIndex + 1}`}
                className="main_image_slide"
                onError={() => setImageError(true)}
            />
            <div className="dots">
                {images.length > 0 ? (
                    images.map((_, index) => (
                        <img
                            key={index}
                            src={index === currentImageIndex ? activeDotIcon : defDotIcon}
                            alt={`dot-${index}`}
                            className={`dot ${index === currentImageIndex ? 'active' : ''}`}
                            onClick={() => {
                                setCurrentImageIndex(index);
                                setImageError(false);
                            }}
                            aria-label={`Slide ${index + 1}`}
                        />
                    ))
                ) : (
                    <img src={activeDotIcon} alt="dot-placeholder" className="dot active" />
                )}
            </div>
            <div className="image_nav">
                <img
                    className="click left_image"
                    src={leftArrow}
                    onClick={handlePrevious}
                    alt="Previous"
                    aria-label="Previous image"
                />
                <img
                    className="click right_image"
                    src={rightArrow}
                    onClick={handleNext}
                    alt="Next"
                    aria-label="Next image"
                />
            </div>
            {embedUrl && (
                <>
                    <Icon
                        src={"play"}
                        className={"ad_video_play_icon"}
                        onClick={() => setIsModalOpen(true)}
                    />
                    {isModalOpen && (
                        <div className="video_modal">
                            <div className="video_container">
                                <iframe
                                    className='video_frame'
                                    src={embedUrl}
                                    frameBorder="10"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="YouTube Video"
                                />
                                <button onClick={() => setIsModalOpen(false)} className="close_modal">
                                    <Icon src='close' />
                                </button>
                            </div>
                        </div>
                    )}
                </>
            )}
            {!isMobile &&
                <div className="ad_description">
                    <h3>{t('description')}</h3>
                    <p>{description || "Нет описания"}</p>
                    <div className="ad_views">
                        <div className='ad_views'>
                            <img src={eyeIcon} alt='eye' />
                            <span>{t('views', { views })}</span>
                        </div>
                        <span>ID: {id}</span>
                    </div>
                </div>
            }
        </div>
    );
};

MainImageSlider.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    views: PropTypes.number,
    videoUrl: PropTypes.string,
};

export default MainImageSlider;