import React from "react";
import Icon from "../../../common/components/Icon";
import deleteIcon from '../../../assets/icons/delete.svg';

function AddressList({ userLocations, onDeleteAddress }) {
    return (
        <div className="address_list_container">
            {userLocations?.map((location, index) => (
                <React.Fragment key={location.address_id || index}>
                    <div className="address_item">
                        <Icon src="marker" />
                        <div className="address_item_text">
                            <p className="address_item_text_1">
                                {location.locality || ''}, {location.province || ''}
                            </p>
                            <p className="address_item_text_2">{location.province || ''}</p>
                        </div>
                        <img src={deleteIcon} alt="delete" className="click" onClick={(e) => {
                            e.stopPropagation();
                            onDeleteAddress(location.address_id);
                        }} />
                    </div>
                    {index < userLocations.length - 1 && <hr className="divider" />}
                </React.Fragment>
            ))}
        </div>
    );
}

export default AddressList;