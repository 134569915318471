import { API_URL } from "../config/connect";

async function getUserFavorites() {
    try {
        const accessToken = localStorage.getItem("access_token");

        if (!accessToken) {
            return;
        }

        const response = await fetch(`${API_URL}/api/v1/favorites`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error(`Error fetching favorites: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error in getUserFavorites:", error);
        throw error;
    }
}

async function addToFavorites(announcementId) {
    try {
        const accessToken = localStorage.getItem("access_token");

        if (!accessToken) {
            return;
        }

        const response = await fetch(`${API_URL}/api/v1/favorites/${announcementId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            const errorDetails = await response.json();
            throw new Error(`Error adding to favorites: ${errorDetails.message || response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error in addToFavorites:", error);
        throw error;
    }
}

async function removeFromFavorites(announcementId) {
    try {
        const accessToken = localStorage.getItem("access_token");

        if (!accessToken) {
            return;
        }

        const response = await fetch(`${API_URL}/api/v1/favorites/${announcementId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error(`Error removing from favorites: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error in removeFromFavorites:", error);
        throw error;
    }
}

async function getFavoriteStatus(announcementId) {
    try {
        const accessToken = localStorage.getItem("access_token");

        if (!accessToken) {
            return;
        }

        const response = await fetch(`${API_URL}/api/v1/favorites/${announcementId}/status`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error(`Error fetching favorites: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error in getFavoriteStatus:", error);
        throw error;
    }
}

export { getUserFavorites, addToFavorites, removeFromFavorites, getFavoriteStatus };