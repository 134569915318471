import { API_URL } from "../config/connect";

export const signup = async (name, phoneNumber, user_type, bin) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/auth/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, phone_number: phoneNumber, user_type: user_type, company_bin: bin || null }),
        });

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data?.error?.message || 'Ошибка при регистрации пользователя');
        }

        return data;
    } catch (error) {
        throw error;
    }
};