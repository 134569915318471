import React from "react";

import { t } from "i18next";

export default function individualTabs(activeTabV, setActiveTabV) {
    return <>
        <button
            className={`tab_button ${activeTabV === "personal" ? "active" : ""}`}
            onClick={() => setActiveTabV("personal")}
        >
            {t('personalData')}
        </button>
        <button
            className={`tab_button ${activeTabV === "address" ? "active" : ""}`}
            onClick={() => setActiveTabV("address")}
        >
            {t('addresses')}
        </button>
    </>;
}