import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../main/components/Header';
import LeftSections from '../components/LeftSection';
import CategoryHeader from '../components/CategoryHeader';
import SearchBar from '../components/SearchBar';
import Result from '../components/Result';
import Pagination from '../components/Pagination';
import Fooder from '../../main/components/Fooder';
import Path from '../../path/ui/Path';
import { getFilterAnnouncements } from '../../../api/getFilterAnnouncements';
import '../style/Main.css';
import SearchStart from '../../modals/search_modal/components/SearchStart';
import MobileBottomBar from '../../main/components/MobileBottomBar';
import CategoryModal from '../../modals/category_modal/ui/Main';
import LocationModal from '../../modals/location_modal/ui/Main';
import FilterModal from '../../modals/filter_modal/ui/Main';
import Icon from '../../../common/components/Icon';

function Main() {
    const { category_name, subcategory_name, category_id, subcategory_id } = useParams();

    // States for filters and items
    const [searchQuery, setSearchQuery] = useState('');
    const [sortOption, setSortOption] = useState('');
    const [filters, setFilters] = useState({
        minPrice: 100,
        maxPrice: 10000,
        negotiable: false,
        barter: false,
        country: '',
        province: '',
        locality: '',
        area: '',
        latitude: '',
        longitude: ''
    });
    const [filteredItems, setFilteredItems] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [categoryId, setCategoryId] = useState();
    const [subCategoryId, setSubCategoryId] = useState();
    const itemsPerPage = 12;

    // Modals and Mobile
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
    const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

    // Update `isMobile` on resize
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [onSelect, setOnSelect] = useState(
        category_name && subcategory_name ? `${category_name}, ${subcategory_name}` : null
    );

    const fetchItems = useCallback(async () => {
        const appliedFilters = {
            ...filters,
            q: searchQuery,
            sort_by_date: sortOption === 'date' ? 'asc' : '',
            sort_by_price: sortOption === 'price' ? 'asc' : '',
            category_id: categoryId || category_id,
            subcategory_id: subCategoryId || subcategory_id,
            page: currentPage,
            limit: itemsPerPage,
            max_price: filters.maxPrice,
            min_price: filters.minPrice,
            negotiable: filters.negotiable,
            barter: filters.barter
        };

        try {
            const { items, total } = await getFilterAnnouncements(appliedFilters);
            setFilteredItems(items);
            setTotalItems(total);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    }, [filters, searchQuery, sortOption, categoryId, subCategoryId, category_id, subcategory_id, currentPage]);

    useEffect(() => {
        fetchItems();
    }, [fetchItems]);

    // Handlers
    const handleSearch = useCallback((query) => {
        setSearchQuery(query);
        setCurrentPage(1);
    }, []);

    const handlePriceChange = (min, max) => {
        setFilters((prev) => ({ ...prev, minPrice: min, maxPrice: max }));
    };

    const handleToggleChange = (field, value) => {
        setFilters((prev) => ({ ...prev, [field]: value }));
    };

    const resetFilters = () => {
        setFilters({
            minPrice: '',
            maxPrice: '',
            negotiable: false,
            barter: false,
            country: '',
            province: '',
            locality: '',
            area: '',
            latitude: '',
            longitude: ''
        });
        setSearchQuery('');
        setSortOption('');
        setOnSelect(null);
        setCurrentPage(1);
    };

    const handleLocationChange = (selectedLocation) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            country: selectedLocation.country || '',
            province: selectedLocation.province || '',
            locality: selectedLocation.locality || '',
            area: selectedLocation.area || '',
            latitude: selectedLocation.latitude || '',
            longitude: selectedLocation.longitude || ''
        }));
        setCurrentPage(1);
    };

    return (
        <div className="def_background">
            <Header />
            <Path currentPath={`${category_name || ''}/${subcategory_name || ''}`} />
            {isMobile && <CategoryHeader header_text={category_name} />}
            <div className="main_category_content">
                <LeftSections
                    openCategoryModal={() => setIsCategoryModalOpen(true)}
                    openLocationModal={() => setIsLocationModalOpen(true)}
                    selectedCategory={onSelect}
                    resetFilters={resetFilters}
                    minPrice={filters.minPrice}
                    maxPrice={filters.maxPrice}
                    onPriceChange={handlePriceChange}
                    isContract={filters.negotiable}
                    isBarter={filters.barter}
                    onContractToggle={(value) => handleToggleChange('negotiable', value)}
                    onBarterToggle={(value) => handleToggleChange('barter', value)}
                />
                <button className="open_modal_button open_modal_button_filter" onClick={() => setIsFilterModalOpen(true)}>
                    Фильтры<Icon src="filter" alt="filter" />
                </button>
                {isMobile && <SearchBar onSearch={handleSearch} onSort={setSortOption} />}
                <div className="main_category_right_section">
                    {!isMobile && <CategoryHeader header_text={category_name} />}
                    {!isMobile && <SearchBar onSearch={handleSearch} onSort={setSortOption} />}
                    {filteredItems.length > 0 ? (
                        <Result items={filteredItems} />
                    ) : (
                        <SearchStart />
                    )}
                    {totalItems > itemsPerPage && (
                        <Pagination
                            currentPage={currentPage}
                            totalPages={Math.ceil(totalItems / itemsPerPage)}
                            onPageChange={setCurrentPage}
                        />
                    )}
                </div>
            </div>
            <div className="fooder">
                <Fooder />
            </div>
            <MobileBottomBar />
            {isCategoryModalOpen && (
                <CategoryModal
                    onSelect={(selected) => {
                        setOnSelect(`${selected.category.name}, ${selected.subcategory.name}`);
                        setCategoryId(selected.category.id);
                        setSubCategoryId(selected.subcategory.id);
                    }}
                    onClose={() => setIsCategoryModalOpen(false)}
                />
            )}
            {isLocationModalOpen && (
                <LocationModal
                    menuVisible={isLocationModalOpen}
                    toggleMenu={() => setIsLocationModalOpen(false)}
                    mode={"select"}
                    onSubmitted={(selectedLocation) => handleLocationChange(selectedLocation)}
                />
            )}
            {isFilterModalOpen && (
                <FilterModal
                    openCategoryModal={() => setIsCategoryModalOpen(true)}
                    openLocationModal={() => setIsLocationModalOpen(true)}
                    selectedCategory={onSelect}
                    onClose={() => setIsFilterModalOpen(false)}
                    onApplyFilters={(appliedFilters) => {
                        setFilters(appliedFilters);
                        setIsFilterModalOpen(false);
                        setCurrentPage(1);
                    }}
                    resetFilters={resetFilters}
                    minPrice={filters.minPrice}
                    maxPrice={filters.maxPrice}
                    isContract={filters.negotiable}
                    isBarter={filters.barter}
                />
            )}
        </div>
    );
}

export default Main;