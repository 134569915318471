import { getUserInfo } from "../../../api/usercall";
import { t } from "i18next";

export function getUserData(userData, UserLocations, setUserData, setUserLocations) {
    return async () => {
        if (userData && UserLocations) return;
        try {
            const response = await getUserInfo();
            if (response?.user) setUserData(response.user);
            if (response?.locations) setUserLocations(response.locations);
        } catch (error) {
            console.error(t('failed_to_fetch_user_data'), error);
        }
    };
}