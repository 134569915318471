import { API_URL } from "../config/connect";

export async function postAnnouncement(formData, userId) {
    try {
        const accessToken = localStorage.getItem("access_token");

        if (!accessToken) {
            console.error("Access token not found. User is not authenticated.");
            return null;
        }

        const response = await fetch(`${API_URL}/api/v1/announcements`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
            body: formData,
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error: ${response.statusText}, ${errorText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error posting announcement:", error);
        throw error;
    }
}