import React, { useEffect, useState, useRef } from "react";
import "../style/ChatConversation.css";

import sendPaperIcon from "../../../assets/icons/paperplane.svg";
import fileIcon from "../../../assets/icons/paperclip.svg";
import user_profile_photo from "../../images/def_user_icon.svg";
import menu_dot from '../../../assets/icons/dots_three_outline_vertical.svg';

const ChatConversation = ({ chat, currentUserId, socketInstance, closeModal }) => {
  const [messages, setMessages] = useState([]);
  const [roomName, setRoomName] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    if (socketInstance && chat?.room_id) {
      socketInstance.emit("join_chat", { room_id: chat.room_id });

      const handleJoinChatSuccess = (data) => {
        setMessages(data.messages || []);
        setRoomName(data.room_name);
      };

      const handleNewMessage = (message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
      };

      socketInstance.on("join_chat_success", handleJoinChatSuccess);
      socketInstance.on("new_message", handleNewMessage);

      return () => {
        socketInstance.off("join_chat_success", handleJoinChatSuccess);
        socketInstance.off("new_message", handleNewMessage);
      };
    }
  }, [socketInstance, chat]);

  useEffect(() => {
    socketInstance.on("new_message", (message) => {
      if (message.room_id === chat?.room_id) {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
    });

    return () => {
      socketInstance.off("new_message");
    };
  }, [chat?.room_id]);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      const message = {
        message: newMessage,
        sender_id: currentUserId,
        timestamp: new Date().toISOString(),
      };

      socketInstance.emit("chat_message", {
        room_id: chat.room_id,
        room_name: roomName,
        message: message.message,
      });

      setMessages((prevMessages) => [...prevMessages, message]);
      setNewMessage("");
    }
  };

  const messagesContainerRef = useRef(null);

  const autoScroll = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    autoScroll();
  }, [messages]);

  const groupMessagesByDate = (messages) => {
    return messages.reduce((groups, message) => {
      const date = new Date(message.timestamp).toLocaleDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  };

  const groupedMessages = groupMessagesByDate(messages);

  const formatDateHeader = (dateString) => {
    const messageDate = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const daysOfWeek = ["Жексенбі", "Дүйсенбі", "Сейсенбі", "Сәрсенбі", "Бейсенбі", "Жұма", "Сенбі"];
    const months = [
      "Қаңтар", "Ақпан", "Наурыз", "Сәуір", "Мамыр", "Маусым",
      "Шілде", "Тамыз", "Қыркүйек", "Қазан", "Қараша", "Желтоқсан"
    ];

    if (messageDate.toDateString() === today.toDateString()) {
      return "Бүгін";
    } else if (messageDate.toDateString() === yesterday.toDateString()) {
      return "Кеше";
    } else if (messageDate > new Date(today.setDate(today.getDate() - today.getDay()))) {
      return daysOfWeek[messageDate.getDay()];
    } else if (messageDate.getFullYear() === today.getFullYear()) {
      return `${messageDate.getDate()} ${months[messageDate.getMonth()]}`;
    } else {
      return `${messageDate.getFullYear()}-${String(messageDate.getMonth() + 1).padStart(2, '0')}-${String(messageDate.getDate()).padStart(2, '0')}`; // e.g., 2024-01-15
    }
  };

  return (
    <div className="chat-conversation">
      <header className="chat-header">
        <div className="chat_header_left">
          <img src={user_profile_photo} alt={chat.receiver_name} className="avatar" onClick={closeModal} />
          <h3>{chat.receiver_name}</h3>
        </div>
        <div className="chats_header_search_menu_button click">
          <img src={menu_dot} />
        </div>
      </header>

      <div className="messages_list" ref={messagesContainerRef}>
        {Object.keys(groupedMessages).map((date) => (
          <div className="messages" key={date}>
            <div className="date-header">{formatDateHeader(date)}</div>
            {groupedMessages[date].map((msg, index) => (
              <div
                key={index}
                className={`message ${msg.sender_id === currentUserId ? "sent-message" : "received-message"
                  }`}
              >
                <span>{msg.message}</span>
                <time className="timestamp">
                  {new Date(msg.timestamp).toLocaleTimeString()}
                </time>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="chat-input-container">
        <button className="file-icon">
          <img src={fileIcon} alt="Attach file" />
        </button>

        <input
          type="text"
          className="message-input"
          placeholder="Написать сообщение..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />

        <button className="send-button" onClick={handleSendMessage}>
          <img src={sendPaperIcon} alt="Send message" />
        </button>
      </div>
    </div>
  );
};

export default ChatConversation;