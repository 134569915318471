import React from "react";

import { t } from "i18next";
import TextInput from "../../elements/TextInput/TextInput";

export default function ContactsInfo(companyInfo, userInfo) {
    return <div className="menu_right_section_package content tab_content">
        <h2>{t('contacts')}</h2>
        <hr className="divider"></hr>
        <div className="personal_data_form_content">
            {/* <form className="personal_data_form" onSubmit={handleSubmit}> */}
            <form className="personal_data_form">
                <TextInput
                    isRequired={true}
                    // onInputChange={handleDescriptionChange}
                    // isSubmitted={isSubmitted}
                    errorText={t("required_text")}
                    labelText={t("description")}
                    placeholder={t("description")}
                    storageKey="description"
                // value={description} 
                />
                <hr className="divider"></hr>
                <TextInput
                    isRequired={true}
                    // onInputChange={handleDescriptionChange}
                    // isSubmitted={isSubmitted}
                    errorText={t("required_text")}
                    labelText={t("Telegram")}
                    placeholder={t("description")}
                    storageKey="description"
                // value={description} 
                />
                <hr className="divider"></hr>
                <TextInput
                    isRequired={true}
                    // onInputChange={handleDescriptionChange}
                    // isSubmitted={isSubmitted}
                    errorText={t("required_text")}
                    labelText={t("WhatsApp")}
                    placeholder={t("description")}
                    storageKey="description"
                // value={description} 
                />
                <hr className="divider"></hr>
                <button type="submit" className="save_button">
                    {t("save")}
                </button>
            </form>
        </div>
    </div>;
}