import React from "react";
import image1 from "../../../assets/images/1.png";
import image2 from "../../../assets/images/2.png";
import image3 from "../../../assets/images/logo.jpg";

const AboutUs = () => (
    <main className="about_content">
        <section className="content">
            <h1 className="m_0">О компании</h1>
        </section>
        <section id="mission" className="section content">
            <h2>Наша миссия</h2>
            <img src={image1} alt="Field view" className="section-image" />
            <p>
                Наша миссия – развивать сельское хозяйство через внедрение цифровых технологий, объединить между собой фермерские хозяйства всего Казахстана, Киргизии, России и Китая, установить прочные торговые связи между производителями сельхозпродукции, поставщиками техники, удобрений и т.д. с торговыми сетями, потребителями продукции.
            </p>
            <hr className="divider"></hr>
            <section id="project" className="section">
                <h2>Наш проект</h2>
                <img src={image2} alt="Partnership" className="section-image" />
                <p>Наш интернет-проект призван объединить производителей сельхозпродукции (фермеров.  владельцев личных подсобных хозяйств) стран СНГ. дальнего и ближнего зарубежья с производителями сельхозтехники. оборудования, удобрений). Мы создаем профессиональную сеть фермеров и предпринимателей агробизнеса. Это площадка со всеми элементами социальной сети и бизнес-портала, которая станет незаменимым помощником как в работе, так и в личном неформальном общении. мы предоставим возможность обсудить любые волнующие вас темы, выгодно продать или купить, получить любую справочную информацию о компаниях, новых технологиях агропромышленного комплекса, поделиться своими фотографиями или видео, быть в курсе всех важных событий отрасли

                    Наш портал поможет вам получить юридическую. логистическую помощь, организовать экспортные и импортные поставки и успешно выйти на внешние рынки.</p>
            </section>
        </section>
        <section id="project" className="section-logo content">
            <h2>Проект реализован при поддержке Фонда науки Казахстан</h2>
            <img src={image3} alt="Partnership" className="section-image-logo" />
        </section>
    </main>
);

export default AboutUs;