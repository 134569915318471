import { API_URL } from "../config/connect";

export async function activateAdById(adId) {
    const endpoint = `${API_URL}/api/v1/announcement/${adId}/activate`;

    try {
        const accessToken = localStorage.getItem("access_token");

        if (!accessToken) {
            throw new Error("Access token is missing");
        }

        const response = await fetch(endpoint, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        const result = await response.json();
        console.log('Ad activated successfully:', result);
        return result;
    } catch (error) {
        console.error('Failed to activate ad:', error);
        throw error;
    }
}

export async function deactivateAdById(adId) {
    const endpoint = `${API_URL}/api/v1/announcement/${adId}/deactivate`;

    try {
        const accessToken = localStorage.getItem("access_token");

        if (!accessToken) {
            throw new Error("Access token is missing");
        }

        const response = await fetch(endpoint, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        const result = await response.json();
        console.log('Ad deactivated successfully:', result);
        return result;
    } catch (error) {
        console.error('Failed to deactivate ad:', error);
        throw error;
    }
}