import React, { useState, useEffect } from 'react';
import '../style/OpenModalButton.css';

import CategoryModal from '../../../modals/category_modal/ui/Main';
import LocationModal from '../../../modals/location_modal/ui/Main';
import FilterModal from '../../../modals/filter_modal/ui/Main';

import rightIcon from '../../../../assets/icons/right_arrow.svg';
import filterIcon from '../../../../assets/icons/filter.svg';
import { t } from 'i18next';

const OpenModalButton = ({ onSelect, type = 'category' }) => {
    const [is_modal_open, set_is_modal_open] = useState(false);
    const [selectedText, setSelectedText] = useState(type === 'filter' ? t('filters') : t('select'));

    useEffect(() => {
        const storedCategory = localStorage.getItem("selectedCategoryName");
        const storedSubcategory = localStorage.getItem("selectedSubcategoryName");

        if (storedCategory && storedSubcategory) {
            setSelectedText(`${storedCategory}, ${storedSubcategory}`);
        }
    }, []);

    const handle_open_modal = () => set_is_modal_open(true);
    const handle_close_modal = () => set_is_modal_open(false);

    const handle_select = (selection) => {
        const newCategoryName = selection.category.name;
        const newSubcategoryName = selection.subcategory.name;

        localStorage.setItem("selectedCategoryName", newCategoryName);
        localStorage.setItem("selectedSubcategoryName", newSubcategoryName);

        setSelectedText(`${newCategoryName}, ${newSubcategoryName}`);

        if (onSelect) onSelect({
            categoryId: selection.category,
            subcategoryId: selection.subcategory,
            filterOption: selection.filterOption
        });

        handle_close_modal();
    };

    return (
        <div>
            <button
                className={`open_modal_button ${type === 'filter' ? 'open_modal_button_filter' : ''}`}
                onClick={handle_open_modal}
            >
                {selectedText} <img src={type === 'filter' ? filterIcon : rightIcon} alt={type === 'filter' ? 'filter' : 'right'} />
            </button>

            {is_modal_open && (
                <div>
                    <div onClick={handle_close_modal} className="click navigation_content_background"></div>
                    <div className="navigation_content">
                        {type === 'category' && (
                            <CategoryModal onSelect={handle_select} onClose={handle_close_modal} />
                        )}
                        {type === 'location' && (
                            <LocationModal menuVisible={true} onSelect={handle_select} onClose={handle_close_modal} />
                        )}
                        {type === 'filter' && (
                            <FilterModal onSelect={handle_select} onClose={handle_close_modal} />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default OpenModalButton;