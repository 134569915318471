import React from "react";
import '../style/Navigation.css'
import Navigation from "../components/Navigation";
import NavigationBottom from "../components/NavigationBottom";

function Main({ menuVisible, toggleMenu }) {
    return (
        menuVisible && (
            <>
                <div onClick={toggleMenu} className="click navigation_content_background"></div>
                <div className="navigation_content_t">
                    <Navigation onClose={toggleMenu} />
                    <NavigationBottom />
                </div>
            </>
        )
    );
}

export default Main;