import React from "react";
import '../styles/UserModalItem.css';
import Icon from "../../../../common/components/Icon";

function UserModalItem({ className, iconPath, itemName, not_line }) {
    return (
        <div className={`click user_modal_item_content ${not_line}`}>
            <div className="user_modal_item_left">
                <Icon className={`${className} left_icon_hover`} alt={itemName} src={iconPath} />
                <p>{String(itemName)}</p>
            </div>
            <Icon className={"user_modal_item_content_right"} src="right_arrow" alt={"user"} />
        </div>
    );
}

export default UserModalItem;