import React, { useEffect, useState } from 'react';
import '../style/CategoryModal.css';

import closeIcon from '../../../../assets/icons/close.svg';
import search_icon from '../../../../assets/icons/search.svg';
import { getCategories } from '../../../../api/getCategories';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const getCategoryName = (category, language) => {
    switch (language) {
        case 'en':
            return category.eng_name;
        case 'kz':
            return category.kz_name;
        case 'cn':
            return category.ch_name;
        default:
            return category.name;
    }
};

const getSubcategoryName = (subcategory, language) => {
    switch (language) {
        case 'en':
            return subcategory.eng_name;
        case 'kz':
            return subcategory.kz_name;
        case 'cn':
            return subcategory.ch_name;
        default:
            return subcategory.name;
    }
};

const CategoryModal = ({ onSelect, onClose }) => {
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const [selectedCategoryId, setSelectedCategoryId] = useState();
    const [selectedSubcategoryId, setSelectedSubcategoryId] = useState();

    const { i18n } = useTranslation();

    useEffect(() => {
        async function fetchCategories() {
            try {
                const categoriesData = await getCategories();
                setCategories(categoriesData);
            } catch (error) {
                console.error("Failed to load categories:", error);
            }
        }

        fetchCategories();
    }, []);

    const handleCategoryClick = (category) => {
        const newCategoryId = selectedCategoryId === category.id ? null : category.id;
        setSelectedCategoryId(newCategoryId);
    };

    const handleSubcategoryClick = (category, subcategory) => {
        setSelectedCategoryId(category.id);
        setSelectedSubcategoryId(subcategory.id);

        localStorage.setItem("selectedCategory", JSON.stringify(category.id));
        localStorage.setItem("selectedSubcategory", JSON.stringify(subcategory.id));

        if (onSelect) {
            onSelect({ category, subcategory });
        }
        onClose();
    };

    const filteredCategories = categories.filter(category =>
        getCategoryName(category, i18n.language).toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="category_modal">
            <div className="category_modal_header">
                <h2>{t('categories')}</h2>
                <button className="click mobile_hide navigation_close" onClick={onClose}>
                    <img src={closeIcon} alt="Close" />
                </button>
            </div>
            <div className="search_input_wrapper">
                <div className="input_container">
                    <img
                        className="search_icon_input"
                        src={search_icon}
                        alt={t('search')}
                    />
                    <input
                        className="second_section_search"
                        placeholder={t('search')}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>

            <div className="category_list">
                {filteredCategories.map((category) => (
                    <div>
                        <div key={category.id} className="click category_item">
                            <img
                                className="category_image"
                                src={category.image_url}
                                alt={getCategoryName(category, i18n.language)}
                                onClick={() => handleCategoryClick(category)}
                            />
                            <div
                                className="category_name"
                                onClick={() => handleCategoryClick(category)}
                            >
                                {getCategoryName(category, i18n.language)}
                            </div>
                        </div>
                        {selectedCategoryId === category.id && category.subcategories && (
                            <div className="subcategory_list content">
                                {category.subcategories.map((sub) => (
                                    <div
                                        key={sub.id}
                                        className={`subcategory_item ${selectedSubcategoryId === sub.id ? "selected" : ""}`}
                                        onClick={() => handleSubcategoryClick(category, sub)}
                                    >
                                        {getSubcategoryName(sub, i18n.language)}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CategoryModal;