import React, { useEffect } from 'react';
import '../style/modal.css';

const Modal = ({ show, message, onClose }) => {
    useEffect(() => {
        if (show) {
            const timer = setTimeout(() => {
                onClose();
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [show, onClose]);

    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-body">
                    {message}
                </div>
            </div>
        </div>
    );
};

export default Modal;