import React, { useEffect, useState } from "react";
import { getNotifications } from "../../../api/get/getNotifications";

import NewItem from "../items/NewItem";

const Articles = () => {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        async function fetchNotifications() {
            try {
                const serviceType = "service";
                const data = await getNotifications({ service: serviceType }, 1, 10);
                setNotifications(data.items || []);
            } catch (error) {
                console.error("Error fetching notifications:", error);
            }
        }

        fetchNotifications();
    }, []);

    return (
        <main className="about_content">
            <div className="content">
                <h1 className="m_0">Статьи</h1>
            </div>
            {/* <div className="result_grid"> */}
            {notifications.length > 0 ? (
                <div className="result_grid">
                    {notifications.map((notification) => (
                        <NewItem
                            key={notification.id}
                            id={notification.id}
                            name={notification.title}
                            text={notification.text}
                            date={notification.created_at}
                        />
                    ))}
                </div>
            ) : (
                <p>Скоро появится полезная информация!</p>
            )}
            {/* </div> */}
        </main>
    );
};

export default Articles;