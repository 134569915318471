import React from 'react'
import CategoryModal from '../components/CategoryModal'

function Main({ onSelect, onClose }) {
  return (
    <>
      <div onClick={onClose} className="click navigation_content_background"></div>
      <div className="navigation_content">
        <CategoryModal onSelect={onSelect} onClose={onClose} />
      </div>
    </>
  )
}

export default Main