import React from "react";
import '../style/Terms.css';
import { t } from "i18next";

const Terms = () => (
    <main className="about_content">
        <div className="content">
            <h1 className="m_0">{t('termsOfUse')}</h1>
        </div>
        <div className="content">
            <section className="terms-section">
                <h2>Платежи</h2>
                <p>Оплата банковской картой онлайн возможна через защищенную платежную страницу АО Банк ЦентрКредит.</p>
                <p>Для дополнительной аутентификации используется 3-D Secure. Если ваш банк поддерживает данную технологию, вам потребуется пройти дополнительную идентификацию.</p>
                <p>Оплата осуществляется по стандартам VISA и MasterCard с использованием методов шифрования.</p>
            </section>

            <section className="terms-section">
                <h2>Гарантии безопасности</h2>
                <p>АО Банк ЦентрКредит защищает данные карт по стандарту PCI DSS 3.0. Все платежные операции проходят через безопасные банковские сети.</p>
            </section>

            <section className="terms-section">
                <h2>Безопасность онлайн-платежей</h2>
                <p>Личная информация (ФИО, e-mail, номер карты) передается в зашифрованном виде и не хранится на наших серверах.</p>
                <p>Все транзакции соответствуют требованиям международных платежных систем.</p>
            </section>

            <section className="terms-section">
                <h2>Возврат денежных средств</h2>
                <p>Возврат наличными средствами не допускается. Процедура возврата регулируется правилами платежных систем.</p>
                <p>Возврат возможен при сохранении товарного вида, а также подтверждающих документов.</p>
                <p>Для возврата средств необходимо заполнить «Заявление о возврате денежных средств» и отправить его на support@agroland.kz.</p>
            </section>

            <section className="terms-section">
                <h2>Случаи отказа в платеже</h2>
                <ul>
                    <li>Банковская карта не предназначена для онлайн-платежей.</li>
                    <li>Недостаточно средств на карте.</li>
                    <li>Неверно введены данные карты.</li>
                    <li>Истек срок действия карты.</li>
                </ul>
                <p>При возникновении проблем свяжитесь с вашим банком.</p>
            </section>

            <section className="terms-section">
                <h2>Конфиденциальность</h2>
                <p>Мы относимся к защите персональных данных серьезно. Персонализированная информация используется только для оказания услуг и не передается третьим лицам, за исключением случаев, предусмотренных законом.</p>
                <p>Анонимные данные могут использоваться для аналитики и улучшения сервиса.</p>
            </section>

            <section className="terms-section">
                <h2>Ограничение ответственности</h2>
                <p>Мы стремимся соблюдать политику конфиденциальности, но не несем ответственности за утечки данных вследствие внешних факторов.</p>
            </section>

            <section className="terms-section">
                <h2>Контакты</h2>
                <p>По вопросам, связанным с условиями пользования, обращайтесь на support@agroland.kz.</p>
            </section>
        </div>
    </main>
);

export default Terms;