import React, { useEffect, useState } from "react";
import "../style/PaymentHistory.css";
import Icon from "../../../common/components/Icon";
import getUserTransactions from "../../../api/getUserTransactions";
import { t } from "i18next";

import EmptyState from "../../../components/emptystate/EmptyState";
import Loading from "../../../common/components/Loading";

function PaymentHistory({ setActiveTab }) {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const data = await getUserTransactions();
                setTransactions(data.transactions);
            } catch (err) {
                setError("Ошибка загрузки данных");
            } finally {
                setLoading(false);
            }
        };

        fetchTransactions();
    }, []);

    const handleBackClick = () => {
        setActiveTab(null);
    };

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        return date.toLocaleString("ru-RU", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        });
    };

    const getTransactionDescription = (type) => {
        const types = {
            balance_top_up: "Пополнение кошелька",
            refund: "Возврат средств",
        };
        return types[type] || "Транзакция";
    };

    return (
        <div className="profile_render_container">
            <Icon src="backicon" onClick={handleBackClick} className="back_icon_mobile" />
            <h2 className="menu_right_section_header">{t('paymentHistory')}</h2>

            {loading && <Loading />}
            {error && <p className="error-message">{error}</p>}

            {!loading && !error && transactions.length === 0 ? (
                <EmptyState title="Пока ничего нет" subtitle="Здесь будет храниться вся история ваших платежей" />
            ) : (
                <div className="menu_right_section_package">
                    <ul className="payment_history_list">
                        {transactions.map((transaction) => (
                            <li key={transaction.id} className="payment_history_item content">
                                <div className="payment-description">
                                    <div>
                                        <p className="main-description">
                                            {getTransactionDescription(transaction.transaction_type)}
                                        </p>
                                        <p className={`payment-amount ${transaction.transaction_type === "refund" ? "debit" : "credit"}`}>
                                            {transaction.transaction_type === "refund" ? "-" : "+"} {transaction.amount}
                                        </p>
                                    </div>
                                </div>
                                <hr className="divider"></hr>
                                <div className="payment-details">
                                    <p className="payment-date">{formatDate(transaction.created_at)}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default PaymentHistory;