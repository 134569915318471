import { t } from "i18next";
import React from "react";

const Faq = () => (
    <main className="about_content">
        <div className="content">
            <h1 className="m_0">{t('howToBuyAndSell')}</h1>
        </div>
        <div className="content">
            <p>Здесь будет подробное руководство.</p>
        </div>
    </main>
);

export default Faq;