import React, { useState, useEffect } from "react";
import "../style/WalletTopUp.css";

import Icon from "../../../common/components/Icon";
import Modal from "../../../common/components/MessageModal";

import { getUserInfo } from "../../../api/usercall";
import { getPaymentRequest } from "../../../api/getPaymentRequest";
import infoIcon from '../../../assets/icons/info.svg';
import { t } from "i18next";

function WalletTopUp({ setActiveTab }) {
    const [amount, setAmount] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [userInfo, setUserInfo] = useState(null);
    const [paymentResponse, setPaymentResponse] = useState('');
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const data = await getUserInfo();
                setUserInfo(data.user);
            } catch (err) {
                console.error("Failed to fetch user info:", err);
            }
        };

        fetchUserInfo();
    }, []);

    const handleAmountClick = (value) => {
        setAmount(value.toString());
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!amount || parseFloat(amount) <= 0) {
            setModalMessage("Ошибка: Введите корректную сумму пополнения.");
            setShowModal(true);
            return;
        }

        if (!userInfo?.id) {
            setModalMessage("Ошибка: Не удалось получить данные пользователя.");
            setShowModal(true);
            return;
        }

        try {
            const response = await getPaymentRequest(amount, "KZT");
            setPaymentResponse(response);
            setIsPaymentModalOpen(true);
        } catch (error) {
            console.error("Payment request failed:", error);
            setModalMessage("Ошибка при пополнении.");
            setShowModal(true);
        }
    };

    const handleBackClick = () => {
        setActiveTab(null);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div className="profile_render_container">
            <Icon src="backicon" onClick={handleBackClick} className="back_icon_mobile" />
            <h2 className="menu_right_section_header">{t('replenishWallet')}</h2>
            <div className="menu_right_section_content content">
                <div className="wallet_section_content">
                    <div className="balance_info">
                        {userInfo && (
                            <p>{t('yourBalance')}: <span className="balance">{userInfo.balance || 0} {t('units')}</span></p>
                        )}
                        <div className="balance_card_second_text">
                            <img src={infoIcon} alt="info" />
                            <p>{t('rule_unit')}</p>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="topup_form">
                        <hr className="divider" />
                        <label className="wallet_amount" htmlFor="amount">{t('sum_top_up')}</label>
                        <input
                            type="number"
                            id="amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder={t('enter_sum')}
                            className="amount-input"
                            min="1"
                        />

                        <div className="amount-options">
                            {[1000, 5000, 20000, 100000].map((value) => (
                                <button key={value} type="button" onClick={() => handleAmountClick(value)}>
                                    {value.toLocaleString()} тг.
                                </button>
                            ))}
                        </div>

                        <hr className="divider" />

                        <label className="wallet_amount" htmlFor="service">{t('service_for_replenishment')}</label>
                        <div className="service-options">
                            <Icon src="mastercard" />
                            <span>{t('banks_card')}</span>
                        </div>

                        <button type="submit" className="topup-button">{t('replenishWallet')}</button>
                    </form>
                </div>
            </div>

            <Modal show={showModal} message={modalMessage} onClose={closeModal} />

            {isPaymentModalOpen && (
                <div className="pay_card_modal">
                    <div className="pay_modal_card_content">
                        <Icon src="close" className={"click payment_modal_close"} onClick={() => setIsPaymentModalOpen(false)} />
                        <div dangerouslySetInnerHTML={{ __html: paymentResponse }} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default WalletTopUp;