import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import routes from './routes/Routes';
import fetchUserCountry from './api/getUserCountry';

fetchUserCountry();

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {routes.map(({ path, element }, index) => (
            <Route key={index} path={path} element={element} />
          ))}
        </Routes>
      </Router>
    </div>
  );
}

export default App;