import React from "react";
import '../style/CategoryHeader.css'

function CategoryHeader({ header_text }) {
    return ( 
        <div className="category_header_content">
            <h1>{header_text}</h1>
        </div>
     );
}

export default CategoryHeader;