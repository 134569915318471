import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import '../style/ImageSelector.css';
import gallerAddIcon from '../../../../assets/icons/galleryadd.svg';
import deleteIcon from '../../../../assets/icons/deleteRound.svg';
import { t } from 'i18next';
import { saveToDB, getFromDB } from '../../../../utils/indexedDBService';

const STORE_NAME_IMAGES = "savedImages";
const STORE_NAME_FILES = "savedImageFiles";

const ImageSelector = ({ onImagesChange }) => {
    const maxImages = 4;
    const [images, setImages] = useState(Array(maxImages).fill(null));
    const [imageFiles, setImageFiles] = useState(Array(maxImages).fill(null));
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const savedImages = await getFromDB(STORE_NAME_IMAGES) || [];
                const savedFiles = await getFromDB(STORE_NAME_FILES) || [];
                setImages([...savedImages, ...Array(maxImages - savedImages.length).fill(null)]);
                setImageFiles([...savedFiles, ...Array(maxImages - savedFiles.length).fill(null)]);
            } catch (error) {
                console.error("Error loading images from DB:", error);
            }
        };
        fetchImages();
    }, []);

    const convertToJpeg = (file, callback) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            canvas.toBlob((blob) => {
                callback(blob);
            }, "image/jpeg", 0.9);
        };
    };

    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        if (files.length > maxImages) {
            alert(t("maxImagesError"));
            return;
        }

        const newImages = [...images];
        const newImageFiles = [...imageFiles];

        files.forEach((file, i) => {
            convertToJpeg(file, (jpegBlob) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const idx = newImages.findIndex((img) => img === null);
                    if (idx !== -1) {
                        newImages[idx] = reader.result;
                        newImageFiles[idx] = new File([jpegBlob], `${file.name.split('.')[0]}.jpg`, { type: "image/jpeg" });
                    }

                    if (i === files.length - 1) {
                        setImages([...newImages]);
                        setImageFiles([...newImageFiles]);
                        saveToDB(STORE_NAME_IMAGES, newImages.filter(Boolean));
                        saveToDB(STORE_NAME_FILES, newImageFiles.filter(Boolean));
                        onImagesChange(newImageFiles.filter(Boolean));
                    }
                };
                reader.readAsDataURL(jpegBlob);
            });
        });
    };


    const handleRemoveImage = (index) => {
        const newImages = [...images];
        const newImageFiles = [...imageFiles];

        newImages[index] = null;
        newImageFiles[index] = null;

        setImages([...newImages]);
        setImageFiles([...newImageFiles]);
        saveToDB(STORE_NAME_IMAGES, newImages.filter(Boolean));
        saveToDB(STORE_NAME_FILES, newImageFiles.filter(Boolean));

        onImagesChange(newImageFiles.filter(Boolean));
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const newImages = [...images];
        const newImageFiles = [...imageFiles];

        const [movedImage] = newImages.splice(result.source.index, 1);
        const [movedFile] = newImageFiles.splice(result.source.index, 1);

        newImages.splice(result.destination.index, 0, movedImage);
        newImageFiles.splice(result.destination.index, 0, movedFile);

        setImages(newImages);
        setImageFiles(newImageFiles);
        onImagesChange(newImageFiles.filter((f) => f));
    };

    return (
        <div className="image_selector">
            {isMobile ? (
                <label className="mobile_image_container">
                    <input type="file" accept="image/*" multiple onChange={handleImageUpload} hidden />
                    <div className="mobile_image_wrapper">
                        {images.some(Boolean) ? (
                            <div className="image_preview">
                                {images.map((img, index) =>
                                    img ? (
                                        <div key={index} className="thumbnail">
                                            <img src={img} alt={`img-${index}`} />
                                            <button className="remove_button" onClick={() => handleRemoveImage(index)}>
                                                <img src={deleteIcon} alt="delete" />
                                            </button>
                                        </div>
                                    ) : null
                                )}
                            </div>
                        ) : (
                            <div className="upload_icon">
                                <img src={gallerAddIcon} alt="add" />
                                <span>{t('add')}</span>
                            </div>
                        )}
                    </div>
                </label>
            ) : (
                <>
                    <div className="image_selector_content">
                        <div>
                            <h3 className="image_selector_title">{t('announcementPhoto')}</h3>
                            <p className="image_selector_text">
                                {t('announcementPhotos')}
                            </p>
                        </div>
                        <span className="image_selector_limit">{t("maxPhotosCount", { count: 4 })}</span>
                    </div>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="images" direction="horizontal">
                            {(provided) => (
                                <div className="image_grid" {...provided.droppableProps} ref={provided.innerRef}>
                                    {images.map((img, index) => (
                                        <Draggable key={index} draggableId={String(index)} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={`image_item ${snapshot.isDragging ? 'dragging' : ''}`}
                                                >
                                                    {img ? (
                                                        <>
                                                            <img src={img} alt={`${index}`} />
                                                            {index === 0 && <span className="main_label">Главное</span>}
                                                            <button
                                                                className="remove_button"
                                                                onClick={() => handleRemoveImage(index)}
                                                            >
                                                                <img src={deleteIcon} alt="delete" />
                                                            </button>
                                                        </>
                                                    ) : (
                                                        images.slice(0, index).every((i) => i !== null) && (
                                                            <label className="image_placeholder">
                                                                <input type="file" accept="image/*" multiple onChange={handleImageUpload} hidden />
                                                                <div className="upload_icon">
                                                                    <img src={gallerAddIcon} alt="add" />
                                                                </div>
                                                                <span>{t('add')}</span>
                                                            </label>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </>
            )}
        </div>
    );
};

export default ImageSelector;