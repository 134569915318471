import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import '../style/description.css';

import calendarIcon from '../../../assets/icons/calendar.svg';
import heartIcon from '../../../assets/icons/heart.svg';
import shareIcon from '../../../assets/icons/share.svg';
import activeHeartIcon from '../../../assets/icons/bottomBar/active_heart.svg';
import starIcon from '../../../assets/icons/star.svg';
import chatIcon from '../../../assets/icons/chat.svg';

import { addToFavorites, removeFromFavorites, getFavoriteStatus } from "../../../api/favoritecall";
import Icon from '../../../common/components/Icon';
import { t } from 'i18next';

const Description = ({ id, title, date, price, description, phone, rating, reviews, user_id }) => {
    const navigate = useNavigate();
    const [isFavorite, setIsFavorite] = useState(false);
    const [showFormattedPhone, setShowFormattedPhone] = useState(false);

    useEffect(() => {
        async function fetchFavoriteStatus() {
            try {
                const status = await getFavoriteStatus(id);
                setIsFavorite(status.is_favorite);
            } catch (error) {
                console.error("Error fetching favorite status:", error);
            }
        }

        fetchFavoriteStatus();
    }, [id]);

    const handleFavoriteToggle = async (e) => {
        e.stopPropagation();
        try {
            if (isFavorite) {
                await removeFromFavorites(id);
            } else {
                await addToFavorites(id);
            }
            setIsFavorite(!isFavorite);
        } catch (error) {
            console.error("Error toggling favorite:", error);
        }
    };

    const handlePhoneToggle = () => {
        setShowFormattedPhone(!showFormattedPhone);
    };

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: title,
                    text: description,
                    url: window.location.href,
                });
            } catch (error) {
                console.error('Error sharing content:', error);
            }
        } else {
            alert('Sharing is not supported in this browser.');
        }
    };

    const handleMessageClick = () => {
        navigate('/profile#messages', {
            state: {
                user_id,
                announcement_link: `https://agroland.kz/announcement/${id}`,
                announcement_id: id
            }
        });
    };

    const formattedDate = new Date(date).toLocaleDateString('ru-RU');
    const formattedPhone = phone ? phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5') : 'Номер не указан';
    const formatPrice = (price) => {
        if (!price) return 'Цена не указана';
        const [value, currency] = price.split(' ');
        if (currency === 'KZT') {
            const formattedValue = parseFloat(value).toFixed(0);
            return `${formattedValue} тг.`;
        }
        return price;
    };

    return (
        <div className="description_content">
            <div className="description_header">
                <div className="announcement_date_content">
                    <img src={calendarIcon} alt="Calendar Icon" />
                    <p className="announcement_date">{t('publishedAt')}{formattedDate}</p>
                </div>
                <div>
                    <img
                        src={shareIcon}
                        alt="share"
                        className="favorite_icon click"
                        onClick={handleShare}
                    />
                    <span className="click" onClick={handleFavoriteToggle}>
                        <img
                            src={isFavorite ? activeHeartIcon : heartIcon}
                            alt={isFavorite ? "Remove from favorites" : "Add to favorites"}
                            className="favorite_icon click"
                        />
                    </span>
                </div>
            </div>
            <h2 className='announcement_title'>{title || 'Без названия'}</h2>
            <div className="announcement_price">
                <span>
                    {price ? `${formatPrice(price)}` : 'Цена не указана'}
                </span>
            </div>
            <div className="announcement_user_number">
                <p>{showFormattedPhone ? formattedPhone : '+7 7'}</p>
                <button className='announcement_user_number_button click' onClick={handlePhoneToggle}>
                    {t('show_phone')} <Icon src={"phone"} whiteicon={"white_icon"} />
                </button>
            </div>
            <div className="announcement_rating">
                <div className="rating_number">
                    <img src={starIcon} alt="Star Icon" />
                    <p>{t('rating')}{String(rating || '5').slice(0, 3)}</p>
                </div>
                <div className="announcement_reviews">
                    <p>
                        <span>{t('feedbacks')}: </span>
                        <span className="announcement_reviews_count">{reviews || 0}</span>
                    </p>
                </div>
            </div>
            <div className="announcement_message click" onClick={handleMessageClick}>
                <p>{t('message')}</p>
                <img src={chatIcon} alt="Chat Icon" className="arrow" />
            </div>
        </div>
    );
};

export default Description;