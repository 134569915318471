import React from "react";
import RangeInput from "../items/RangeInput";
import ToggleContainer from "../items/ToggleSwitch";

import "../style/LeftSection.css";
import "../style/CategoryLocationSelection.css"
import Icon from "../../../common/components/Icon";

import { t } from "i18next";

function LeftSections({
    openCategoryModal,
    openLocationModal,
    selectedCategory,
    resetFilters,
    minPrice,
    maxPrice,
    onPriceChange,
    isContract,
    isBarter,
    onContractToggle,
    onBarterToggle,
}) {
    return (
        <div className="filter_section_content">
            <div className="filter_section_content_top">
                <h2>{t('filters')}</h2>
                <p className="click link" onClick={resetFilters}>
                    {t('reset')}
                </p>
            </div>
            <div className="filter_section_content_card">
                <div className="selection-container">
                    <h3>{t('selectCategory')}</h3>
                    <button
                        className="open_modal_button filter"
                        onClick={openCategoryModal}
                    >
                        {selectedCategory || t('selectCategory')}
                        <Icon src="right_arrow" alt="right" />
                    </button>
                    <hr style={{marginBottom: 20, marginTop: 20}} className="divider" />
                    <h3 style={{marginTop: 0}}>{t('location')}</h3>
                    <button
                        className="open_modal_button filter"
                        onClick={openLocationModal}
                    >
                        {t('location')}
                        <Icon src="right_arrow" alt="right" />
                    </button>
                    <hr className="divider" />
                </div>
            </div>
            <div className="filter_section_content_bottom">
                <h3>{t('price')}</h3>
                <div className="filter_section_content_bottom_inputs">
                    <RangeInput
                        minValue={minPrice}
                        maxValue={maxPrice}
                        onMinChange={(min) => onPriceChange(min, maxPrice)}
                        onMaxChange={(max) => onPriceChange(minPrice, max)}
                    />
                </div>
                <ToggleContainer
                    isContract={isContract}
                    isBarter={isBarter}
                    onContractToggle={onContractToggle}
                    onBarterToggle={onBarterToggle}
                />
            </div>
        </div>
    );
}

export default LeftSections;