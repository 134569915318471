import React, { useEffect, useState } from "react";
import VipAdvertItem from "../../main/items/vip_advert_item";
import { getUserFavorites } from "../../../api/favoritecall";

import image1 from '../../images/vip1.png';
import app_icon_mini from '../../images/app_icon_mini.svg';

import like_icon from '../../../assets/icons/heart.svg';

import Loading from "../../../common/components/Loading";
import { t } from "i18next";

import '../style/Favorites.css';

function Favorites() {
    const [favorites, setFavorites] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchFavorites() {
            try {
                const data = await getUserFavorites();
                setFavorites(data.items || []);
            } catch (err) {
                setError(err.message);
                console.error("Error fetching user favorites:", err);
            } finally {
                setIsLoading(false);
            }
        }

        fetchFavorites();
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="profile_render_container">
            <h2 className="menu_right_section_header">
                {t('favorites')}
            </h2>
            <div className="fab_content">
                <div className="fav_advert_items">
                    {favorites.length > 0 ? (
                        favorites.map((favorite, index) => (
                            <VipAdvertItem
                                key={index}
                                id={favorite.id}
                                imagePath={favorite.main_image_url || image1}
                                name={favorite.title}
                                price={favorite.price}
                                location={favorite.user_location_id}
                                date={favorite.created_at}
                                barter={favorite.barter}
                            />
                        ))
                    ) : null}
                </div>
                {favorites.length === 0 && (
                    <div className="container_empty_state">
                        <img className="logo" src={app_icon_mini} alt="logo" />
                        <h2 className="title">{t('noFavoritesTitle')}</h2>
                        <p className="subtitle">{t('noFavoritesDescription')}</p>
                        <img src={like_icon} style={{ width: 24, height: 24, margin: 0 }} alt="heart" />
                    </div>
                )}
            </div>
        </div>
    );
}

export default Favorites;