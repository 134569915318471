import React from 'react';
import ErrorComponent from '../components/ErrorComponent';

function Main({ showLogoutModal, setShowLogoutModal, message }) {
    return (
        <ErrorComponent 
            showLogoutModal={showLogoutModal} 
            setShowLogoutModal={setShowLogoutModal} 
            errorMessage={message} 
        />
    );
}

export default Main;