import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import Fooder from '../../main/components/Fooder';
import Header from '../../main/components/Header';
import MobileBottomBar from '../../main/components/MobileBottomBar';

import { I18nextProvider } from 'react-i18next';
import i18n from '../../../language/i18n';
import Path from '../../path/ui/Path';
import EditForm from '../components/EditForm';

import getAnnouncementById from '../../../api/getAnnouncementById';

function Main() {
    const [bottomBarHidden, setBottomBarHidden] = useState(false);
    const location = useLocation();
    const [announcementId, setAnnouncementId] = useState(location.state?.adId || null);
    const [announcementData, setAnnouncementData] = useState(null);

    useEffect(() => {
        if (location.state?.adId) {
            setAnnouncementId(location.state.adId);
        }
    }, [location.state?.adId]);

    console.log(location.state?.adId)

    useEffect(() => {
        if (announcementId) {
            getAnnouncementById(announcementId)
                .then((data) => {
                    setAnnouncementData(data.announcement);
                })
                .catch((error) => {
                    console.error('Failed to fetch announcement:', error);
                });
        }
    }, [announcementId]);

    const handleBottomBarToggle = (isHidden) => {
        setBottomBarHidden(isHidden);
    };

    return (
        <I18nextProvider i18n={i18n}>
            <Header onToggleMenu={handleBottomBarToggle} />
            <Path currentPath={`Страница пользователя`} />
            {announcementData ? (
                <EditForm
                    announcementId={announcementId}
                    announcementData={announcementData}
                />
            ) : (
                <p>Loading...</p>
            )}
            <Fooder />
            {!bottomBarHidden && <MobileBottomBar activeTabName={'home'} />}
        </I18nextProvider>
    );
}

export default Main;