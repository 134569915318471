import React, { useEffect } from "react";
import "../style/About.css";
import AboutLeftNav from "./AboutLeftNav";
import AboutUs from "./AboutUs";
import Articles from "./Articles";
import Contacts from "./Contacts";
import Policy from "./Policy";
import Terms from "./Terms";
import Faq from "./Faq";

const About = ({ activeSection, handleSectionChange }) => {
  useEffect(() => {
    const hash = window.location.hash.replace("#", "");
    if (hash) {
      handleSectionChange(hash);
    }
  }, [handleSectionChange]);

  return (
    <div className="container">
      <AboutLeftNav activeSection={activeSection} handleSectionChange={handleSectionChange} />
      {activeSection === "main" && <AboutUs />}
      {activeSection === "articles" && <Articles />}
      {activeSection === "contacts" && <Contacts />}
      {activeSection === "policy" && <Policy />}
      {activeSection === "terms" && <Terms />}
      {activeSection === "faq" && <Faq />}
    </div>
  );
};

export default About;