import React, { useEffect, useState } from 'react';
import { getAgreementTemplates } from '../../../api/getAgreementTemplates';
import '../style/ContractTemplates.css'; // Import styles
import Loading from '../../../common/components/Loading';
import Icon from '../../../common/components/Icon';
import { t } from 'i18next';

function ContractTemplates({ setActiveTab }) {
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchTemplates() {
            try {
                setLoading(true);
                const fetchedTemplates = await getAgreementTemplates();
                setTemplates(fetchedTemplates.items);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }

        fetchTemplates();
    }, []);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div className="profile_render_container">Error: {error}</div>;
    }

    const handleBackClick = () => {
        setActiveTab(null);
    };

    return (
        <div className="profile_render_container">
            <Icon src="backicon" onClick={handleBackClick} className={"back_icon_mobile"} />
            <h2 className="menu_right_section_header">{t('contractTemplates')}</h2>
            <div className="content">
                {templates.length === 0 ? (
                    <p>No templates available</p>
                ) : (
                    <ul className="template_list">
                        {templates.map((template, index) => (
                            <li key={template.id || index} className="template_item">
                                <div className="template_header">
                                    <h3 className="template_name">{template.name}</h3>
                                    <span className="template_date">{new Date(template.created_at).toLocaleDateString()}</span>
                                </div>
                                <a
                                    href={template.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="template_link"
                                >
                                    Открыть шаблон
                                </a>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}

export default ContractTemplates;