import { API_URL } from "../config/connect";

export async function getUserInfo() {
    try {
        const accessToken = localStorage.getItem("access_token");

        if (!accessToken) {
            return;
        }

        const response = await fetch(`${API_URL}/api/v1/user/profile`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            console.error(`Error fetching user info: ${response.status} ${response.statusText}`, errorMessage);
            return null;
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Network or unexpected error in getUserInfo:", error);
        throw new Error("Failed to fetch user info. Please try again later.");
    }
}