import React, { useEffect } from "react";
import '../style/Fooder.css';

import DownloadItem from "../../main/items/download_item";
import googleIcon from '../../images/google-play.svg';
import appleIcon from '../../images/apple.svg';

import telegram_icon from '../../../assets/icons/telegram.svg';
import instagram_icon from '../../../assets/icons/instagram.svg';
import tiktok_icon from '../../../assets/icons/tiktok.svg';
import linkedin_icon from '../../../assets/icons/linkedin.svg';
import { useTranslation } from "react-i18next";

function NavigationBottom() {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const handleLanguageChange = (lang) => {
            localStorage.setItem('language', lang);
        };

        i18n.on('languageChanged', handleLanguageChange);
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n]);
    return (
        <div className="navigation_fooder_content">
            <div className="navigation_fooder_top">
                <div className="navigation_fooder_right">
                    <p className="navigation_fooder_right_number">+7 (701) 766-34-43</p>
                    <ul className="navigation_fooder_list">
                        <li><img src={telegram_icon} alt="telegram" /></li>
                        <li><img src={instagram_icon} alt="instagram" /></li>
                        <li><img src={tiktok_icon} alt="tiktok" /></li>
                        <li><img src={linkedin_icon} alt="linkedin" /></li>
                    </ul>
                    <p>Бостандыкский район, мкр-н Орбита-4, дом 11, офис 357, 050050, KZ, Алматы</p>
                    <div className="navigation_app_download">
                        <DownloadItem iconPath={googleIcon} itemText={t("googlePlay", "Google Play")} downloadLink={"https://play.google.com/store/apps/details?id=com.agroland.app&pcampaignid=web_share"} />
                        <DownloadItem iconPath={appleIcon} itemText={t("appStore", "App Store")} downloadLink={"https://apps.apple.com/us/app/agroland/id6743326709"} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavigationBottom;