import React from "react";
import SubCategory from "../components/SubCategory";

function Main({ category }) {
    return ( 
        <div className="subcategory_container">
            <SubCategory category={category} />
        </div>
     );
}

export default Main;