import React, { useState } from "react";
import '../style/CategoryItem.css';

import altImage from '../../images/test3.png';

function CategoryItem({ imagePath, categoryText, onClick, selected }) {
    const [imageSrc, setImageSrc] = useState(imagePath);

    const handleError = () => {
        setImageSrc(altImage);
    };

    return (
        <div className="click category_item_content" onClick={onClick}>
            <img 
                className="category_item_image" 
                src={imageSrc} 
                alt="category" 
                onError={handleError} 
            />
            <h3 
                className={`category_item_text ${selected ? "selected_category_item_text" : ""}`}
            >
                {categoryText}
            </h3>
        </div>
    );
}

export default CategoryItem;