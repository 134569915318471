import React from 'react';
import '../style/SearchResponse.css';
import ResultItem from '../items/ResultItem';

function SearchResponse({ announcements }) {
    return (
        <div className="search_response_section">
            {announcements.map((announcement) => (
                <ResultItem title={announcement.title} categoryName={announcement.category} subCategoryName={announcement.subCategory} categoryId={announcement.categoryId} subCategoryId={announcement.subCategoryId} location={announcement.location.locality}/>
            ))}
        </div>
    );
}

export default SearchResponse;