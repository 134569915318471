import React from "react";
import '../style/Main.css';
import AddressModal from "../components/AddressModal";

function Main({ menuVisible, toggleMenu, onSubmitted, mode }) {
    return (
        menuVisible && (
            <>
                <div onClick={toggleMenu} className="click navigation_content_background"></div>
                <div className="navigation_content">
                    <AddressModal mode={mode} onClose={toggleMenu} onSubmitted={onSubmitted} />
                </div>
            </>
        )
    );
}

export default Main;