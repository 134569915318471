import React, { useState, useEffect } from "react";
import def_user_icon from '../../../images/def_user_icon.svg';
import '../styles/Header.css';
import { Link } from "react-router-dom";
import { getUserInfo } from "../../../../api/usercall";

function Header() {
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        async function fetchUserData() {
            try {
                const response = await getUserInfo();
                setUserData(response.user);
            } catch (error) {
                console.error("Failed to fetch user data:", error);
            }
        }

        fetchUserData();
    }, []);

    return (
        <Link to="/profile#walletTopUp">
            <div className="click user_header_content">
                <div className="user_image">
                    <img src={def_user_icon} alt="default user" />
                </div>
                <div className="user_info">
                    <p className="user_info_name">
                        {userData ? userData.name : "Loading..."}
                    </p>
                    <p className="user_info_id">
                        id: {userData ? userData.id : "Loading..."}
                    </p>
                </div>
            </div>
        </Link>
    );
}

export default Header;