import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import '../style/floating_label_input.css';

const FloatingLabelInput = ({ label, mask, value, onChange, type = "text", readOnly = false }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div className={`floating_label_input ${isFocused || value ? 'is_focused' : ''} ${!label ? 'no_label' : ''}`}>
      {label && <label className="floating_label">{label}</label>}
      <InputMask
        mask={mask}
        value={value}
        onChange={onChange}
        type={type}
        className="floating_input"
        onFocus={handleFocus}
        onBlur={handleBlur}
        readOnly={readOnly}
        style={!label ? { paddingTop: 10 } : { height: 20}}
      />
    </div>
  );
};

export default FloatingLabelInput;