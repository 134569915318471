import React from "react";
import "./style.css";

import app_logo from '../../app/images/app_icon_mini.svg';

export default function EmptyState({ title, subtitle }) {
  return (
    <div className="container_empty_state">
      <img src={app_logo} alt="Logo" className="logo" />
      <h2 className="title">{title}</h2>
      <p className="subtitle">{subtitle}</p>
    </div>
  );
}