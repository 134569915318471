import React from "react";
import { Link } from "react-router-dom";
import "../style/Path.css";

import { t } from "i18next";

const Path = ({ currentPath, pathTo = "/" }) => {
  const pathnames = currentPath.split("/").filter((x) => x);

  return (
    <nav aria-label="breadcrumb" className="breadcrumb_container">
      <ol className="breadcrumb_list">
        <li className="breadcrumb_item">
          <Link to="/">{t('home')}</Link>
        </li>
        {pathnames.map((value, index) => {
          const to = `${pathTo}/${pathnames.slice(1, index + 1).join("/")}`;

          return (
            <li key={to} className={`breadcrumb_item ${index === pathnames.length - 1 ? "active" : ""}`}>
              {index === pathnames.length - 1 ? (
                <span>{value}</span>
              ) : (
                <Link to={to}>{value}</Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Path;