import React from 'react';
import PropTypes from 'prop-types';
import icons from '../../assets/icons/icons';

const Icon = ({
    src,
    alt,
    className = '',
    style = {},
    onClick = () => {},
    whiteicon,
}) => {
    const iconPath = icons[src];

    if (!iconPath) {
        console.error(`Icon not found: ${src}`);
        return null;
    }

    return (
        <img
            src={iconPath}
            alt={alt || src}
            className={className + ' ' + (whiteicon || '')}
            style={style}
            onClick={onClick}
        />
    );
};

Icon.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    whiteicon: PropTypes.string,
};

export default Icon;