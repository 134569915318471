import React, { useState } from 'react';
import '../style/Pagination.css';

import right_arrow from '../../../assets/icons/rightarrow.svg';
import left_arrow from '../../../assets/icons/leftarrow.svg';

function Pagination() {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 3;

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  return (
    <div className="pagination-container">
      <button
        className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
        onClick={handlePrevClick}
        disabled={currentPage === 1}
      >
        <img src={left_arrow} alt='left' />
      </button>
      {[...Array(totalPages)].map((_, index) => (
        <button
          key={index + 1}
          className={`page-number ${currentPage === index + 1 ? 'active' : ''}`}
          onClick={() => handlePageClick(index + 1)}
        >
          {index + 1}
        </button>
      ))}
      <button
        className={`pagination-button ${currentPage === totalPages ? 'disabled' : ''}`}
        onClick={handleNextClick}
        disabled={currentPage === totalPages}
      >
        <img src={right_arrow} alt='left' />
      </button>
    </div>
  );
}

export default Pagination;