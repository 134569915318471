import React, { useState } from 'react';
import './style/ToggleSwitch.css';

const ToggleSwitch = ({ onToggle }) => {
    const [isToggled, setIsToggled] = useState(false);

    const handleToggle = () => {
        const newToggledState = !isToggled;
        setIsToggled(newToggledState);
        if (onToggle) {
            onToggle(newToggledState);
        }
    };

    return (
        <div
            className={`toggle_switch ${isToggled ? 'toggled' : ''}`}
            onClick={handleToggle}
        >
            <div className={`switch_circle ${isToggled ? 'circle_toggled' : ''}`} />
        </div>
    );
};

export default ToggleSwitch;