import React, { useEffect } from "react";
import '../style/MobileSection.css';
import mobile from '../../../assets/images/mobile.svg'
import DownloadItem from "../items/download_item";
import googleIcon from '../../images/google-play.svg';
import appleIcon from '../../images/apple.svg';
import { useTranslation } from "react-i18next";

function MobileSection() {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const handleLanguageChange = (lang) => {
            localStorage.setItem('language', lang);
        };

        i18n.on('languageChanged', handleLanguageChange);
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n]);

    return (
        <div className="mobile_section_content">
            <div className="mobile_section_left">
                <h3 className="mobile_section_text">
                    <span className="text_black">{t('download')} <span className="text_green"> {t('mobileApp')}</span></span>
                    <br />
                    <span className="text_sma">{t('more_conv')}</span>
                </h3>
                <div className="mobile_section_next">
                    <DownloadItem iconPath={googleIcon} itemText={t("googlePlay", "Google Play")} downloadLink={"https://play.google.com/store/apps/details?id=com.agroland.app&pcampaignid=web_share"} />
                    <DownloadItem iconPath={appleIcon} itemText={t("appStore", "App Store")} downloadLink={"https://apps.apple.com/us/app/agroland/id6743326709"} />
                </div>
            </div>
            <div className="mobile_section_right">
                <img src={mobile} alt="mobile" />
            </div>
        </div>
    );
}

export default MobileSection;