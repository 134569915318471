const DB_NAME = "ImageStorage";
const STORE_NAME_IMAGES = "savedImages";
const STORE_NAME_FILES = "savedImageFiles";

const openDB = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, 1);

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains(STORE_NAME_IMAGES)) {
                db.createObjectStore(STORE_NAME_IMAGES, { keyPath: "id", autoIncrement: true });
            }
            if (!db.objectStoreNames.contains(STORE_NAME_FILES)) {
                db.createObjectStore(STORE_NAME_FILES, { keyPath: "id", autoIncrement: true });
            }
        };

        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject("IndexedDB-ге қосылу қатесі!");
    });
};

export const saveToDB = async (storeName, data) => {
    const db = await openDB();
    const tx = db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);

    await store.clear();
    
    for (const item of data) {
        store.add({ value: item });
    }
};

export const getFromDB = async (storeName) => {
    const db = await openDB();
    const tx = db.transaction(storeName, "readonly");
    const store = tx.objectStore(storeName);

    return new Promise((resolve) => {
        const request = store.getAll();
        request.onsuccess = () => resolve(request.result.map((item) => item.value));
    });
};

export const clearDB = async (storeName) => {
    const db = await openDB();
    const tx = db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);
    await store.clear();
};