import { API_URL } from "../config/connect";
import { t } from "i18next";

export const loginUser = async (phoneNumber) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phone_number: phoneNumber }),
        });

        let data;
        try {
            data = await response.json();
        } catch (jsonError) {
            console.error("Failed to parse JSON response:", jsonError);
            throw new Error(t('networkError') || 'Ошибка сети. Повторите попытку позже.');
        }

        if (!response.ok) {
            // Map server errors to user-friendly messages
            let errorMessage = data?.error?.message || 'Unknown error';
            if (errorMessage === 'User not found') {
                errorMessage = t('userNotFound') || 'Пользователь не найден.'; // Replace "User not found" with localized text
            }

            console.error("Server error:", errorMessage);
            throw new Error(errorMessage);
        }
        return data;

    } catch (error) {
        console.error("Login error:", error.message);
        throw new Error(error.message || t('networkError') || 'Ошибка сети. Повторите попытку позже.');
    }
};