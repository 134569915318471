export function phoneFun(setAdditionalPhones, additionalPhones, setPhoneErrors, phoneErrors) {
    const handleAddPhone = () => {
        setAdditionalPhones([...additionalPhones, '']);
        setPhoneErrors([...phoneErrors, false]);
    };
    const handleDeletePhone = (index) => {
        const updatedPhones = additionalPhones.filter((_, i) => i !== index);
        setAdditionalPhones(updatedPhones);

        const updatedErrors = phoneErrors.filter((_, i) => i !== index);
        setPhoneErrors(updatedErrors);
    };
    return { handleDeletePhone, handleAddPhone };
}