import React from 'react';
import '../style/CompanyNavigation.css';

import { useTranslation } from "react-i18next";

import companyLogo from '../../../assets/images/companyLogo.png';
import starIcon from '../../../assets/icons/star.svg';
import shareIcon from '../../../assets/icons/share.svg';

function CompanyNavigation({ activeTab, setActiveTab, user }) {
    const date = new Date(user?.created_at);

    const { t, i18n } = useTranslation();
    const locale = i18n.language;

    const month = date.toLocaleString(locale, { month: "long" });
    const year = date.getFullYear();
    return (
        <div className="company-navigation">
            <div className="company-header">
                <img
                    src={companyLogo}
                    alt="Company Logo"
                    className="company-logo"
                />
                <div className="company-info">
                    <h2 className="company-name">{user?.name}</h2>
                    <p className="company-details">
                        {t("memberSince", { date: `${month} ${year}` })}
                    </p>
                    <p className="last-online">Был в сети 20.08.2024</p>
                </div>
                <div className="company-rating-share">
                    <span className="rating">
                        <img className='star' src={starIcon} alt='star' />
                        {t('rating')}    {user?.rating ? user.rating.toString().substring(0, 3) : 'N/A'}
                    </span>
                    <span className="rating">
                        <img className='share' src={shareIcon} alt='share' />
                        {t('share')}
                    </span>
                </div>
            </div>

            {user?.user_type === "business" && (
                <div className="company-tabs">
                    <button
                        className={`tab-button ${activeTab === "ads" ? "active" : ""}`}
                        onClick={() => setActiveTab("ads")}
                    >
                        {t('announcements')}
                    </button>
                    <button
                        className={`tab-button ${activeTab === "about" ? "active" : ""}`}
                        onClick={() => setActiveTab("about")}
                    >
                        {t('aboutUs')}
                    </button>
                    <button
                        className={`tab-button ${activeTab === "contacts" ? "active" : ""}`}
                        onClick={() => setActiveTab("contacts")}
                    >
                        {t('contacts')}
                    </button>
                </div>
            )}
        </div>
    );
}

export default CompanyNavigation;