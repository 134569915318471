import { API_URL } from '../config/connect';

export async function getPaymentRequest(amount, currency = "KZT") {
    const endpoint = `${API_URL}/api/v1/payment/generate`;

    try {
        const accessToken = localStorage.getItem("access_token");

        if (!accessToken) {
            throw new Error("Access token is missing");
        }

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Accept': 'text/html',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "amount": amount,
                "currency": currency
            })
        });

        const htmlResponse = await response.text();
        return htmlResponse;
    } catch (error) {
        console.error('Failed to getPaymentRequest:', error);
        throw error;
    }
}