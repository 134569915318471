import { API_URL } from "../config/connect";

export async function getAgreementTemplates() {
    const endpoint = `${API_URL}/api/v1/agreement_templates`;

    try {
        const accessToken = localStorage.getItem("access_token");

        if (!accessToken) {
            throw new Error("Access token is missing");
        }

        const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        const templates = await response.json();
        console.log('Agreement templates fetched successfully:', templates);
        return templates;
    } catch (error) {
        console.error('Failed to fetch agreement templates:', error);
        throw error;
    }
}