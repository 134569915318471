import React from 'react';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../style/Buttons.css';

export function Buttons({ handlePublish, isLoading, isMobile }) {
    const navigate = useNavigate();

    return (
        <div className={`title_section preview_content_buttons ${isMobile ? 'mobile' : 'desktop'}`}>
            <h2 className='preview_title'>{t('previewTitle')}</h2>
            <div className='buttons_container'>
                <button
                    className="ad_form_buttons publish_button"
                    onClick={handlePublish}
                    disabled={isLoading}
                >
                    {isLoading ? <div className="spinner"></div> : t("publish")}
                </button>
                <button
                    className="ad_form_buttons review_button"
                    disabled={isLoading}
                    onClick={() => navigate('/adform')}
                >
                    {t('back')}
                </button>
            </div>
        </div>
    );
}