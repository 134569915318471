import React, { useEffect, useState, useCallback, useRef } from "react";
import VipAdvertItem from "../items/vip_advert_item";
import '../style/VipAdvert.css';
import { getVipAdverts } from '../../../api/getVipAnnouncements';
import { useTranslation } from "react-i18next";

import image1 from '../../images/vip1.png';
import Loading from "../../../common/components/Loading";

function VipAdvert() {
    const { t } = useTranslation();
    const [adverts, setAdverts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    
    const isFetching = useRef(false);

    const fetchAdverts = useCallback(async () => {
        if (isFetching.current) return;
        isFetching.current = true;

        try {
            const data = await getVipAdverts({ page });
            setAdverts((prevAdverts) => [...prevAdverts, ...data.items]);

            if (data.items.length < 10) {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching VIP adverts:", error);
        } finally {
            setIsLoading(false);
            isFetching.current = false;
        }
    }, [page]);

    useEffect(() => {
        fetchAdverts();
    }, [fetchAdverts]);

    const handleShowMore = () => {
        setPage((prevPage) => prevPage + 1);
    };

    if (isLoading) {
        return <Loading />;
    }

    if (adverts.length === 0) {
        return <p className="no_adverts">{t("noVipAdvertisements")}</p>;
    }

    return (
        <div className="vip_advert_content">
            <h3 className="vip_advert_header">{t("vipAdvertisements")}</h3>
            <div className="vip_advert_items">
                {adverts.map((advert) => (
                    <VipAdvertItem
                        key={advert.id}
                        id={advert.id}
                        imagePath={advert.main_image_url || image1}
                        name={advert.title}
                        price={advert.price}
                        location={advert.location}
                        date={advert.created_at}
                        barter={advert.barter}
                        is_favorite={advert.is_favorite}
                        promotion_package={advert.promotion_package}
                        measurement_unit={advert.measurement_unit}
                    />
                ))}
            </div>
            {hasMore && (
                <div className="show_more">
                    <button className="click show_more_button" onClick={handleShowMore}>
                        {t("showMore")}
                    </button>
                </div>
            )}
        </div>
    );
}

export default VipAdvert;