const fetchUserCountry = async () => {
  const fallbackCountry = "Unknown";
  const cachedCountry = localStorage.getItem("userCountry");
  const cachedTimestamp = localStorage.getItem("countryFetchTimestamp");
  const sixHoursInMillis = 6 * 60 * 60 * 1000;
  const now = Date.now();

  if (cachedCountry && cachedTimestamp && now - cachedTimestamp < sixHoursInMillis) {
    return cachedCountry;
  }

  try {
    const response = await fetch('https://get.geojs.io/v1/ip/geo.json');

    if (!response.ok) {
      throw new Error('Failed to fetch the country information');
    }

    const data = await response.json();

    localStorage.setItem("userCountry", data.country);
    localStorage.setItem("countryFetchTimestamp", now.toString());

    return data.country;
  } catch (err) {
    console.error(`Error fetching country data: ${err.message}`);
    return fallbackCountry;
  }
};

export default fetchUserCountry;