import React from "react";

import Icon from "../../../common/components/Icon";

import addIcon from '../../../assets/icons/add.svg';
import deleteIcon from '../../../assets/icons/delete.svg';
import marker_icon from '../../../assets/icons/marker.svg';
import warning_icon from '../../../assets/icons/WarningCircle.svg';
import right_icon from '../../../assets/icons/right_arrow.svg';

import FloatingLabelInput from "../../../common/components/FloatingLabelInput";

import { t } from "i18next";

export function ContactInfo({
    userData = {},
    additionalPhones = [],
    phoneErrors = [],
    handlePhoneChange = () => { },
    handleDeletePhone = () => { },
    handleAddPhone = () => { },
    isSubmitted = false,
    selectedLocation = null,
    UserLocations = [],
    handleSelectLocation = () => { },
    handleDeleteAddress = () => { },
    toggleMenu = () => { }
}) {
    return <div className="contact_info">
        <h4 className="section_heading">{t('your_data')}</h4>

        <div className="contact_section">
            <label className="text_input_label">{t('contact_name')}</label>
            <FloatingLabelInput
                label={t('nameLabel')}
                value={userData?.name || t('default_value')}
                readOnly={true}
            />
        </div>

        <div className="phone_section">
            <label className="text_input_label">{t('phone')}</label>
            <FloatingLabelInput
                // label={t('phone')}
                value={userData?.phone_number || t('default_value')}
                type="tel"
                readOnly={true}
            />

            {additionalPhones?.map((phone, index) => (
                <div key={index} className="address_item">
                    <FloatingLabelInput
                        label={t('enter_additional_phone')}
                        value={phone}
                        type="tel"
                        onChange={(e) => handlePhoneChange(index, e.target.value)}
                        className={phoneErrors[index] ? 'error' : ''}
                    />
                    <img src={deleteIcon} alt={t('delete')} className="click address_delete" onClick={() => handleDeletePhone(index)} />
                    {phoneErrors[index] && (
                        <span className="error_text">{t('enter_phone')}</span>
                    )}
                </div>
            ))}

            <button className="add_phone_button click" onClick={handleAddPhone}>
                <img src={addIcon} alt={t('add')} /> <span className="tel_add_button_text">{t('add_more')}</span>
            </button>
        </div>

        <hr className="divider" style={{ marginTop: 14, marginBottom: 14 }} />

        <div className="address_section">
            <p style={{ marginBottom: 14 }} className="text_input_label">{t('your_address')}</p>
            <div className="input_header">
                {isSubmitted && !selectedLocation && (
                    <>
                        <span className="error_text">{t('required_text')}</span>
                        <img src={warning_icon} alt='warning' />
                    </>
                )}
            </div>

            {UserLocations?.map((location, index) => (
                <React.Fragment key={index}>
                    <div
                        className={`address_item click ${selectedLocation.address_id === location.address_id ? 'selected' : ''}`}
                        onClick={() => handleSelectLocation(location)}
                        style={{marginBottom: 14}}
                    >
                        <Icon src="marker" />
                        <div className="address_item_text">
                            <p className="address_item_text_1">{location.locality || ''}, {location.province || ''}</p>
                            <p className="address_item_text_2">{location.street || ''}</p>
                        </div>
                        <img src={deleteIcon} alt={t('delete')} className="click" onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteAddress(location.address_id);
                        }} />
                    </div>
                    <hr className="divider" />
                </React.Fragment>
            ))}
        </div>

        <div className="click mobile_none add_address_button" onClick={() => toggleMenu('address')}>
            <div>
                <img src={marker_icon} alt="marker_icon" />
                <span className="header_crop_text">{t('add_address')}</span>
            </div>
            <img src={right_icon} alt="marker_icon" />
        </div>
    </div>;
}