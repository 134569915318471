import React from "react";

import FloatingLabelInput from "../../../common/components/FloatingLabelInput";
import { t } from "i18next";

export default function PersonalInfo(userInfo) {
    return <div className="menu_right_section_package content tab_content">
        <h2 className="mt_0">Личные данные</h2>
        <hr className="divider"></hr>
        <div className="personal_data_form_content">
            <form className="personal_data_form">
                <FloatingLabelInput
                    label={t('nameLabel')}
                    value={userInfo.name || ""}
                    onChange={() => { }}
                    type="text"
                    readOnly />
                <FloatingLabelInput
                    value={userInfo.phone_number || ""}
                    mask="+7 (999) 999-99-99"
                    onChange={() => { }}
                    type="text"
                    readOnly />
                <button type="submit" className="save_button">
                    {t('save')}
                </button>
            </form>
        </div>
    </div>;
}