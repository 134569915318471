import React from "react";
import '../style/ResultItem.css';

const ResultItem = ({ title, categoryName, subCategoryName, categoryId, subCategoryId, location }) => {
    return (
        <a href={`/category/${categoryName}/${subCategoryName}/${categoryId}/${subCategoryId}`} className="result_item click">
            <h3 className="result_item__title">{title}</h3>
            <div className="result_item__category">
                <p>{categoryName}/{subCategoryName}</p>
                <p>{location}</p>
            </div>
        </a>
    );
};

export default ResultItem;