import React from "react";

import { t } from "i18next";

export default function businessTabs(activeTabV, setActiveTabV) {
    return <>
        <button
            className={`tab_button ${activeTabV === "general" ? "active" : ""}`}
            onClick={() => setActiveTabV("general")}
        >
            {t('general')}
        </button>
        <button
            className={`tab_button ${activeTabV === "about" ? "active" : ""}`}
            onClick={() => setActiveTabV("about")}
        >
            {t('aboutCompany')}
        </button>
        <button
            className={`tab_button ${activeTabV === "contacts" ? "active" : ""}`}
            onClick={() => setActiveTabV("contacts")}
        >
            {t('contacts')}
        </button>
        <button
            className={`tab_button ${activeTabV === "design" ? "active" : ""}`}
            onClick={() => setActiveTabV("design")}
        >
            {t('decorations')}
        </button>
    </>;
}