import React from 'react';
import './style/TagInput.css';

import warning_icon from '../../../assets/icons/WarningCircle.svg';

import { t } from 'i18next';

function TagInput({
    value,
    onChange,
    onAddTag,
    onRemoveTag,
    tags,
    isSubmitted,
    isRequired,
    errorText,
    placeholder
}) {
    const hasError = isRequired && isSubmitted && tags.length === 0;
    const isInputEmpty = value.trim() === '';

    return (
        <div className="tag_container">
            <div className="input_header">
                <label className="text_input_label">{t('keywords')}</label>
            </div>

            <div className="input_header">
                {isRequired && isSubmitted && hasError && (
                    <span className="error_text">{errorText}</span>
                )}

                {isRequired && isSubmitted && hasError && (
                    <img src={warning_icon} alt={t('warning')} />
                )}
            </div>

            <div className="tag_input_container text_input_box">
                <input
                    className={`phone_input_box ${hasError ? 'input_error' : ''}`}
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
                <button
                    type="button"
                    className={`add_button price_button ${isInputEmpty ? 'disabled' : 'active'}`}
                    onClick={onAddTag}
                    disabled={isInputEmpty}
                >
                    {t('add')}
                </button>
            </div>

            <div className="tag_list">
                {tags.map((tag, index) => (
                    <div className="tag_item" key={index}>
                        {tag}
                        <button
                            type="button"
                            className="remove_btn"
                            onClick={() => onRemoveTag(index)}
                        >
                            ✕
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TagInput;