import React from "react";

import FloatingLabelInput from "../../../common/components/FloatingLabelInput";
import { t } from "i18next";

export default function GeneralInfo(companyInfo, userInfo) {
    return <div>
        <div className="">
            <div className="menu_right_section_package tab_content content">
                <h2 className="mt_0">{t('general')}</h2>
                <hr className="divider"></hr>
                <div className="personal_data_form_content">
                    <form className="personal_data_form">
                        <FloatingLabelInput
                            value={companyInfo.company_bin || ""}
                            onChange={() => { } }
                            type="text"
                            readOnly />
                        <FloatingLabelInput
                            value={userInfo.name || ""}
                            onChange={() => { } }
                            type="text"
                            readOnly />
                        <FloatingLabelInput
                            value={userInfo.phone_number || ""}
                            onChange={() => { } }
                            type="text"
                            readOnly />
                    </form>
                </div>
            </div>
            <div className="menu_right_section_package tab_content content">
                <h2 className="mt_0">{t('personalData')}</h2>
                <hr className="divider"></hr>
                <div className="personal_data_form_content">
                    <form className="personal_data_form">
                        <FloatingLabelInput
                            label={t('nameLabel')}
                            value={companyInfo?.representative.name || ""}
                            mask=""
                            onChange={() => { } }
                            type="text"
                            readOnly />
                        <FloatingLabelInput
                            value={companyInfo?.representative.position || ""}
                            mask=""
                            onChange={() => { } }
                            type="text"
                            readOnly />
                        <button type="submit" className="save_button">
                            {t('save')}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>;
}