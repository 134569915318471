import React from "react";
import SearchPanel from "../components/SearchPanel";

function Main({ menuVisible, toggleMenu }) {
    return (
        menuVisible && (
            <>
                <div onClick={toggleMenu} className="click navigation_content_background"></div>
                <div className="navigation_content">
                    <SearchPanel onClose={toggleMenu} />
                </div>
            </>
        )
    );
}

export default Main;