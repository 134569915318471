import React, { useState } from 'react';
import './style/TextInput.css';

import warning_icon from '../../../assets/icons/WarningCircle.svg';
import { t } from 'i18next';

const TextInput = ({
    isRequired,
    onInputChange,
    isSubmitted,
    errorText,
    labelText,
    multiline = false,
    maxLength = Infinity,
    rows,
    placeholder,
    type = 'text',
    storageKey,
    value
}) => {
    const [error, setError] = useState(false);

    const handleInputChange = (e) => {
        const value = e.target.value;

        if (maxLength !== Infinity && value.length > maxLength) {
            return;
        }

        localStorage.setItem(storageKey, value);

        if (isRequired && value.trim() === '') {
            setError(true);
        } else {
            setError(false);
        }

        onInputChange(value);
    };

    return (
        <div className="text_input_container">
            <div className="input_header">
                <label className="text_input_label">{labelText}</label>
                {maxLength !== Infinity && (
                    <span className="char_count">
                        {t('charactersLeft')} {maxLength - (value?.length || 0)}
                    </span>
                )}
            </div>

            <div className="input_header">
                {isRequired && isSubmitted && !value && (
                    <span className="error_text">{errorText}</span>
                )}

                {isRequired && isSubmitted && !value && (
                    <img src={warning_icon} alt='warning' />
                )}
            </div>

            <div className={`text_input_box ${error ? 'input_error' : ''}`}>
                {multiline ? (
                    <textarea
                        placeholder={placeholder}
                        value={value || ''}
                        onChange={handleInputChange}
                        className="phone_input"
                        rows={rows}
                    />
                ) : (
                    <input
                        type={type}
                        placeholder={placeholder}
                        value={value || ''}
                        onChange={handleInputChange}
                        className="phone_input"
                    />
                )}
            </div>
        </div>
    );
};

export default TextInput;