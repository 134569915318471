import React, { useState } from 'react';
import '../style/SearchPanel.css';
import SearchStart from './SearchStart';
import SearchError from './SearchError';
import SearchResponse from './SearchResponse';

import { getSuggestions } from '../../../../api/searchSuggations';
import Icon from '../../../../common/components/Icon';
import Loading from '../../../../common/components/Loading';

import { t } from 'i18next';

function SearchPanel({ onClose }) {
    const [query, setQuery] = useState('');
    const [announcements, setAnnouncements] = useState([]);
    const [error, setError] = useState(null);
    const [isSearching, setIsSearching] = useState(false);

    const handleInputChange = async (e) => {
        const inputValue = e.target.value;
        setQuery(inputValue);

        if (inputValue.trim() === '') {
            setAnnouncements([]);
            setError(null);
            return;
        }

        setIsSearching(true);
        try {
            const result = await getSuggestions(inputValue);
            setAnnouncements(result);
            setError(null);
        } catch (err) {
            setError('Ошибка при загрузке объявлений.');
        } finally {
            setIsSearching(false);
        }
    };

    return (
        <div className="search_panel_section">
            <div className="modal_header">
                <h2 className="modal_title">Поиск</h2>
                <Icon className='click' src='close' alt='close' onClick={onClose} />
            </div>
            <div className="mobile_modal_header">
                <Icon className='click' src='backicon' alt='close' onClick={onClose} />
                <h2 className="modal_title">Поиск</h2>
            </div>
            <div className="input_container">
                <Icon src='search' alt='search' className='search_icon_input' />
                <input
                    className="second_section_search"
                    placeholder={t('searchPlaceholder')}
                    value={query}
                    onChange={handleInputChange}
                />
            </div>
            <div className='sp_result_panel'>
                {!isSearching && announcements.length > 0 && (
                    <SearchResponse announcements={announcements} />
                )}
                <div className='sp_result_panel_message'>
                    {isSearching && <Loading />}
                    {!isSearching && error && <SearchError message={error} />}
                    {!isSearching && !error && announcements.length === 0 && <SearchStart />}
                </div>
            </div>
        </div>
    );
}

export default SearchPanel;