import React, { useState, useEffect } from 'react';
import LanguageModal from '../components/LanguageModal';
import { useTranslation } from 'react-i18next';
import fetchUserCountry from '../../../../api/getUserCountry';
import '../style/Main.css';

const Main = ({ menuVisible, toggleMenu }) => {
    const { i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(() => {
        return localStorage.getItem('selectedLanguage') || i18n.language;
    });

    const countryToLanguageMap = {
        Kazakhstan: 'kz',
        Russia: 'ru',
        China: 'cn',
        default: 'en',
    };

    useEffect(() => {
        const setLanguageByCountry = async () => {
            try {
                let userCountry = localStorage.getItem('userCountry');
                if (!userCountry) {
                    userCountry = await fetchUserCountry();
                    localStorage.setItem('userCountry', userCountry);
                }

                const languageCode =
                    localStorage.getItem('selectedLanguage') ||
                    countryToLanguageMap[userCountry] ||
                    countryToLanguageMap.default;

                setSelectedLanguage(languageCode);
                i18n.changeLanguage(languageCode);
            } catch (error) {
                console.error('Error fetching user country:', error);
            }
        };

        setLanguageByCountry();
    }, [i18n]);

    const handleSelectLanguage = (languageCode) => {
        setSelectedLanguage(languageCode);
        localStorage.setItem('selectedLanguage', languageCode);
        i18n.changeLanguage(languageCode);
    };

    return (
        menuVisible && (
            <>
                <div onClick={toggleMenu} className="click navigation_content_background_white"></div>
                <div className="language_modal_content">
                    <LanguageModal
                        selectedLanguage={selectedLanguage}
                        onSelectLanguage={handleSelectLanguage}
                    />
                </div>
            </>
        )
    );
};

export default Main;