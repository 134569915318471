import { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { initializeSocket } from "../utils/socketUtils";
import { getUserInfo } from "../../../api/usercall";

import { ChatsUI } from "../comui/ChatsUI";

const Chats = ({ hideBottomBar }) => {
  const location = useLocation();
  const otherUserId = location.state?.user_id;
  const announcementLink = location.state?.announcement_link;
  const announcementId = location.state?.announcement_id;
  const [currentUserId, setCurrentUserId] = useState(null);
  const [chatList, setChatList] = useState([]);
  const [filteredChatList, setFilteredChatList] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [activeTab, setActiveTab] = useState("Покупаю");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const socketInstance = useRef(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userInfo = await getUserInfo();
        setCurrentUserId(userInfo.user.id);
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserData();

    socketInstance.current = initializeSocket();

    socketInstance.current.on("connect_chat_success", (data) => {
      if (data?.chats) {
        setChatList(data.chats);

        const filtered = data.chats.filter(
          (chat) => chat.announcement_author_id !== currentUserId
        );
        setFilteredChatList(filtered);

        if (otherUserId) {
          const chatWithOtherUser = data.chats.find(
            (chat) => chat.receiver_id === otherUserId
          );
          if (chatWithOtherUser) {
            setSelectedChat(chatWithOtherUser);
            socketInstance.current.emit("join_chat", {
              other_user_id: chatWithOtherUser.receiver_id,
            });

            if (window.innerWidth <= 768) {
              setIsModalOpen(true);
              if (hideBottomBar) hideBottomBar(true);
            }
          }
        }
      }
    });

    return () => {
      if (socketInstance.current) {
        socketInstance.current.disconnect();
      }
    };
  }, [hideBottomBar, otherUserId, currentUserId]);

  useEffect(() => {
    if (currentUserId !== null) {
      const filtered = chatList.filter(
        (chat) =>
          (activeTab === "Покупаю"
            ? chat.announcement_author_id !== currentUserId
            : chat.announcement_author_id === currentUserId) &&
          (chat.username?.toLowerCase() || "").includes(searchQuery.toLowerCase())
      );
      setFilteredChatList(filtered);
    }
  }, [activeTab, chatList, currentUserId, searchQuery]);

  useEffect(() => {
    if (otherUserId && chatList.length > 0) {
      const newChat = chatList.find((chat) => chat.receiver_id === otherUserId);
      if (newChat) {
        setSelectedChat(newChat);
        socketInstance.current.emit("join_chat", {
          other_user_id: newChat.receiver_id,
          announcement_link: announcementLink || newChat.announcement_link || "",
          announcement_id: announcementId || newChat.announcement_id || null
        });

        if (window.innerWidth <= 768) {
          setIsModalOpen(true);
          if (hideBottomBar) hideBottomBar(true);
        }
      }
    }
  }, [otherUserId, announcementLink, announcementId, chatList, hideBottomBar]);

  const handleUserClick = useCallback((chat) => {
    if (!selectedChat || selectedChat.room_id !== chat.room_id) {
      setSelectedChat(chat);
      socketInstance.current.emit("join_chat", {
        other_user_id: chat.receiver_id,
        announcement_link: announcementLink || "",
        announcement_id: announcementId || null
      });

      if (window.innerWidth <= 768) {
        setIsModalOpen(true);
        if (hideBottomBar) hideBottomBar(true);
      }
    }
  }, [selectedChat, announcementId, announcementLink, hideBottomBar]);

  const closeModal = () => {
    setIsModalOpen(false);
    if (hideBottomBar) hideBottomBar(false);
  };

  return (
    ChatsUI(searchQuery, setSearchQuery, activeTab, setActiveTab, chatList, currentUserId, filteredChatList, handleUserClick, selectedChat, isModalOpen, socketInstance, closeModal)
  );
};

export default Chats;