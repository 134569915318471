import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// icons
import walletIcon from '../../../assets/icons/wallet.svg';
// import medalIcon from '../../../assets/icons/medal.svg';
import historyIcon from '../../../assets/icons/history.svg';
import noteIcon from '../../../assets/icons/note.svg';
import chatIcon from '../../../assets/icons/chat.svg';
import gearIcon from '../../../assets/icons/gear.svg';
import heartIcon from '../../../assets/icons/heart.svg';
import templatesIcon from '../../../assets/icons/templates.svg';
import signoutIcon from '../../../assets/icons/signout.svg';

import def_user_icon from '../../images/def_user_icon.svg';
import infoIcon from '../../../assets/icons/info.svg';
import starIcon from '../../../assets/icons/star.svg';

import ProfileMenuTabItem from "../items/profile_meni_tab_item";

import { getUserInfo } from "../../../api/usercall";

import "./style/Menu.css";
import { t } from "i18next";

function Menu({ setActiveTab }) {
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [activeTab, setActiveTabState] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const data = await getUserInfo();
                setUserInfo(data.user);
            } catch (err) {
                console.error("Failed to fetch user information", err);
            }
        };

        fetchUserInfo();

        const currentHash = window.location.hash.replace("#", "");
        if (currentHash) {
            setActiveTab(currentHash);
            setActiveTabState(currentHash);
        }
    }, [setActiveTab]);

    const handleLogout = () => {
        localStorage.clear();
        setShowLogoutModal(false);
        navigate("/sign");
    };

    const handleTabClick = (tabId) => {
        if (tabId === "logout") {
            setShowLogoutModal(true);
        } else {
            setActiveTab(tabId);
            setActiveTabState(tabId);
            window.location.hash = tabId;
        }
    };

    return (
        <div className="profile_menu">
            <div className="profile_menu_mobile_show profile_menu_sidebar">
                <div className="user_profile_info">
                    <div className="user_header_content">
                        <div className="user_image">
                            {userInfo ? (
                                <img
                                    src={userInfo.profilePicture || def_user_icon}
                                    alt="Profile"
                                />
                            ) : (
                                <p></p>
                            )}
                        </div>
                        <div className="user_info">
                            {userInfo ? (
                                <>
                                    <p className="user_info_name">{userInfo.name}</p>
                                    <p className="user_info_id">id: {userInfo.id}</p>
                                </>
                            ) : (
                                <p></p>
                            )}
                        </div>
                    </div>
                    {userInfo && (
                        <div className="user_details">
                            <div className="user_balance_content">
                                <img src={infoIcon} alt="userIcon" />
                                <p>
                                    {t('yourBalance')}:{" "}
                                    <span className="balance">
                                        {userInfo.balance} {t('units')}
                                    </span>
                                </p>
                            </div>
                            <p className="user_raiting_info_content">
                                <span className="user_raiting_info_content_count">
                                    <img src={starIcon} alt="star" />
                                    {t('rating')}{(userInfo.rating).toString().substring(0, 3)}
                                </span>
                                <span className="user_raiting_info_content_count">
                                    {t('feedbacks')}: {" "}
                                    <span className="announcement_reviews_count">
                                        {userInfo.reviews}
                                    </span>
                                </span>
                            </p>
                        </div>
                    )}
                </div>
                <ul className="menu">
                    {[
                        { tabId: "walletTopUp", tabName: t('replenishWallet'), iconPath: walletIcon },
                        // { tabId: "purchasePackage", tabName: t('buyPackage'), iconPath: medalIcon },
                        { tabId: "paymentHistory", tabName: t('paymentHistory'), iconPath: historyIcon },
                        { tabId: "ads", tabName: t('announcements'), iconPath: noteIcon },
                        { tabId: "messages", tabName: t('messages'), iconPath: chatIcon },
                        { tabId: "settings", tabName: t('settings'), iconPath: gearIcon },
                        { tabId: "favorites", tabName: t('favorites'), iconPath: heartIcon },
                        { tabId: "contractTemplates", tabName: t('contractTemplates'), iconPath: templatesIcon },
                        { tabId: "logout", tabName: t('logout'), iconPath: signoutIcon },
                    ].map((item) => (
                        <ProfileMenuTabItem
                            key={item.tabId}
                            setActiveTab={handleTabClick}
                            tabId={item.tabId}
                            tabName={item.tabName}
                            iconPath={item.iconPath}
                            className={activeTab === item.tabId ? "menu-item active" : ""}
                        />
                    ))}
                </ul>
            </div>
            {showLogoutModal && (
                <div className="logout_modal_overlay">
                    <div className="logout_modal_content">
                        <h2>{t('logoutPrompt')}</h2>
                        <div className="logout_modal_buttons">
                            <button onClick={handleLogout}>{t('yes')}</button>
                            <button onClick={() => setShowLogoutModal(false)}>{t('no')}</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Menu;