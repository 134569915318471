import React from 'react';
import { t } from 'i18next';

export function AddButtons(handlePublish, isLoading, handlePreview) {
    return <div className="title_section mobile_show">
        <button
            className="ad_form_buttons publish_button"
            onClick={handlePublish}
            disabled={isLoading}
        >
            {isLoading ? <div className="spinner"></div> : t('publish')}
        </button>
        <button
            className="ad_form_buttons review_button"
            disabled={isLoading}
            onClick={handlePreview}
        >
            {t('preview')}
        </button>
    </div>;
}