import { API_URL } from "../../config/connect";

async function getRejectMessage(id) {
    try {
        const accessToken = localStorage.getItem("access_token");

        if (!accessToken) {
            throw new Error("No access token found");
        }

        const response = await fetch(`${API_URL}/api/v1/announcement/reject-message/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error(`Error fetching rejection message: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error in getRejectMessage:", error);
        throw error;
    }
}

export { getRejectMessage };