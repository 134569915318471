import React from "react";
import "../style/ToggleSwitch.css";
import ToggleSwitch from "../../elements/ToggleSwitch/ToggleSwitch";

import { t } from "i18next";

const ToggleContainer = ({ isContract, isBarter, onContractToggle, onBarterToggle }) => {
    return (
        <div className="toggle-container">
            <div className="toggle-row">
                <label className="toggle-label">{t('negotiable')}</label>
                <ToggleSwitch toggled={isContract} onToggle={onContractToggle} />
            </div>
            <div className="toggle-row">
                <label className="toggle-label">{t('barter')}</label>
                <ToggleSwitch toggled={isBarter} onToggle={onBarterToggle} />
            </div>
        </div>
    );
};

export default ToggleContainer;