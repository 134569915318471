import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import '../style/SubCategory.css';
import rightArrowIcon from '../../../assets/icons/right_arrow.svg';

import { useTranslation } from "react-i18next";

const getSubCategoryName = (subCategory, language) => {
    switch (language) {
        case 'en':
            return subCategory.eng_name;
        case 'kz':
            return subCategory.kz_name;
        case 'cn':
            return subCategory.ch_name;
        default:
            return subCategory.name;
    }
};

const getCategoryName = (category, language) => {
    switch (language) {
        case 'en':
            return category.eng_name;
        case 'kz':
            return category.kz_name;
        case 'cn':
            return category.ch_name;
        default:
            return category.name;
    }
};

function SubCategoryItem({ categoryName, categoryId, subCategory }) {
    const { i18n } = useTranslation();
    const subCategoryName = getSubCategoryName(subCategory, i18n.language);

    return (
        <div className="subcategory_item">
            <img src={rightArrowIcon} alt="right arrow" />
            <a
                href={`/category/${categoryName}/${subCategoryName}/${categoryId}/${subCategory.id}`}
                className="subcategory_link"
            >
                {subCategoryName}
            </a>
        </div>
    );
}

function SubCategory({ category }) {
    const navigate = useNavigate();
    const subCategories = category.subcategories || [];
    const { t, i18n } = useTranslation();

    const [categoryName, setCategoryName] = useState("");

    useEffect(() => {
        setCategoryName(getCategoryName(category, i18n.language));
    }, [i18n.language, category]);

    const handleShowAllClick = () => {
        navigate(`/category/${categoryName}/null/${category.id}/null`);
    };

    return (
        <div className="subcategory_content">
            <div className="subcategory_content_header">
                <h2 className="subcategory_header">{categoryName}</h2>
                <div
                    className="subcategory_header_show_all click"
                    onClick={handleShowAllClick}
                >
                    <p className="text_green mobile_hide">{t('all_ads')}</p>
                    <p className="mobile_show">{t('all_ads')}</p>
                    <img className="mobile_hide" src={rightArrowIcon} alt="View all" />
                </div>
            </div>
            <div className="subcategory_grid">
                {subCategories.length > 0 ? (
                    subCategories
                        .sort((a, b) => (a.name === "Искать по всей рубрике" ? -1 : b.name === "Искать по всей рубрике" ? 1 : 0))
                        .map((subCategory) => (
                            <SubCategoryItem
                                key={subCategory.id}
                                categoryName={categoryName}
                                categoryId={category.id}
                                subCategory={subCategory}
                            />
                        ))
                ) : (
                    <p>{t('no_subcategories')}</p>
                )}
            </div>
        </div>
    );
}

export default SubCategory;