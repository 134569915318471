import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import '../style/Main.css';
import Header from "../../main/components/Header";
import MobileBottomBar from "../../main/components/MobileBottomBar";
import Menu from "../components/Menu";
import WalletTopUp from "../components/WalletTopUp";
import BuyPackage from "../components/BuyPackage";
import PaymentHistory from "../components/PaymentHistory";
import Fooder from "../../main/components/Fooder";
import Advertisements from "../components/Advertisements";
import Chats from "../components/Chats";
import Settings from "../components/Settings";
import Favorites from "../components/Favorites";
import ContractTemplates from "../components/ContractTemplates";
import Path from "../../path/ui/Path";

import { t } from "i18next";

function Main() {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState();
    const [hideBottomBar, setHideBottomBar] = useState(false);

    const tabComponents = {
        walletTopUp: <WalletTopUp setActiveTab={setActiveTab} />,
        purchasePackage: <BuyPackage setActiveTab={setActiveTab} />,
        paymentHistory: <PaymentHistory setActiveTab={setActiveTab} />,
        ads: <Advertisements setActiveTab={setActiveTab} />,
        messages: <Chats hideBottomBar={(isHidden) => setHideBottomBar(isHidden)} />,
        settings: <Settings setActiveTab={setActiveTab} />,
        favorites: <Favorites />,
        contractTemplates: <ContractTemplates setActiveTab={setActiveTab} />
    };

    const tabNames = {
        walletTopUp: t('replenishWallet'),
        purchasePackage: t('buyPackage'),
        paymentHistory: t('paymentHistory'),
        ads: t('announcements'),
        messages: t('messages'),
        settings: t('settings'),
        favorites: t('favorites'),
        contractTemplates: t('contractTemplates')
    };

    useEffect(() => {
        const hash = location.hash.replace('#', '');
        setActiveTab(tabNames[hash] ? hash : "");
    }, [location]);

    return (
        <>
            <div className="profile_main_container">
                <Header />
                <Path
                    currentPath={`${t('profile')}/${tabNames[activeTab] || ''}`}
                    pathTo="/profile"
                />

                <div className="profile_layout">
                    <Menu setActiveTab={setActiveTab} />
                    {activeTab && (
                        <div className="profile_content">{tabComponents[activeTab]}</div>
                    )}
                </div>

                {!hideBottomBar && (
                    <MobileBottomBar activeTabName={activeTab} setActiveTab={setActiveTab} />
                )}
                <Fooder />
            </div>
        </>
    );
}

export default Main;