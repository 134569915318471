import React, { useState } from 'react';
import '../style/authorAds.css';
import { useNavigate } from 'react-router-dom';
import VipAdvertItem from '../../main/items/vip_advert_item';
import arrowIcon from '../../../assets/icons/right_arrow.svg';
import { t } from 'i18next';

const AuthorAds = ({ ads = [], user }) => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 4;

    const totalPages = Math.ceil(ads.length / itemsPerPage);

    const currentAds = ads.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const handleNavigate = () => {
        if (user?.id) {
            navigate("/company", { state: { user } });
        }
    };

    return (
        <div className="author_ads">
            <div className="author_ads_header">
                <h2>{t('otherUserAnnouncements')}</h2>
                <div className="author_ads_header_buttons">
                    <button
                        className="arrow_button left"
                        onClick={handlePrevious}
                        disabled={currentPage === 0}
                    >
                        <img src={arrowIcon} alt="Left" />
                    </button>
                    <button
                        className="arrow_button right"
                        onClick={handleNext}
                        disabled={currentPage === totalPages - 1}
                    >
                        <img src={arrowIcon} alt="Right" />
                    </button>
                </div>
            </div>
            <div className="author_ads_list">
                {currentAds.length > 0 ? (
                    currentAds.map((ad) => (
                        <VipAdvertItem
                            key={ad.id}
                            id={ad.id}
                            imagePath={ad.main_image_url}
                            name={ad.title}
                            price={`${ad.price}`}
                            location={ad.location}
                            date={ad.created_at}
                        />
                    ))
                ) : (
                    <p>Объявлений нет.</p>
                )}
            </div>
            <div className="announcement_button click" onClick={handleNavigate}>
                <p>{t('userAnnouncements')}</p>
                <img className='arrow' src={arrowIcon} alt='arrow' />
            </div>
        </div>
    );
};

export default AuthorAds;