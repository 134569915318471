import { t } from 'i18next';

export function previewFun(
    announcementName, description, price, priceType, images, videoLink,
    userData, selectedLocation, navigate, setIsLoading,
    additionalPhones, setAdditionalPhones, phoneErrors, setPhoneErrors,
    setIsSubmitted, selectedSubcategory, selectedCategory, isBarter, tags, volumeUnit
) {
    const imageUrls = images
        .filter(file => file instanceof File)
        .map(file => URL.createObjectURL(file));

    if (!announcementName || !description) {
        console.error(t('required_fields_missing'));
        setIsSubmitted(true);
        return;
    }

    if (!selectedSubcategory || !selectedLocation) {
        console.error(t('category_or_location_missing'));
        setIsSubmitted(true);
        return;
    }

    const phoneErrorsList = additionalPhones.map(phone => !phone.trim());
    setPhoneErrors(phoneErrorsList);

    if (phoneErrorsList.some(error => error)) {
        console.error(t('empty_phone_numbers'));
        setIsSubmitted(true);
        return;
    }

    const previewData = {
        title: announcementName,
        videoLink,
        description,
        priceType,
        isBarter,
        price,
        selectedCategory,
        selectedSubcategory,
        selectedLocation,
        additionalPhones,
        tagsArray: tags,
        images: imageUrls,
        user_name: userData?.name,
        member_since: userData?.member_since,
        user_rating: userData?.rating,
        user_location: selectedLocation,
        volumeUnit,
    };

    navigate('/preview', { state: { previewData } });
}