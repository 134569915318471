import React from "react";
import agro_logo from '../../images/app_icon_mini.svg';
import { Link } from "react-router-dom";
import { t } from "i18next";
import '../style/NoUserAd.css';

function NoUserAd({ headerText, contentText }) {
    return (
        <div className="no_ads_content">
            <img src={agro_logo} alt="logo" />
            <h2 className="no_ads_content_h2">{headerText}</h2>
            <p className="no_ads_content_p">{contentText}</p>
            <button className="header_create_ad_button">
                <Link className='text_white' to={"/adform"}>{t('createAnnouncement')}</Link>
            </button>
        </div>
    );
}

export default NoUserAd;