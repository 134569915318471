import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationkz from '../locales/app_kz.json';
import translationru from '../locales/app_ru.json';
import translationen from '../locales/app_en.json';
import translationcn from '../locales/app_cn.json';

const savedLanguage = localStorage.getItem('language') || 'kz';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: translationen },
            ru: { translation: translationru },
            kz: { translation: translationkz },
            cn: { translation: translationcn }
        },
        lng: savedLanguage,
        fallbackLng: 'kz',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;