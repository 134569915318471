import TextInput from "../../elements/TextInput/TextInput";
import ImageSelector from "../../elements/ImageSelector/ui/ImageSelector";
import OpenModalButton from "../../elements/OpenModalButton/ui/OpenModalButton";
import ToggleSwitch from "../../elements/ToggleSwitch/ToggleSwitch";
import TagInput from "../../elements/TagInput/TagInput";

import { units } from "../constants/units";

import { t } from "i18next";

import DropdownUnit from "../../../common/components/DropdownUnit";

import warning_icon from '../../../assets/icons/WarningCircle.svg';

export function AddForm(handleImagesChange, handleVideoLinkChange, handleAnnouncementInputChange, isSubmitted, customTags, handleCustomTagsChange, handleAddTag, handleRemoveTag, tagsArray, handleSelectCategory, handleDescriptionChange, priceType, setPriceType, price, setPrice, handleVolumeChange, handleBarterToggle, handlePublish, isLoading, handlePreview, selectedSubcategory, volumeUnit) {
    return <div className="main_form">
        <h2 className="form_heading">{t('announcement')}</h2>
        <div className="title_section">
            <ImageSelector onImagesChange={handleImagesChange} />
            <hr className="divider" />
            <div className="video_link_section">
                <TextInput
                    isRequired={false}
                    onInputChange={handleVideoLinkChange}
                    labelText={t('video_link')}
                    placeholder={t('video_link')}
                    type="url"
                    storageKey="videoLink"
                />
            </div>
        </div>

        <div className="title_section">
            <TextInput
                isRequired={true}
                onInputChange={handleAnnouncementInputChange}
                isSubmitted={isSubmitted}
                errorText={t('required_text')}
                labelText={t('announcement_title')}
                placeholder={t('announcement_title')}
                type="text"
                storageKey="announcementName"
            />
        </div>
        <div className="title_section">
            <TagInput
                value={customTags}
                onChange={handleCustomTagsChange}
                onAddTag={handleAddTag}
                onRemoveTag={handleRemoveTag}
                tags={tagsArray}
                isSubmitted={isSubmitted}
                isRequired={true}
                errorText={t('required_text')}
                placeholder={t('enter_keyword')} />
        </div>
        <div className="title_section">
            <label className="text_input_label">{t('select_category')}</label>
            <div className="input_header">
                {isSubmitted && !selectedSubcategory && (
                    <span className="error_text">{t('required_text')}</span>
                )}

                {isSubmitted && !selectedSubcategory && (
                    <img src={warning_icon} alt='warning' />
                )}
            </div>
            <div style={{ marginTop: 14 }}>
                <OpenModalButton type="category" onSelect={handleSelectCategory} />
            </div>
        </div>
        <div className="title_section">
            <TextInput
                isRequired={true}
                onInputChange={handleDescriptionChange}
                isSubmitted={isSubmitted}
                errorText={t('required_text')}
                labelText={t('description')}
                multiline={true}
                rows={8}
                placeholder={t('description_placeholder')}
                maxLength={1000}
                storageKey="description" />
        </div>

        <div className="title_section">
            <label className="text_input_label">{t('price')}</label>
            <div className="price_inputs">
                <button className={priceType === "fixed" ? "price_button active" : "price_button"} onClick={() => setPriceType("fixed")}>
                    {t('fixed_price')}
                </button>
                <button className={priceType === "negotiable" ? "price_button active" : "price_button"} onClick={() => setPriceType("negotiable")}>
                    {t('negotiable')}
                </button>
            </div>
            <div className="input_header">
                {isSubmitted && !price && (
                    <span className="error_text">{t('required_text')}</span>
                )}

                {isSubmitted && !price && (
                    <img src={warning_icon} alt="warning" />
                )}
            </div>
            <div className="price_section_form">
                <input
                    type="number"
                    className="phone_input price_input"
                    placeholder={t('enter_price')}
                    value={price}
                    onChange={(e) => setPrice(e.target.value)} />
                <DropdownUnit
                    text={t('volume_production')}
                    options={units}
                    selected={volumeUnit}
                    onOptionSelect={handleVolumeChange}
                    style={{ width: "200px" }}
                />
            </div>
            <hr className="divider" style={{ marginTop: 14 }} />
            <div className="toggle-row" style={{ marginTop: 14 }}>
                <label className="toggle-label">{t('barter')}</label>
                <ToggleSwitch onToggle={handleBarterToggle} />
            </div>
        </div>

        <div className="title_section publish_section" style={{}}>
            <button
                className="ad_form_buttons publish_button"
                onClick={handlePublish}
                disabled={isLoading}
            >
                {isLoading ? <div className="spinner"></div> : t("publish")}
            </button>
            <button
                className="ad_form_buttons review_button"
                disabled={isLoading}
                onClick={handlePreview}
            >
                {t('preview')}
            </button>
        </div>
    </div>;
}