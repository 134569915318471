import activedot from './activedot.svg';
import add from './add.svg';
import backicon from './backicon.svg';
import bar from './bar.svg';
import beeline from './beeline.svg';
import calendar from './calendar.svg';
import chat from './chat.svg';
import check from './check.svg';
import checks from './checks.svg';
import close from './close.svg';
import defdot from './defdot.svg';
import deleteIcon from './delete.svg';
import done from './done.svg';
import dots from './dots.svg';
import edit from './edit.svg';
import eye from './eye.svg';
import file from './file.svg';
import filter from './filter.svg';
import galleryadd from './galleryadd.svg';
import gear from './gear.svg';
import glob from './glob.svg';
import heart from './heart.svg';
import history from './history.svg';
import info from './info.svg';
import kaspi from './kaspi.svg';
import leftarrow from './leftarrow.svg';
import loading from './loading.svg';
import map from './map.svg';
import marker from './marker.svg';
import mastercard from './mastercard.svg';
import medal from './medal.svg';
import note from './note.svg';
import paperclip from './paperclip.svg';
import paperplane from './paperplane.svg';
import phone from './phone.svg';
import photo from './photo.svg';
import play from './play.svg';
import right_arrow from './right_arrow.svg';
import rightarrow from './rightarrow.svg';
import rounded_close from './rounded_close.svg';
import search from './search.svg';
import signout from './signout.svg';
import star from './star.svg';
import templates from './templates.svg';
import trash from './trash.svg';
import user from './user.svg';
import wallet from './wallet.svg';
import x from './x.svg';

const icons = {
    activedot,
    add,
    backicon,
    bar,
    beeline,
    calendar,
    chat,
    check,
    checks,
    close,
    defdot,
    deleteIcon,
    done,
    dots,
    edit,
    eye,
    file,
    filter,
    galleryadd,
    gear,
    glob,
    heart,
    history,
    info,
    kaspi,
    leftarrow,
    loading,
    map,
    marker,
    mastercard,
    medal,
    note,
    paperclip,
    paperplane,
    phone,
    photo,
    play,
    right_arrow,
    rightarrow,
    rounded_close,
    search,
    signout,
    star,
    templates,
    trash,
    user,
    wallet,
    x,
};

export default icons;