import React from "react";

import TextInput from "../../elements/TextInput/TextInput";
import { t } from "i18next";

export default function AboutCompany(handleSubmit, handleDescriptionChange, isSubmitted, description) {
    return <div className="menu_right_section_package tab_content content">
        <h2 className="mt_0">{t('aboutCompany')}</h2>
        <hr className="divider"></hr>
        <div className="personal_data_form_content">
            <form className="personal_data_form" onSubmit={handleSubmit}>
                <TextInput
                    isRequired={true}
                    onInputChange={handleDescriptionChange}
                    isSubmitted={isSubmitted}
                    errorText={t("required_text")}
                    labelText={t("description")}
                    multiline={true}
                    rows={8}
                    placeholder={t("description")}
                    maxLength={1000}
                    storageKey="description"
                    value={description} />
                <button type="submit" className="save_button">
                    {t("save")}
                </button>
            </form>
        </div>
    </div>;
}
