import { API_URL } from '../config/connect';

export const getVipAdverts = async () => {
    try {
        const response = await fetch(`${API_URL}/api/v1/announcements?is_vip=true`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching VIP adverts:", error);
        throw error;
    }
};