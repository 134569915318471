import { useCallback } from "react";

export function useMenuButton(setMenuVisibility) {
    return useCallback(
        (menuName) => {
            setMenuVisibility((prev) => ({
                ...prev,
                [menuName]: !prev[menuName],
            }));
        },
        [setMenuVisibility]
    );
}