import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import '../style/VipAdvertItem.css';

import markerIcon from '../../../assets/icons/marker.svg';
import calendarIcon from '../../../assets/icons/calendar.svg';
import heartIcon from '../../../assets/icons/heart.svg';
import minimalIcon from '../../../assets/icons/flash.svg';
import optimalIcon from '../../../assets/icons/flash_opt.svg';
import maximalIcon from '../../../assets/icons/flash_max.svg';
import heartIconActive from '../../../assets/icons/bottomBar/active_heart.svg';

import placeholderImage from '../../../assets/images/noimage.png';

import { addToFavorites, removeFromFavorites, getFavoriteStatus } from "../../../api/favoritecall";

const measurementUnitMap = {
    "piece": "1 шт",
    "kilogram": "1 кг",
    "ton": "1 т",
    "liter": "1 л"
};

function VipAdvertItem(props) {
    const [isFavorite, setIsFavorite] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFavoriteStatus = async () => {
            try {
                const status = await getFavoriteStatus(props.id);
                setIsFavorite(status?.is_favorite || false);
            } catch (error) {
                console.error("Error fetching favorite status:", error);
            }
        };

        if (props.id) {
            fetchFavoriteStatus();
        }
    }, [props.id]);

    const handleFavoriteToggle = async (e) => {
        e.stopPropagation();

        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
            navigate("/sign");
            return;
        }

        try {
            if (isFavorite) {
                await removeFromFavorites(props.id);
            } else {
                await addToFavorites(props.id);
            }
            setIsFavorite((prev) => !prev);
        } catch (error) {
            console.error("Error toggling favorite:", error);
        }
    };

    const handleClick = () => {
        navigate(`/announcement/${props.id}`);
    };

    const getIcon = (packageType) => {
        switch (packageType) {
            case 'minimal':
                return minimalIcon;
            case 'optimal':
                return optimalIcon;
            case 'maximal':
                return maximalIcon;
            default:
                return null;
        }
    };

    const promotionIcon = getIcon(props.promotion_package);

    return (
        <div className={`click vip_advert_item_content ${props.id || ''}`} onClick={handleClick}>
            <div className="vip_advert_item_top">
                <img
                    className="click vip_advert_item_like"
                    src={isFavorite ? heartIconActive : heartIcon}
                    alt="like"
                    onClick={handleFavoriteToggle}
                />
                <img
                    className="vip_advert_image"
                    src={props.imagePath || placeholderImage}
                    alt="vip_advert_image"
                />
                {promotionIcon && (
                    <img className="vip_advert_item_promotion" src={promotionIcon} alt="promotion_icon" />
                )}
                <p className="vip_advert_item_barter_mob">{props.barter ? "Бартер" : ""}</p>
                <p className="vip_advert_item_measurement_unit_mob">{measurementUnitMap[props.measurement_unit] || ''}</p>
            </div>
            <div className="vip_advert_item_detail">
                <p className="vip_advert_item_name">{props.name}</p>
                <div className="price_content">
                    <p className="vip_advert_item_price">{props.price} тг.</p>
                    <p className="vip_advert_item_barter">{measurementUnitMap[props.measurement_unit] || ''}</p>
                    <p className="vip_advert_item_barter">{props.barter ? "Бартер" : ""}</p>
                </div>
                <div className="vip_icon_div">
                    <img src={markerIcon} alt="location" />
                    <div className="vip_icon_div_text">
                        <p>
                            {props.location?.province && `${props.location.province}, `}
                            {props.location?.locality || ''}
                        </p>
                    </div>
                </div>
                <div className="vip_icon_div">
                    <img src={calendarIcon} alt="calendar" />
                    <p>
                        {props.date ? new Date(props.date).toISOString().split('T')[0].replace(/-/g, '.') : 'Invalid date'}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default VipAdvertItem;