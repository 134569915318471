import React from 'react';
import '../style/ProfileAdvertisementsItem.css';
import { useNavigate } from 'react-router-dom';

import deleteIcon from '../../../assets/icons/delete.svg';
import phoneIcon from '../../../assets/icons/phone.svg';
import eyeIcon from '../../../assets/icons/eye.svg';
import editIcon from '../../../assets/icons/edit.svg';
import doneIcon from '../../../assets/icons/done.svg';
import calendarIcon from '../../../assets/icons/calendar.svg';
import markerIcon from '../../../assets/icons/marker.svg';
import chatIcon from '../../../assets/icons/chat.svg';
import heartIcon from '../../../assets/icons/heart.svg';
import closeIcon from '../../../assets/icons/close-circle.svg';
import exportIcon from '../../../assets/icons/export.svg';
import loadingIcon from '../../../assets/icons/spinnergap.svg';
import infoIcon from '../../../assets/icons/info.svg';

import placeholderImage from '../../../assets/images/noimage.png';

import { deleteUserAdById } from '../../../api/deleteUserAd';
import { activateAdById, deactivateAdById } from '../../../api/statusChangeUserAd';
import { getRejectMessage } from '../../../api/get/getRejectMessage';

const ProfileAdvertisementsItem = ({ item }) => {
    const {
        main_image_url,
        title,
        price,
        id,
        phone,
        views,
        likes,
        comments,
        location,
        created_at,
        status,
    } = item;

    const navigate = useNavigate();

    const handleDelete = async () => {
        try {
            await deleteUserAdById(id);
            alert('Advertisement deleted successfully');
        } catch (error) {
            alert('Failed to delete advertisement: ' + error.message);
        }
    };

    const handleActivate = async () => {
        try {
            await activateAdById(id);
            alert('Advertisement activated successfully');
        } catch (error) {
            alert('Failed to activate advertisement: ' + error.message);
        }
    };

    const handleDeactivate = async () => {
        try {
            await deactivateAdById(id);
            alert('Advertisement deactivated successfully');
        } catch (error) {
            alert('Failed to deactivate advertisement: ' + error.message);
        }
    };

    const handleRejectMessage = async () => {
        try {
            const rejectMessage = await getRejectMessage(id);
            alert(`Причина отказа: ${rejectMessage.message || "Причина не указана"}`);
        } catch (error) {
            alert("Не удалось получить причину отказа.");
        }
    };

    return (
        <div className="profile_advertisements_item">
            <img
                className="profile_advertisements_image"
                src={main_image_url || placeholderImage}
                alt={title}
            />
            <div className="profile_advertisements_details">
                <div className='profile_advertisements_title_1'>
                    <div className='profile_advertisements_title_content'>
                        <h3 className="profile_advertisements_title">{title}</h3>
                        <p className="profile_advertisements_price">{price} тг.</p>
                    </div>
                    <p className="profile_advertisements_id">ID: {id}</p>
                </div>
                <div>
                    <div className="profile_advertisements_title_1">
                        <div className="profile_advertisements_info">
                            <div className="info_block">
                                <img src={phoneIcon} alt="Phone" />
                                <span>{phone || 2}</span>
                            </div>
                            <div className="info_block">
                                <img src={eyeIcon} alt="Views" />
                                <span>{views || 12}</span>
                            </div>
                            <div className="info_block">
                                <img src={heartIcon} alt="Likes" />
                                <span>{likes || 0}</span>
                            </div>
                            <div className="info_block">
                                <img src={chatIcon} alt="Comments" />
                                <span>{comments || 0}</span>
                            </div>
                        </div>

                        <div className="profile_advertisements_location_date">
                            <div className="info_block">
                                <img src={markerIcon} alt="Location" />
                                <span>{location?.province}</span>
                            </div>
                            <div className="info_block">
                                <img src={calendarIcon} alt="Date" />
                                <span>{new Date(created_at).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' })}</span>
                            </div>
                        </div>
                    </div>

                    <div className="profile_advertisements_actions">
                        <button
                            className={`action_button ${status === 'pending' ? 'pending' :
                                status === 'inactive' ? 'inactive' :
                                    status === 'rejected' ? 'rejected' : ''
                                }`}
                            onClick={() => {
                                if (status === 'pending' || status === 'inactive') {
                                    handleActivate();
                                } else if (status === 'active') {
                                    handleDeactivate();
                                } else if (status === 'rejected') {
                                    handleRejectMessage();
                                }
                            }}
                        >
                            <img
                                src={status === 'pending' ? loadingIcon :
                                    status === 'inactive' ? doneIcon :
                                        status === 'active' ? closeIcon :
                                            status === 'rejected' ? infoIcon : ''
                                }
                                alt={status === 'active' ? 'Deactivate' : 'Activate'}
                            />
                            {status === 'pending' && 'Объявление проходит модерацию'}
                            {status === 'inactive' && 'Активировать'}
                            {status === 'active' && 'Деактивировать'}
                            {status === 'rejected' && 'Причина отказа'}
                        </button>

                        {status !== 'rejected' && (
                            <button
                                className="action_button"
                                onClick={() => navigate('/edit', { state: { adId: id } })}
                            >
                                <img src={editIcon} alt="Edit" />
                                Редактировать
                            </button>
                        )}

                        {status === 'active' && (
                            <button
                                className="action_button"
                                onClick={() => navigate('/promote', { state: { ad: item } })}
                            >
                                <img src={exportIcon} alt="Promote" /> Продвигать
                            </button>
                        )}

                        {(status === 'inactive' || status === 'rejected') && (
                            <button className="action_button delete" onClick={handleDelete}>
                                <img src={deleteIcon} alt="Delete" /> Удалить
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileAdvertisementsItem;