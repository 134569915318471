import React, { useEffect } from "react";
import '../style/MobileBottomBar.css';
import { Link, useNavigate, useLocation } from "react-router-dom";
import MobileBottomItem from "../items/mobile_bottom_item";
import { t } from "i18next";

import def_house_icon from '../../../assets/icons/bottomBar/def_house.svg';
import active_house_icon from '../../../assets/icons/bottomBar/active_house.svg';
import def_add_icon from '../../../assets/icons/bottomBar/def_add.svg';
import active_add_icon from '../../../assets/icons/bottomBar/active_add.svg';
import def_heart_icon from '../../../assets/icons/heart.svg';
import active_heart_icon from '../../../assets/icons/bottomBar/active_heart.svg';
import def_user_icon from '../../../assets/icons/bottomBar/def_user.svg';
import active_user_icon from '../../../assets/icons/bottomBar/active_user.svg';
import def_chat_icon from '../../../assets/icons/chat.svg';
import active_chat_icon from '../../../assets/icons/bottomBar/active_chat.svg';

function MobileBottomBar({ activeTabName }) {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const protectedRoutes = ["/profile", "/messages", "/adform"];
        const accessToken = localStorage.getItem("access_token");

        if (!accessToken && protectedRoutes.includes(location.pathname)) {
            navigate("/sign");
        }
    }, [navigate, location.pathname]);

    const getIcon = (tabName, defIcon, activeIcon) =>
        activeTabName === tabName ? activeIcon : defIcon;

    return (
        <div className='mobile_bottom_bar_conetent'>
            <Link to="/">
                <MobileBottomItem
                    iconPath={getIcon("home", def_house_icon, active_house_icon)}
                    itemName={t("home")}
                />
            </Link>
            <Link to={"/profile#favorites"}>
                <MobileBottomItem
                    iconPath={getIcon("favorites", def_heart_icon, active_heart_icon)}
                    itemName={t("favorites")}
                />
            </Link>
            <Link to="/adform">
                <MobileBottomItem
                    center_icon
                    iconPath={getIcon("add", def_add_icon, active_add_icon)}
                    itemName={t("create")}
                />
            </Link>
            <Link to={"/profile#messages"}>
                <MobileBottomItem
                    iconPath={getIcon("messages", def_chat_icon, active_chat_icon)}
                    itemName={t("messages")}
                />
            </Link>
            <Link to="/profile">
                <MobileBottomItem
                    iconPath={getIcon("profile", def_user_icon, active_user_icon)}
                    itemName={t("profile")}
                />
            </Link>
        </div>
    );
}

export default MobileBottomBar;