import React, { useState } from 'react';

import Header from '../../main/components/Header';
import Fooder from '../../main/components/Fooder';
import MobileBottomBar from '../../main/components/MobileBottomBar';

import off_icon from '../../../assets/icons/offIcon.svg';

import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from '../../../language/i18n';

import '../style/main.css';

function Main() {
    const { t } = useTranslation();
    const [bottomBarHidden, setBottomBarHidden] = useState(false);

    const handleBottomBarToggle = (isHidden) => {
        setBottomBarHidden(isHidden);
    };

    return (
        <I18nextProvider i18n={i18n}>
            <Header onToggleMenu={handleBottomBarToggle} />
            <div className='content_404'>
                <div className='content_404_wr'>
                    <img src={off_icon} alt='off_icon' />
                    <h1>{t('title_404')}</h1>
                    <p>{t('message_404')}</p>
                </div>
            </div>
            <Fooder />
            {!bottomBarHidden && <MobileBottomBar activeTabName={'home'} />}
        </I18nextProvider>
    );
}

export default Main;