import React from "react";
import "../style/RangeInput.css";
import { t } from "i18next";

const RangeInput = ({ minValue, maxValue, onMinChange, onMaxChange }) => {
    const handleMinChange = (e) => {
        const value = Number(e.target.value);
        if (value < maxValue) {
            onMinChange(value);
        }
    };

    const handleMaxChange = (e) => {
        const value = Number(e.target.value);
        if (value > minValue) {
            onMaxChange(value);
        }
    };

    const calculateLeft = () => ((minValue - 1000) / (10000 - 1000)) * 100 + 10;
    const calculateRight = () => 100 - ((maxValue - 1000) / (10000 - 1000)) * 100;

    return (
        <>
            <div className="range_input_container">
                <div className="range_input_box">
                    <span className="label">{t('from')}</span>
                    <input
                        type="number"
                        className="range_input"
                        value={minValue}
                        onChange={handleMinChange}
                    />
                </div>
                <div className="separator"></div>
                <div className="range_input_box">
                    <span className="label">{t('to')}</span>
                    <input
                        type="number"
                        className="range_input"
                        value={maxValue}
                        onChange={handleMaxChange}
                    />
                </div>
            </div>
            <div className="slider_container">
                <div
                    className="range_filled"
                    style={{
                        left: `${calculateLeft()}%`,
                        right: `${calculateRight()}%`,
                    }}
                ></div>
                <input
                    type="range"
                    min="1000"
                    max="10000"
                    value={minValue}
                    onChange={handleMinChange}
                    className="range_slider"
                />
                <input
                    type="range"
                    min="1000"
                    max="10000"
                    value={maxValue}
                    onChange={handleMaxChange}
                    className="range_slider max_slider"
                />
            </div>
        </>
    );
};

export default RangeInput;