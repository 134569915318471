import React from 'react';
import '../style/Main.css';
import Login from '../in/Login';

const Main = () => {
  return (
    <Login />
  );
};

export default Main;