import React from "react";

function ProfileMenuTabItem(props) {
    return (
        <li
            onClick={() => props.setActiveTab(props.tabId)}
            className={`${props.className}`}
        >
            <div className="menu-item-content">
                <div className="active_tab_left_user"></div>
                <img
                    className="profile_menu_icon"
                    src={props.iconPath}
                    alt={props.tabName}
                />
                <span>{props.tabName}</span>
            </div>
            <i className="profile_menu_icon_right fa-solid fa-chevron-right"></i>
        </li>
    );
}

export default ProfileMenuTabItem;