import React from 'react';
import checkIcon from '../../../assets/icons/check.svg';
import checksIcon from '../../../assets/icons/checks.svg';
import '../style/ChatUserItem.css';

import user_profile_photo from '../../images/def_user_icon.svg';

const ChatUserItem = ({ user, onClick, isActive }) => {
    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const hours = String(date.getUTCHours()).padStart(2, "0");
        const minutes = String(date.getUTCMinutes()).padStart(2, "0");
        return `${hours}:${minutes}`;
    };

    return (
        <div className={`chat_user_item_classname ${isActive ? 'active' : ''}`} onClick={() => onClick(user)}>
            <div className="user-info">
                <img src={user_profile_photo} alt={user.name} className="avatar" />
                <div className="details">
                    <div className="chat_user_header">
                        <div className="message-preview crop_text_10">{user.receiver_name}</div>
                        <div className="message-preview crop_text_10">{formatTimestamp(user.timestamp)}</div>
                    </div>
                    <div className="name-and-status">
                        <span className={`status ${user.announcement_status === 'active' ? 'active' : 'inactive'}`}>
                            {user.announcement_status === 'active' ? 'Активно' : 'Неактивно'}
                        </span>
                        <span className="name crop_text_10">{user.announcement_title}</span>
                    </div>
                    <div className="chat_user_header">
                        <div className="message-preview crop_text_10">{user.message}</div>
                        <div className="time-and-icon">
                            <span className="time">{user.time}</span>
                            {user.isChecked ? (
                                <img src={checksIcon} alt="Checked" className="check-icon" />
                            ) : (
                                <img src={checkIcon} alt="Check" className="check-icon" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatUserItem;