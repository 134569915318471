import React, { useEffect } from "react";
import '../style/Fooder.css';

import app_icon from '../../../assets/icons/long_icon.svg';
import logo_icon from '../../../assets/images/logo.jpg';
import telegram_icon from '../../../assets/icons/telegram.svg';
import instagram_icon from '../../../assets/icons/instagram.svg';
import tiktok_icon from '../../../assets/icons/tiktok.svg';
import linkedin_icon from '../../../assets/icons/linkedin.svg';

import DownloadItem from "../items/download_item";
import googleIcon from '../../images/google-play.svg';
import appleIcon from '../../images/apple.svg';

import { useTranslation } from "react-i18next";

function Fooder() {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const handleLanguageChange = (lang) => {
            localStorage.setItem('language', lang);
        };

        i18n.on('languageChanged', handleLanguageChange);
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n]);

    return (
        <div className="fooder_content">
            <div className="fooder_top">
                <div className="fooder_left">
                    <img className="fooder_app_icon" src={app_icon} alt="Agroland Logo" />
                    <p className="fooder_left_text">{t("platform_description")}</p>
                    <hr className="divider"></hr>
                    <img className="fooder_app_icon_logo" src={logo_icon} alt="Agroland Logo" />
                    <p className="fooder_left_text">{t('project_support')} </p>
                </div>
                <div className="fooder_center">
                    <p className="fooder_panel text_green">{t("navigation")}</p>
                    <ul className="fooder_list">
                        <li className="click"><a className="link" href="/aboutus">{t("aboutUs")}</a></li>
                        <li className="click"><a className="link" href="/aboutus#articles">{t("news")}</a></li>
                        <li className="click"><a className="link" href="/aboutus#contacts">{t("contacts")}</a></li>
                        <li className="click"><a className="link" href="/aboutus#policy">{t("privacyPolicy")}</a></li>
                        <li className="click"><a className="link" href="/aboutus#terms">{t("termsOfUse")}</a></li>
                        <li className="click"><a className="link" href="/aboutus#faq">{t("howToBuyAndSell")}</a></li>
                    </ul>
                </div>
                <div className="fooder_right">
                    <p className="navigation_fooder_right_number">+7 (701) 766-34-43</p>
                    <ul className="fooder_list navigation_fooder_list">
                        <li><img src={telegram_icon} alt="telegram" /></li>
                        <li><img src={instagram_icon} alt="instagram" /></li>
                        <li><img src={tiktok_icon} alt="tiktok" /></li>
                        <li><img src={linkedin_icon} alt="linkedin" /></li>
                    </ul>
                    <a className="link" rel="noreferrer" target="_blank" href="mailto:support@agroland.kz">support@agroland.kz</a>
                    <a className="fooder_right_address link" rel="noreferrer" target="_blank" href="https://go.2gis.com/3loks">
                        <span>{t("addressLine1", "Бостандыкский район, мкр-н Орбита-4, дом 11, офис 357")}</span>
                        <span>{t("addressLine2", "050050, KZ, Алматы")}</span>
                    </a>
                    <div className="fooder_right_download">
                        <DownloadItem iconPath={googleIcon} itemText={t("googlePlay", "Google Play")} downloadLink={"https://play.google.com/store/apps/details?id=com.agroland.app&pcampaignid=web_share"} />
                        <DownloadItem iconPath={appleIcon} itemText={t("appStore", "App Store")} downloadLink={"https://apps.apple.com/us/app/agroland/id6743326709"} />
                    </div>
                </div>
            </div>
            <div className="fooder_bottom">
                <p>© {t("service_name")}, 2025</p>
            </div>
        </div>
    );
}

export default Fooder;