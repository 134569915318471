import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/anim/loading.json';

const Loading = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div style={fullscreenOverlay}>
            <Lottie options={defaultOptions} height={400} width={400} />
        </div>
    );
};

const fullscreenOverlay = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

export default Loading;