import React from "react";
import { useNavigate } from "react-router-dom";

import calendarIcon from '../../../assets/icons/calendar.svg';

import placeholderImage from '../../../assets/images/noimage.png';

function NewItem(props) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/news/${props.id}`);
    };

    return (
        <div className={`click vip_advert_item_content ${props.id || ''}`} onClick={handleClick}>
            <div className="vip_advert_item_top">
                <img
                    className="vip_advert_image"
                    src={props.imagePath || placeholderImage}
                    alt="vip_advert_image"
                />
            </div>
            <div className="vip_advert_item_detail">
                <p className="vip_advert_item_name">{props.name}</p>
                <div className="vip_icon_div">
                    <img src={calendarIcon} alt="calendar" />
                    <p>
                        {props.date ? new Date(props.date).toISOString().split('T')[0].replace(/-/g, '.') : 'Invalid date'}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default NewItem;