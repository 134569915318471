import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Fooder from '../../main/components/Fooder';
import Header from '../../main/components/Header';
import MobileBottomBar from '../../main/components/MobileBottomBar';

import { I18nextProvider } from 'react-i18next';
import i18n from '../../../language/i18n';
import Path from '../../path/ui/Path';
import CompanyHeader from '../components/CompanyHeader';
import CompanyNavigation from '../components/CompanyNavigation';
import CompanyContent from '../components/CompanyContent';

import { getAnyUserInfo } from '../../../api/getAnyUserInfo';
import { t } from 'i18next';

function Main() {
  const [bottomBarHidden, setBottomBarHidden] = useState(false);
  const [activeTab, setActiveTab] = useState('ads');

  const location = useLocation();
  const userFromState = location.state?.user;

  const handleBottomBarToggle = (isHidden) => {
    setBottomBarHidden(isHidden);
  };

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (userFromState.id) {
      getAnyUserInfo(userFromState.id).then((response) => {
        setUserData(response);
      });
    }
  }, [userFromState]);

  return (
    <I18nextProvider i18n={i18n}>
      <Header onToggleMenu={handleBottomBarToggle} />
      <Path currentPath={t('user_page')} />

      <CompanyHeader user={userData} />
      <CompanyNavigation activeTab={activeTab} setActiveTab={setActiveTab} user={userData} />
      <CompanyContent activeTab={activeTab} announcements_by_category={userData?.announcements_by_category} />

      <Fooder />
      {!bottomBarHidden && <MobileBottomBar activeTabName={'home'} />}
    </I18nextProvider>
  );
}

export default Main;