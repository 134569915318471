import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Header from '../../main/components/Header';
import MainImageSlider from '../../announcement/components/MainImageSlider';
import Description from '../../announcement/components/Description';
import Path from '../../path/ui/Path';
import MobileBottomBar from '../../main/components/MobileBottomBar';
import Fooder from '../../main/components/Fooder';
import { postAnnouncement } from '../../../api/createannouncement';
import { Buttons } from '../../review/components/Buttons';

import { clearDB } from '../../../utils/indexedDBService';

const Main = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const previewData = location.state?.previewData;

    const [isLoading, setIsLoading] = useState(false);

    if (!previewData) {
        return (
            <div className="error_message">
                <p>No data to preview. Please fill out the form first.</p>
                <button onClick={() => navigate(-1)}>Go Back</button>
            </div>
        );
    }

    const handlePublish = async () => {
        setIsLoading(true);

        const formData = new FormData();

        formData.append("title", previewData.title || "");
        formData.append("video_url", previewData.videoLink || "");
        formData.append("description", previewData.description || "");
        formData.append("price_type", previewData.priceType || "");
        formData.append("price", previewData.priceType === "fixed" ? previewData.price : "");
        formData.append("isBarter", previewData.isBarter ? "true" : "false");

        if (previewData.selectedCategory) {
            formData.append("category_id", previewData.selectedCategory);
        } else {
            alert("Выберите категорию!");
            setIsLoading(false);
            return;
        }

        if (previewData.selectedSubcategory) {
            formData.append("subcategory_id", previewData.selectedSubcategory);
        } else {
            alert("Выберите подкатегорию!");
            setIsLoading(false);
            return;
        }

        if (previewData.selectedLocation) {
            formData.append("user_location_id", previewData.selectedLocation.address_id);
        } else {
            alert("Выберите местоположение!");
            setIsLoading(false);
            return;
        }

        if (previewData.volumeUnit) {
            const volumeUnitMap = {
                "1 Штук": "piece",
                "1 Килограм": "kilogram",
                "1 Тонна": "ton",
                "1 Литр": "liter"
            };

            const convertedVolumeUnit = volumeUnitMap[previewData.volumeUnit] || previewData.volumeUnit;
            formData.append("measurement_unit", convertedVolumeUnit);
        } else {
            alert("Выберите единицу измерения!");
            setIsLoading(false);
            return;
        }

        if (Array.isArray(previewData.additionalPhones)) {
            previewData.additionalPhones.forEach((phone, index) => {
                formData.append(`additionalPhones[${index}]`, phone);
            });
        }

        if (Array.isArray(previewData.tagsArray)) {
            previewData.tagsArray.forEach(tag => {
                formData.append("tags[]", tag.trim());
            });
        }


        async function urlToBlob(url) {
            const response = await fetch(url);
            const blob = await response.blob();
            return new File([blob], "image.jpg", { type: blob.type });
        }

        if (Array.isArray(previewData.images)) {
            const imageFiles = await Promise.all(previewData.images.map(async (file) => {
                if (typeof file === "string") {
                    return urlToBlob(file);
                }
                return file;
            }));

            imageFiles.forEach(file => formData.append("images", file));
        }

        try {
            const accessToken = localStorage.getItem("access_token");
            const response = await postAnnouncement(formData, accessToken);

            if (response?.announcement_id) {
                localStorage.removeItem("priceType");
                localStorage.removeItem("announcementName");
                localStorage.removeItem("videoLink");
                localStorage.removeItem("description");
                localStorage.removeItem("price");
                localStorage.removeItem("tagsArray");
                localStorage.removeItem("volumeUnit");
                localStorage.removeItem("selectedLocation");
                localStorage.removeItem("selectedCategory");
                localStorage.removeItem("selectedCategoryName");
                localStorage.removeItem("selectedSubcategory");
                localStorage.removeItem("selectedSubcategoryName");
                clearDB("savedImages")
                clearDB("savedImageFiles")
                navigate(`/announcement/${response.announcement_id}`);
            } else {
                console.error("Announcement created but ID is missing in the response");
            }
        } catch (error) {
            console.error("Error creating announcement:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const { title, description, price, priceType, images, videoLink, user_name, member_since, user_rating, user_location, volumeUnit, isBarter } = previewData;

    const user = {
        name: user_name,
        member_since: member_since,
        rating: user_rating,
    };

    return (
        <div className="main_container">
            <Header />
            <Path currentPath="Предпросмотр объявления" />
            <div className="announcement_content">
                <div className="main_content">
                    <MainImageSlider
                        id={"55774869"}
                        images={Array.isArray(images) && images.length > 0 ? images : []}
                        description={description || "No description available"}
                        views={0}
                        videoUrl={videoLink || null}
                        isLoading={isLoading}
                    />
                    <Buttons
                        handlePublish={handlePublish}
                        isLoading={isLoading}
                        isMobile={window.innerWidth >= 768}
                    />
                    <div className="main_details">
                        <Description
                            title={title || "Untitled"}
                            price={`${price || 0} тг. `}
                            user={user}
                            location={user_location}
                            unit={volumeUnit}
                            isBarter={isBarter}
                            priceType={priceType}
                        />
                    </div>
                </div>
                <MobileBottomBar />
                <Fooder />
                <Buttons
                    handlePublish={handlePublish}
                    isLoading={isLoading}
                    isMobile={window.innerWidth <= 768}
                />
            </div>
        </div>
    );
};

export default Main;