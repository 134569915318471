import React, { useState } from 'react';
import '../style/similarAds.css';
import VipAdvertItem from '../../main/items/vip_advert_item';
import { Link } from 'react-router-dom';

import rightArrowIcon from '../../../assets/icons/right_arrow.svg';

const SimilarAds = ({ ads = [] }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 4;

    const totalPages = Math.ceil(ads.length / itemsPerPage);

    const currentAds = ads.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    return (
        <div className="sim_ads">
            <div className="author_ads_header">
                <h2>Похожие объявления</h2>
                <div className="author_ads_header_buttons">
                    <button
                        className="arrow_button left"
                        onClick={handlePrevious}
                        disabled={currentPage === 0}
                    >
                        <img src={rightArrowIcon} alt="Left" />
                    </button>
                    <button
                        className="arrow_button right"
                        onClick={handleNext}
                        disabled={currentPage === totalPages - 1}
                    >
                        <img src={rightArrowIcon} alt="Right" />
                    </button>
                </div>
            </div>
            <div className="author_ads_list">
                {currentAds.length > 0 ? (
                    currentAds.map((ad) => (
                        <Link to={`/announcement/${ad.id}`} key={ad.id}>
                            <VipAdvertItem
                                imagePath={ad.main_image_url}
                                name={ad.title}
                                price={`${ad.price}`}
                                location={ad.location}
                                date={ad.created_at}
                            />
                        </Link>
                    ))
                ) : (
                    <p>Похожие объявления отсутствуют.</p>
                )}
            </div>
        </div>
    );
};

export default SimilarAds;