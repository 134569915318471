import React from 'react';
import '../style/LanguageItem.css';
import Icon from '../../../../common/components/Icon';

const LanguageItem = ({ language, isSelected, onClick }) => {
    return (
        <div className={`language_item ${isSelected ? 'selected' : ''}`} onClick={onClick}>
            {language}
            {isSelected && <Icon src="check" alt="done" />}
        </div>
    );
};

export default LanguageItem;