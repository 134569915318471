import React from 'react';
import '../style/SearchError.css';

function SearchError({ message }) {
    return (
        <div className="search_error_section">
            <p>{message}</p>
        </div>
    );
}

export default SearchError;