import React from 'react';
import '../style/Result.css';
import CategoryAdItem from '../items/category_ad_item';

const Result = ({ items }) => {
    return (
        <div className="result_grid">
            {items.map((item, index) => (
                <CategoryAdItem key={index} item={item} />
            ))}
        </div>
    );
};

export default Result;