import React from "react";

import "../style/Chats.css";
import ChatUserItem from "../items/chat_user_item";
import ChatConversation from '../components/ChatConversation';

import menu_dot from "../../../assets/icons/dots_three_outline_vertical.svg";
import search_icon from "../../../assets/icons/search.svg";
import off_icon from "../../../assets/icons/offIcon.svg";
import { t } from "i18next";

export function ChatsUI(searchQuery, setSearchQuery, activeTab, setActiveTab, chatList, currentUserId, filteredChatList, handleUserClick, selectedChat, isModalOpen, socketInstance, closeModal) {
    return <div className="chat_content">
        <h2 className="menu_right_section_header">{t('messages')}</h2>
        <div className="profile_chat">
            <div className="chat_list">
                <div className="chats_header">
                    <div className="chats_header_search">
                        <div className="chats_header_search_menu_button click">
                            <img src={menu_dot} alt="menu_dot" />
                        </div>
                        <div style={{ width: "auto" }} className="input_container">
                            <img
                                className="search_icon_input"
                                src={search_icon}
                                alt={t("search")}
                                style={{ margin: 24 }} />
                            <input
                                className="second_section_search"
                                placeholder={t("search")}
                                style={{ backgroundColor: "#F9F9F9", marginLeft: 10 }}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)} />
                        </div>
                    </div>
                    <div className="chat_filters">
                        <button
                            className={`filter-btn ${activeTab === "Покупаю" ? "active" : ""}`}
                            onClick={() => setActiveTab("Покупаю")}
                        >
                            {t('buying')}{" "}
                            <span className="chat_filter_count">
                                {chatList.filter(
                                    (chat) => chat.announcement_author_id !== currentUserId
                                ).length}
                            </span>
                        </button>
                        <button
                            className={`filter-btn ${activeTab === "Продаю" ? "active" : ""}`}
                            onClick={() => setActiveTab("Продаю")}
                        >
                            {t('selling')}{" "}
                            <span className="chat_filter_count">
                                {chatList.filter(
                                    (chat) => chat.announcement_author_id === currentUserId
                                ).length}
                            </span>
                        </button>
                    </div>
                </div>
                <div className="chats_list">
                    {filteredChatList.length > 0 ? (
                        filteredChatList.map((chat) => (
                            <ChatUserItem
                                key={chat.room_id}
                                user={chat}
                                onClick={() => handleUserClick(chat)}
                                isActive={selectedChat?.room_id === chat.room_id} />
                        ))
                    ) : (
                        <div className="no_chat_message">
                            <img src={off_icon} alt="off_icon" />
                        </div>
                    )}
                </div>
            </div>

            <div className={`chat-details ${isModalOpen ? "modal-open" : ""}`}>
                {selectedChat ? (
                    <ChatConversation
                        key={selectedChat?.room_id}
                        chat={selectedChat}
                        currentUserId={currentUserId}
                        socketInstance={socketInstance.current}
                        closeModal={closeModal} />
                ) : (
                    <div className="no_chat_selected">
                        <img src={off_icon} alt="off_icon" />
                    </div>
                )}
            </div>
        </div>
    </div>;
}