import React from "react";
import Header from "../components/Header";
import ModalList from "../components/ModalList";
import '../styles/Main.css'

function Main({ menuVisible, toggleMenu }) {
    return (
        menuVisible && (
            <>
                <div onClick={toggleMenu} className="click navigation_content_background_white"></div>
                <div className="user_modal_content">
                    <Header />
                    <ModalList />
                </div>
            </>
        )
    );
}

export default Main;