import { t } from 'i18next';

export function changesFun(setAnnouncementName, setVideoLink, setDescription, setSelectedCategory, setSelectedSubcategory, setIsBarter, setImages, setSelectedLocation, setCustomTags, setVolumeUnit) {
    const saveToLocalStorage = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    };

    const handleAnnouncementInputChange = (value) => setAnnouncementName(value);
    const handleVideoLinkChange = (value) => setVideoLink(value);
    const handleDescriptionChange = (value) => setDescription(value);

    const handleSelectCategory = ({ categoryId, subcategoryId }) => {
        setSelectedCategory(categoryId.id);
        setSelectedSubcategory(subcategoryId.id);
        saveToLocalStorage("selectedCategory", categoryId.id);
        saveToLocalStorage("selectedSubcategory", subcategoryId.id);
    };

    const handleBarterToggle = (toggled) => setIsBarter(toggled);
    const handleImagesChange = (imageFiles) => setImages(imageFiles);

    const handleSelectLocation = (location) => {
        setSelectedLocation(location);
        saveToLocalStorage("selectedLocation", location);
    };

    const handleCustomTagsChange = (e) => {
        const value = e.target.value;
        setCustomTags(value);
        saveToLocalStorage("customTags", value);
    };

    const handleVolumeChange = (selectedOption) => {
        const mapping = {
            [`1 ${t("piece")}`]: "piece",
            [`1 ${t("kilogram")}`]: "kilogram",
            [`1 ${t("ton")}`]: "ton",
            [`1 ${t("liter")}`]: "liter"
        };

        localStorage.setItem("volumeUnit", selectedOption);
        setVolumeUnit(mapping[selectedOption] || null);
    };

    return {
        handleImagesChange,
        handleVideoLinkChange,
        handleAnnouncementInputChange,
        handleCustomTagsChange,
        handleSelectCategory,
        handleDescriptionChange,
        handleVolumeChange,
        handleBarterToggle,
        handleSelectLocation
    };
}