import { deleteUserLocationById } from "../../../api/post/deleteUserLocation";
import { t } from 'i18next';

export function deleteAdressFun(setUserLocations, selectedLocation, setSelectedLocation) {
    return async (addressId) => {
        try {
            const confirmation = window.confirm(t('confirm_delete_address'));
            if (!confirmation) return;

            await deleteUserLocationById(addressId);

            setUserLocations((prevLocations) => prevLocations.filter((location) => location.address_id !== addressId)
            );

            if (selectedLocation === addressId) {
                setSelectedLocation(null);
            }

            console.log(t('address_deleted'));
        } catch (error) {
            console.error(t('error_deleting_address'), error);
            alert(t('delete_address_error'));
        }
    };
}