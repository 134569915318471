import { API_URL } from "../config/connect";

export async function getSuggestions(query = "") {
    try {
        const url = `${API_URL}/api/v1/search-suggestions?q=${encodeURIComponent(query)}&page=1&limit=10`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            const errorMessage = `Error: ${response.status} ${response.statusText}`;
            console.error(errorMessage);
            throw new Error(errorMessage);
        }

        const announcements = await response.json();
        return announcements;
    } catch (error) {
        console.error("Error fetching announcements:", error.message);
        throw error;
    }
}