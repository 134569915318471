import React, { useState, useEffect } from 'react';
import '../style/Second.css';
import { useTranslation } from 'react-i18next';

import search_icon from '../../../assets/icons/search.svg';
import { getSuggestions } from '../../../api/searchSuggations';

import ResultItem from '../../modals/search_modal/items/ResultItem';

function Second() {
    const { t, i18n } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [error, setError] = useState(null);
    // const [isSearching, setIsSearching] = useState(false);
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        const userCountry = localStorage.getItem('userCountry') || 'Kazakhstan';
        const savedLanguage = localStorage.getItem('language');
        const defaultLanguage = userCountry === 'Kazakhstan' ? 'kz' : 'en';

        const initialLanguage = savedLanguage || defaultLanguage;
        i18n.changeLanguage(initialLanguage);
    }, [i18n]);

    useEffect(() => {
        const handleLanguageChange = (lang) => {
            localStorage.setItem('language', lang);
        };

        i18n.on('languageChanged', handleLanguageChange);
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n]);

    const handleInputChange = async (e) => {
        const inputValue = e.target.value;
        setSearchQuery(inputValue);

        if (inputValue.trim() === '') {
            setSearchResults([]);
            setError(null);
            return;
        }

        // setIsSearching(true);
        try {
            const result = await getSuggestions(inputValue);
            setSearchResults(result);
            setError(null);
        } catch (err) {
            setError(t('errorLoadingAds'));
        } finally {
            // setIsSearching(false);
        }
    };

    const displayedResults = showAll ? searchResults : searchResults.slice(0, 10);

    return (
        <div className="second_section">
            <h2 className="second_section_text">{t('farmerAds')}</h2>
            <div className="input_container">
                <img
                    className="search_icon_input"
                    src={search_icon}
                    alt={t('search')}
                />
                <input
                    className="second_section_search"
                    placeholder={t('search')}
                    value={searchQuery}
                    onChange={handleInputChange}
                />
            </div>
            {error && <p className="error_message">{error}</p>}
            {displayedResults.length > 0 && (
                <div className="search_results">
                    {displayedResults.map((item) => (
                        <div
                            key={item.id}
                            className="search_result_item"
                        >
                            <ResultItem
                                title={item.title}
                                categoryName={item.category}
                                subCategoryName={item.subCategory}
                                categoryId={item.categoryId}
                                subCategoryId={item.subCategoryId}
                                location={item.location.locality}
                            />
                        </div>
                    ))}
                </div>
            )}
            {searchResults.length > 10 && !showAll && (
                <button
                    className="show_more_button"
                    onClick={() => setShowAll(true)}
                >
                    {t('showMore')}
                </button>
            )}
        </div>
    );
}

export default Second;