import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/CategoryAdItem.css';

import markerIcon from '../../../assets/icons/marker.svg';
import calendarIcon from '../../../assets/icons/calendar.svg';
import heartIcon from '../../../assets/icons/heart.svg';
import heartIconActive from '../../../assets/icons/bottomBar/active_heart.svg';

import { addToFavorites, removeFromFavorites, getFavoriteStatus } from "../../../api/favoritecall";

const CategoryAdItem = ({ item }) => {
    const [isFavorite, setIsFavorite] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchFavoriteStatus() {
            try {
                const status = await getFavoriteStatus(item.id);
                setIsFavorite(status.is_favorite);
            } catch (error) {
                console.error("Error fetching favorite status:", error);
            }
        }

        fetchFavoriteStatus();
    }, [item.id]);

    const handleFavoriteToggle = async (e) => {
        e.stopPropagation();

        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
            navigate('/sign');
            return;
        }

        try {
            if (isFavorite) {
                await removeFromFavorites(item.id);
            } else {
                await addToFavorites(item.id);
            }
            setIsFavorite(!isFavorite);
        } catch (error) {
            console.error("Error toggling favorite:", error);
        }
    };

    const handleClick = () => {
        navigate(`/announcement/${item.id}`);
    };

    return (
        <div className="click category_ad_item" onClick={handleClick}>
            <div className="vip_advert_item_top">
                <img
                    className="click vip_advert_item_like"
                    src={isFavorite ? heartIcon : heartIconActive}
                    alt="like"
                    onClick={handleFavoriteToggle}
                />
                <div className="vip_advert_image_wrapper">
                    <img src={item.main_image_url} alt={item.title} className="vip_advert_image" />
                </div>
            </div>
            <div className="vip_advert_item_detail">
                <h3 className="vip_advert_item_name">{item.title}</h3>
                <div className="price_content">
                    <p className="vip_advert_item_price">{item.price} тг.</p>
                    {item.contract && <p className="vip_advert_item_barter">Договорная</p>}
                </div>
                <div className="vip_icon_div">
                    <img src={markerIcon} alt="location" />
                    <div className="vip_icon_div_text">
                        <p>{item.location?.province}</p>
                        <p>{item.location?.locality}</p>
                    </div>
                </div>
                <div className="vip_icon_div">
                    <img src={calendarIcon} alt="calendar" />
                    <p>
                        {item.created_at ? new Date(item.created_at).toISOString().split('T')[0].replace(/-/g, '.') : 'Invalid date'}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CategoryAdItem;