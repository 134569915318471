import { API_URL } from "../config/connect";

async function getUserAdd(status) {
    try {
        const accessToken = localStorage.getItem("access_token");

        if (!accessToken) {
            return;
        }

        const response = await fetch(`${API_URL}/api/v1/user/announcements/${status}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error(`Error fetching favorites: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error in getFavoriteStatus:", error);
        throw error;
    }
}

export {getUserAdd};