import React from "react";
import '../style/MobileBottomItem.css';

function MobileBottomItem({ iconPath, itemName, center_icon }) {
    return ( 
        <div className='mobile_bottom_item_content'>
            <img 
                className={`${center_icon ? 'center_icon' : ''}`} 
                src={iconPath} 
                alt={itemName} 
            />
            <p className='text_black mobile_bottom_item_text'>{itemName}</p>
        </div>
     );
}

export default MobileBottomItem;