import { t } from 'i18next';
import React from 'react';

function ErrorComponent({ showLogoutModal, setShowLogoutModal, errorMessage }) {
    return (
        <div>
            {showLogoutModal && (
                <div className="logout_modal_overlay">
                    <div className="logout_modal_content" style={{height: 'auto'}}>
                        <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                        <div className="logout_modal_buttons">
                            <button onClick={() => setShowLogoutModal(false)}>{t('close')}</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ErrorComponent;