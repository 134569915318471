import React from "react";
import "../style/Navigation.css";
import Icon from "../../../common/components/Icon";
import { t } from "i18next";

function Navigation({ onClose }) {
    const handleLinkClick = () => {
        if (onClose) onClose();
    };

    return (
        <div className="navigation_content_bar">
            <Icon src="close" className={"click mobile_hide navigation_close"} onClick={onClose} />
            <h1 className="navigation_header_text">{t("navigation")}</h1>
            <ul className="navigation_header_list">
                <li className="click">
                    <a className="link" href="/#category_content" onClick={handleLinkClick}>
                        {t("categories")}
                    </a>
                </li>
                <li className="click">
                    <a className="link" href="/aboutus" onClick={handleLinkClick}>
                        {t("aboutUs")}
                    </a>
                </li>
                <li className="click">
                    <a className="link" href="/aboutus#articles" onClick={handleLinkClick}>
                        {t("news")}
                    </a>
                </li>
                <li className="click">
                    <a className="link" href="/aboutus#contacts" onClick={handleLinkClick}>
                        {t("contacts")}
                    </a>
                </li>
                <li className="click">
                    <a className="link" href="/aboutus#policy" onClick={handleLinkClick}>
                        {t("privacyPolicy")}
                    </a>
                </li>
                <li className="click">
                    <a className="link" href="/aboutus#terms" onClick={handleLinkClick}>
                        {t("termsOfUse")}
                    </a>
                </li>
                <li className="click">
                    <a className="link" href="/aboutus#faq" onClick={handleLinkClick}>
                        {t("howToBuyAndSell")}
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default Navigation;