import React from "react";

import telegram_icon from '../../../assets/icons/telegram.svg';
import instagram_icon from '../../../assets/icons/instagram.svg';
import tiktok_icon from '../../../assets/icons/tiktok.svg';
import linkedin_icon from '../../../assets/icons/linkedin.svg';

import '../style/Contacts.css';
import { t } from "i18next";

const Contacts = () => {
    return (
        <section className="about_content">
            <div className="content">
                <h1 className="m_0">{t('contacts')}</h1>
            </div>
            <iframe
                className="map_content"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2908.5527930148305!2d76.87614207629325!3d43.19789358175937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3883688f3a500001%3A0xc53ec9cc4f5c14ca!2sApartment%20Orbita-4%20-11!5e0!3m2!1sen!2skz!4v1743144169179!5m2!1sen!2skz"
                width="100%"
                height="400"
                borderRadius="10px"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Agroland ақпараттық беті"
            ></iframe>
            <div className="content">
                <div className="contact-details">
                    <h3>{t('our_contacts')}</h3>
                    <hr className="divider"></hr>
                    <div className="contact-social-content">
                        <div className="contact-details">
                            <ul>
                                <li>
                                    <strong>{t('phone')}:</strong> +7 (701) 766-34-43
                                </li>
                                <li>
                                    <strong>{t('mail')}:</strong> support@agroland.kz
                                </li>
                                <li>
                                    <strong>{t('address')}:</strong> {t('address_loc')}
                                </li>
                            </ul>
                        </div>
                        <div className="social-links">
                            <div>
                                <h3>{t('social_networks')}</h3>
                                <ul className="fooder_list navigation_fooder_list">
                                    <li><img src={telegram_icon} alt="telegram" /></li>
                                    <li><img src={instagram_icon} alt="instagram" /></li>
                                    <li><img src={tiktok_icon} alt="tiktok" /></li>
                                    <li><img src={linkedin_icon} alt="linkedin" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contacts;