import React from "react";
import '../style/DownloadItem.css';
import { t } from "i18next";

function DownloadItem({ iconPath, itemText, downloadLink }) {
    return (
        <a href={downloadLink} target="_blank">
            <div className="click download_item_content">
                <img src={iconPath} alt={iconPath} />
                <div className="download_item_text_content">
                    <p className="margin0 download_item_text">{t('download')}</p>
                    <h5 className="margin0">{itemText}</h5>
                </div>
            </div>
        </a>
    );
}

export default DownloadItem;