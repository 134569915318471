import { API_URL } from "../../config/connect";

export async function postUserLocation(locationData) {
  const endpoint = `${API_URL}/api/v1/user/location`;

  try {
    const accessToken = localStorage.getItem("access_token");

    if (!accessToken) {
      throw new Error("Access token is missing");
    }

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(locationData)
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Failed to post location:', error);
    throw error;
  }
}