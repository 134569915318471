import React, { useState, useEffect } from "react";
import "../style/BuyPackage.css";

import Icon from "../../../common/components/Icon";

import { getUserInfo } from "../../../api/usercall";
import infoIcon from '../../../assets/icons/info.svg';
import doneIcon from '../../../assets/icons/done.svg';

import { t } from "i18next";

function BuyPackage({ setActiveTab }) {
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const data = await getUserInfo();
                setUserInfo(data.user);
            } catch (err) {
            } finally {
            }
        };

        fetchUserInfo();
    });

    const handleBackClick = () => {
        setActiveTab(null);
    };

    return (
        <div className="profile_render_container">
            <Icon src="backicon" onClick={handleBackClick} className={"back_icon_mobile"} />
            <h2 className="menu_right_section_header">Купить пакет</h2>
            <div className="menu_right_section_package">
                <div className="content">
                    <p>{t('yourBalance')}: <span className="balance">{userInfo?.balance} {t('units')}</span></p>
                    <div className="balance_card_second_text">
                        <img src={infoIcon} alt='info' />
                        <p>{t('rule_unit')}</p>
                    </div>
                </div>

                <div className="packages">
                    {[
                        { title: 'Пакет 1', duration: 'От 31 до 220 дней', units: 500, price: '500 тг.', active: true },
                        { title: 'Пакет 2', duration: 'От 2 месяцев до 1 года', units: 1000, price: '1 000 тг.', active: false },
                        { title: 'Пакет 3', duration: 'От 3 месяцев до 1,5 года', units: 1500, price: '1 500 тг.', active: false, error: true },
                    ].map((pkg, index) => (
                        <div key={index} className={`content_background package_card ${pkg.active ? 'active' : ''}`}>
                            <div>
                                <h3>{pkg.title}</h3>
                                <div className="package_card_info">
                                    <img src={doneIcon} alt='done' />
                                    <p>{pkg.duration}</p>
                                </div>
                                <div className="package_card_info">
                                    <img src={doneIcon} alt='done' />
                                    <p>{t('units')}: {pkg.units}</p>
                                </div>
                                <div className="package_card_info">
                                    <img src={doneIcon} alt='done' />
                                    <p>Стоимость: {pkg.price}</p>
                                </div>
                            </div>
                            <div>
                                {pkg.error &&
                                    <div className="card_second_text_error">
                                        <img src={infoIcon} alt='info' style={{filter: 'var(--red-filter)'}}/>
                                        <p className="error-text">На вашем счете недостаточно {t('units')}, необходимо <a href="/profile#walletTopUp">пополнить баланс</a></p>
                                    </div>
                                }
                                <button
                                    disabled={pkg.error}
                                    className={`promo_button ${pkg.error ? 'error' : ''} ${pkg.active ? 'active' : ''}`}
                                >
                                    {pkg.active ? 'Активирован' : 'Активировать'}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default BuyPackage;