import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

import TextInput from "../../elements/TextInput/TextInput";
import ImageSelector from "../../elements/ImageSelector/ui/ImageSelector";
import OpenModalButton from "../../elements/OpenModalButton/ui/OpenModalButton";
import ToggleSwitch from "../../elements/ToggleSwitch/ToggleSwitch";
import TagInput from "../../elements/TagInput/TagInput";

import AddressModal from '../../modals/location_modal/ui/Main';

import Icon from "../../../common/components/Icon";
import Loading from "../../../common/components/Loading";

import { getUserInfo } from "../../../api/usercall";
import { deleteUserLocationById } from "../../../api/post/deleteUserLocation";
import { postAnnouncement } from "../../../api/createannouncement";

import addIcon from '../../../assets/icons/add.svg';
import deleteIcon from '../../../assets/icons/delete.svg';
import marker_icon from '../../../assets/icons/marker.svg';

function EditForm({ announcementId, announcementData }) {
    const [priceType, setPriceType] = useState("fixed");
    const [announcementName, setAnnouncementName] = useState(announcementData?.title);
    const [videoLink, setVideoLink] = useState('');
    const [description, setDescription] = useState(announcementData.description);
    const [isBarter, setIsBarter] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [price, setPrice] = useState(announcementData?.price);
    const [images, setImages] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [userData, setUserData] = useState(null);
    const [UserLocations, setUserLocations] = useState(null);
    const [tagsArray, setTagsArray] = useState([]);
    const [customTags, setCustomTags] = useState('');
    const [additionalPhones, setAdditionalPhones] = useState([]);
    const [phoneErrors, setPhoneErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [menuVisibility, setMenuVisibility] = useState({
        menu: false,
        user: false,
        address: false,
        search: false,
        language: false,
    });

    const toggleMenu = useCallback(
        (menuName) => {
            setMenuVisibility((prev) => ({
                ...prev,
                [menuName]: !prev[menuName],
            }));
        },
        [setMenuVisibility]
    );

    const navigate = useNavigate();

    const fetchUserData = useCallback(async () => {
        try {
            const response = await getUserInfo();
            setUserData(response.user);
            setUserLocations(response.locations);
        } catch (error) {
            console.error(t('failed_to_fetch_user_data'), error);
        }
    }, []);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    const handleAnnouncementInputChange = (value) => setAnnouncementName(value);
    const handleVideoLinkChange = (value) => setVideoLink(value);
    const handleDescriptionChange = (value) => setDescription(value);
    const handleSelectCategory = ({ categoryId, subcategoryId }) => {
        setSelectedCategory(categoryId.id);
        setSelectedSubcategory(subcategoryId.id);
    };
    const handleBarterToggle = (toggled) => setIsBarter(toggled);
    const handleImagesChange = (imageFiles) => setImages(imageFiles);

    const handleSelectLocation = (location) => {
        setSelectedLocation(location);
    };

    const handleCustomTagsChange = (e) => {
        const value = e.target.value;
        setCustomTags(value);
    };

    const handleAddPhone = () => {
        setAdditionalPhones([...additionalPhones, '']);
        setPhoneErrors([...phoneErrors, false]);
    };

    const handlePhoneChange = (index, value) => {
        const updatedPhones = [...additionalPhones];
        updatedPhones[index] = value;
        setAdditionalPhones(updatedPhones);

        const updatedErrors = [...phoneErrors];
        updatedErrors[index] = false;
        setPhoneErrors(updatedErrors);
    };

    const handlePublish = async () => {
        setIsSubmitted(true);
        setIsLoading(true);

        if (!announcementName || !description) {
            console.error(t('required_fields_missing'));
            setIsLoading(false);
            return;
        }

        if (!selectedSubcategory || !selectedLocation) {
            console.error(t('category_or_location_missing'));
            setIsLoading(false);
            return;
        }

        const phoneErrors = additionalPhones.map(phone => !phone.trim());
        setPhoneErrors(phoneErrors);

        if (phoneErrors.some(error => error)) {
            console.error(t('empty_phone_numbers'));
            setIsLoading(false);
            return;
        }

        const formData = new FormData();

        formData.append("title", announcementName || "");
        formData.append("video_url", videoLink || "");
        formData.append("description", description || "");
        formData.append("price_type", priceType || "");
        formData.append("price", priceType === "fixed" ? price : "");
        formData.append("isBarter", isBarter ? "true" : "false");

        if (selectedCategory) formData.append("category_id", selectedCategory);
        if (selectedSubcategory) formData.append("subcategory_id", selectedSubcategory);
        if (selectedLocation) formData.append("user_location_id", selectedLocation);

        formData.append("contactName", userData.name || "");
        formData.append("contactPhone", userData.phone_number || "");

        additionalPhones.forEach((phone, index) => {
            formData.append(`additionalPhones[${index}]`, phone);
        });

        tagsArray.forEach(tag => {
            formData.append("tags[]", tag.trim());
        });

        images.forEach((file) => {
            formData.append("images", file);
        });

        try {
            const accessToken = localStorage.getItem("access_token");
            const response = await postAnnouncement(formData, accessToken);

            if (response?.announcement_id) {
                navigate(`/announcement/${response.announcement_id}`);
            } else {
                console.error(t('announcement_id_missing'));
            }
        } catch (error) {
            console.error(t('error_creating_announcement'), error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddTag = () => {
        if (customTags.trim() === '') return;
        setTagsArray([...tagsArray, customTags.trim()]);
        setCustomTags('');
    };

    const handleRemoveTag = (index) => {
        setTagsArray(tagsArray.filter((_, i) => i !== index));
    };

    const handleDeleteAddress = async (addressId) => {
        try {
            const confirmation = window.confirm(t('confirm_delete_address'));
            if (!confirmation) return;

            await deleteUserLocationById(addressId);

            setUserLocations((prevLocations) =>
                prevLocations.filter((location) => location.address_id !== addressId)
            );

            if (selectedLocation === addressId) {
                setSelectedLocation(null);
            }

            console.log(t('address_deleted'));
        } catch (error) {
            console.error(t('error_deleting_address'), error);
            alert(t('delete_address_error'));
        }
    };

    const handleDeletePhone = (index) => {
        const updatedPhones = additionalPhones.filter((_, i) => i !== index);
        setAdditionalPhones(updatedPhones);

        const updatedErrors = phoneErrors.filter((_, i) => i !== index);
        setPhoneErrors(updatedErrors);
    };

    const handlePreview = () => {
        const previewData = {
            title: announcementName,
            description,
            price,
            priceType,
            images,
            videoLink,
        };

        navigate('/preview', { state: { previewData } });
    };

    return (
        <div className="ad_form_container">
            <div className="main_form">
                <h2 className="form_heading">{t('announcement')}</h2>
                <div className="title_section">
                    <ImageSelector onImagesChange={handleImagesChange} />
                    <hr className="divider" />
                    <div className="video_link_section">
                        <TextInput isRequired={false} onInputChange={handleVideoLinkChange} labelText={t('video_link')} />
                    </div>
                </div>

                <div className="title_section">
                    <TextInput isRequired={true} onInputChange={handleAnnouncementInputChange} isSubmitted={isSubmitted} errorText={t('required')} labelText={t('announcement_title')} />
                </div>
                <div className="title_section">
                    {isSubmitted && tagsArray.length === 0 && (
                        <span className="error_text">{t('required')}</span>
                    )}
                    <TagInput
                        value={customTags}
                        onChange={handleCustomTagsChange}
                        onAddTag={handleAddTag}
                        onRemoveTag={handleRemoveTag}
                        tags={tagsArray}
                        isSubmitted={isSubmitted}
                    />
                </div>
                <div className="title_section">
                    <label className="input_label">{t('select_category')}</label>
                    <OpenModalButton type="category" onSelect={handleSelectCategory} />
                </div>
                <div className="title_section">
                    <TextInput isRequired={true} onInputChange={handleDescriptionChange} isSubmitted={isSubmitted} errorText={t('required')} labelText={t('description')} value={description}/>
                </div>

                <div className="title_section">
                    <label className="input_label">{t('price')}</label>
                    <div className="price_inputs">
                        <button className={priceType === "fixed" ? "price_button active" : "price_button"} onClick={() => setPriceType("fixed")}>
                            {t('fixed_price')}
                        </button>
                        <button className={priceType === "negotiable" ? "price_button active" : "price_button"} onClick={() => setPriceType("negotiable")}>
                            {t('negotiable')}
                        </button>
                    </div>
                    {priceType === "fixed" && (
                        <input
                            type="number"
                            className="input_field"
                            placeholder={t('enter_price')}
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                    )}
                    <hr className="divider" />
                    <div className="toggle-row">
                        <label className="toggle-label">{t('barter')}</label>
                        <ToggleSwitch onToggle={handleBarterToggle} />
                    </div>
                </div>

                <div className="title_section publish_section mobile_hide">
                    <button
                        className="ad_form_buttons publish_button"
                        onClick={handlePublish}
                        disabled={isLoading}
                    >
                        {isLoading ? <Loading /> : t('publish')}
                    </button>
                    <button
                        className="ad_form_buttons review_button"
                        disabled={isLoading}
                        onClick={handlePreview}
                    >
                        {t('preview')}
                    </button>
                </div>
            </div>

            <div className="contact_info">
                <h4 className="section_heading">{t('your_data')}</h4>

                <div className="contact_section">
                    <label className="input_label">{t('contact_name')}</label>
                    <input type="text" className="input_field" contentEditable={false} value={userData?.name || t('default_value')} disabled />
                </div>

                <div className="phone_section">
                    <label className="input_label">{t('phone')}</label>
                    <input
                        type="tel"
                        className="input_field"
                        contentEditable={false}
                        value={userData?.phone_number || t('default_value')}
                        disabled
                    />
                    {additionalPhones.map((phone, index) => (
                        <div key={index} className="address_item">
                            <input
                                type="tel"
                                className={`input_field ${phoneErrors[index] ? 'error' : ''}`}
                                placeholder={t('enter_additional_phone')}
                                value={phone}
                                onChange={(e) => handlePhoneChange(index, e.target.value)}
                            />
                            <img src={deleteIcon} alt={t('delete')} className="click" onClick={() => handleDeletePhone(index)} />
                            {phoneErrors[index] && (
                                <span className="error_text">{t('enter_phone')}</span>
                            )}
                        </div>
                    ))}
                    <button className="add_phone_button click" onClick={handleAddPhone}>
                        <img src={addIcon} alt={t('add')} /> <span className="tel_add_button_text">{t('add_more')}</span>
                    </button>
                </div>
                <hr className="divider" />
                <div className="address_section">
                    <label className="input_label">{t('your_address')}</label>
                    {UserLocations?.map((location, index) => (
                        <React.Fragment key={index}>
                            <div
                                className={`address_item click ${selectedLocation === location.address_id ? 'selected' : ''}`}
                                onClick={() => handleSelectLocation(location.address_id)}
                            >
                                <Icon src="marker" />
                                <div className="address_item_text">
                                    <p className="address_item_text_1">{location.locality || ''}, {location.province || ''}</p>
                                    <p className="address_item_text_2">{location.street || ''}</p>
                                </div>
                                <img src={deleteIcon} alt={t('delete')} className="click" onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteAddress(location.address_id);
                                }} />
                            </div>
                            <hr className="divider" />
                        </React.Fragment>
                    ))}
                </div>

                <div className="click mobile_none header_location" onClick={() => toggleMenu('address')}>
                    <img src={marker_icon} alt="marker_icon" />
                    <span className="header_crop_text">{t('add_address')}</span>
                </div>
            </div>
            <div className="title_section mobile_show">
                <button
                    className="ad_form_buttons publish_button"
                    onClick={handlePublish}
                    disabled={isLoading}
                >
                    {isLoading ? <Loading /> : t('publish')}
                </button>
                <button
                    className="ad_form_buttons review_button"
                    disabled={isLoading}
                    onClick={handlePreview}
                >
                    {t('preview')}
                </button>
            </div>
            <AddressModal menuVisible={menuVisibility.address} toggleMenu={() => toggleMenu('address')} onSubmitted={() => fetchUserData()} />
        </div>
    );
}

export default EditForm;