import { API_URL } from "../../config/connect";

async function getNotifications({ service }, page = 1, limit = 10) {
    try {
        const response = await fetch(`${API_URL}/api/v1/notifications/${service}?page=${page}&limit=${limit}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error(`Error fetching notifications: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error in getNotifications:", error);
        return [];
    }
}

export { getNotifications };