import React, { useState, useEffect } from 'react';
import '../style/PromoteContent.css';
import '../style/PaymentModal.css';

import phoneIcon from '../../../assets/icons/phone.svg';
import eyeIcon from '../../../assets/icons/eye.svg';
import doneIcon from '../../../assets/icons/done.svg';
import chatIcon from '../../../assets/icons/chat.svg';
import heartIcon from '../../../assets/icons/heart.svg';
import infoIcon from '../../../assets/icons/info.svg';

import { getUserInfo } from '../../../api/usercall';
import { postPromote } from '../../../api/postPromote';

import { t } from 'i18next';

const PromoteContent = ({ data }) => {
    const [userBalance, setUserBalance] = useState(0);
    const [activatedPackages, setActivatedPackages] = useState({});
    const [activatedServices, setActivatedServices] = useState({});

    useEffect(() => {
        const fetchUserBalance = async () => {
            try {
                const response = await getUserInfo();
                setUserBalance(response.user.balance);
            } catch (error) {
                console.error("Failed to fetch user balance:", error);
            }
        };
        fetchUserBalance();
    }, []);

    const handleActivateClick = async (id, item, type) => {
        if (userBalance < item.units) {
            alert("Недостаточно {t('units')} на балансе!");
            return;
        }

        if ((type === "package" && activatedPackages[item.title]) ||
            (type === "service" && activatedServices[item.name])) {
            alert("Этот тариф/услуга уже активирован!");
            return;
        }

        try {
            const promotionPackageIndex = item.promotionType;
            const response = await postPromote(id, promotionPackageIndex);

            if (response.promotion_id) {
                if (type === "package") {
                    setActivatedPackages((prev) => ({ ...prev, [item.title]: true }));
                } else {
                    setActivatedServices((prev) => ({ ...prev, [item.name]: true }));
                }
                setUserBalance((prevBalance) => prevBalance - item.units);

                alert("Тариф успешно активирован!");
            } else {
                alert("Не удалось активировать тариф. Попробуйте снова.");
            }
        } catch (error) {
            console.error("Ошибка при активации:", error);
            alert("Произошла ошибка. Попробуйте позже.");
        }
    };

    return (
        <div className="promo_container">
            <div className="promo_content_left_section content_background">
                <div className="item-card">
                    <div className="promo_item_details">
                        <div className="promo_item_details_content">
                            <img
                                src={data.main_image_url}
                                alt="Item"
                                className="promo_item_details_content_image"
                            />
                            <div>
                                <h3 className="promo_item_details_content_title">{data.title}</h3>
                                <p className="promo_item_details_content_price">{data.price} тг.</p>
                            </div>
                        </div>
                        <div className="profile_advertisements_info">
                            <div className="info_block">
                                <img src={phoneIcon} alt="Phone" />
                                <span>{2}</span>
                            </div>
                            <div className="info_block">
                                <img src={eyeIcon} alt="Views" />
                                <span>{12}</span>
                            </div>
                            <div className="info_block">
                                <img src={heartIcon} alt="Likes" />
                                <span>{0}</span>
                            </div>
                            <div className="info_block">
                                <img src={chatIcon} alt="Comments" />
                                <span>{0}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="promo_content_right_section">
                <div className="balance_card content_background">
                    <p>{t('yourBalance')}: <strong className="balance">{userBalance} {t('units')}</strong></p>
                    <div className="balance_card_second_text">
                        <img src={infoIcon} alt="info" />
                        <p>Повысьте видимость объявлений с помощью рекламы. Выберите тариф или услугу.</p>
                    </div>
                </div>

                {/* <div className="packages-section">
                    <h2 className="content_background">Тарифы</h2>
                    <div className="packages">
                        {[
                            { title: 'Пакет 1', duration: 'От 31 до 220 дней', units: 500, price: '500 тг.' },
                            { title: 'Пакет 2', duration: 'От 2 месяцев до 1 года', units: 1000, price: '1 000 тг.' },
                            { title: 'Пакет 3', duration: 'От 3 месяцев до 1,5 года', units: 1500, price: '1 500 тг.' },
                        ].map((pkg, index) => (
                            <div key={index} className={`content_background package_card ${activatedPackages[pkg.title] ? 'active' : ''}`}>
                                <div>
                                    <h3>{pkg.title}</h3>
                                    <div className="package_card_info">
                                        <img src={doneIcon} alt="done" />
                                        <p>{pkg.duration}</p>
                                    </div>
                                    <div className="package_card_info">
                                        <img src={doneIcon} alt="done" />
                                        <p>{t('units')}: {pkg.units}</p>
                                    </div>
                                    <div className="package_card_info">
                                        <img src={doneIcon} alt="done" />
                                        <p>Стоимость: {pkg.price}</p>
                                    </div>
                                </div>
                                <button
                                    disabled={userBalance < pkg.units}
                                    className={`promo_button ${userBalance < pkg.units ? 'error' : ''} ${activatedPackages[pkg.title] ? 'active' : ''}`}
                                    onClick={() => handleActivateClick(pkg, "package")}
                                >
                                    {activatedPackages[pkg.title] ? 'Активирован' : 'Активировать'}
                                </button>
                            </div>
                        ))}
                    </div>
                </div> */}

                <div className="services-section">
                    <h2 className="content_background">Платные услуги</h2>
                    <div className="services">
                        {[
                            { name: 'Минимальный (Стартовый)', units: 500, price: '100 тг.', promotionType: 0, text1: '1000 просмотров', text2: '500 тенге (0,50 тг за просмотр)', text3: 'Подходит для теста рекламы или единичных продаж' },
                            { name: 'Оптимальный (Популярный)', units: 1300, price: '1 000 тг.', promotionType: 1, text1: '3000 просмотров', text2: '1300 тенге (0,43 тг за просмотр, скидка 15%)', text3: 'Идеален для небольших бизнесов и регулярных объявлений' },
                            { name: 'Максимальный (Бизнес)', units: 2800, price: '1 500 тг.', promotionType: 2, text1: '7000 просмотров', text2: '2800 тенге (0,40 тг за просмотр, скидка 20%)', text3: 'Подходит для тех, кто хочет максимальную видимость'},
                        ].map((service, index) => (
                            <div key={index} className={`content_background service_card ${activatedServices[service.name] ? 'active' : ''}`}>
                                <div>
                                    <h3>{service.name}</h3>
                                    <div className="package_card_info">
                                        <img src={doneIcon} alt="done" />
                                        <p>{service.text1}</p>
                                    </div>
                                    <div className="package_card_info">
                                        <img src={doneIcon} alt="done" />
                                        <p> {service.text2}</p>
                                    </div>
                                    <div className="package_card_info">
                                        <img src={doneIcon} alt="done" />
                                        <p> {service.text3}</p>
                                    </div>
                                </div>
                                <button
                                    disabled={userBalance < service.units}
                                    className={`promo_button ${userBalance < service.units ? 'error' : ''} ${activatedServices[service.name] ? 'active' : ''}`}
                                    onClick={() => handleActivateClick(data.id, service, "package")}
                                >
                                    {activatedServices[service.name] ? 'Активирован' : 'Активировать'}
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromoteContent;