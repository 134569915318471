import React, { useState } from 'react';
import search_icon from '../../../assets/icons/search.svg';
import '../style/CompanyContent.css';
import VipAdvertItem from '../../main/items/vip_advert_item';
import { t } from 'i18next';

function CompanyContent({ activeTab, announcements_by_category }) {
    const [activeSection, setActiveSection] = useState('main');

    const handleSectionChange = (section) => {
        setActiveSection(section);
    };

    return (
        <div className="company_content">
            {activeTab === 'ads' && (
                <div className="company_ads_content">
                    <aside className="sidebar">
                        <nav>
                            <ul>
                                <li className="nav-title">{t('categories')}</li>
                                <li>
                                    <p
                                        onClick={() => handleSectionChange('main')}
                                        className={`click nav-link ${activeSection === 'main' ? 'active' : ''}`}
                                    >
                                        {activeSection === 'main' && <div className="active_tab_left"></div>}
                                        {t('all_ads')} <span className="arrow">›</span>
                                    </p>
                                </li>
                                {(Array.isArray(announcements_by_category) ? announcements_by_category : []).length > 0 ? (
                                    (announcements_by_category || []).map(category => (
                                        <li key={category.category_id}>
                                            <p
                                                onClick={() => handleSectionChange(category.category_id)}
                                                className={`click nav-link ${activeSection === category.category_id ? 'active' : ''}`}
                                            >
                                                {activeSection === category.category_id && <div className="active_tab_left"></div>}
                                                {category.category_name} <span className="arrow">›</span>
                                            </p>
                                        </li>
                                    ))
                                ) : (
                                    <li className="no-categories-message">Нет категорий</li>
                                )}
                            </ul>
                        </nav>
                    </aside>
                    <div className="company_ads_content_result">
                        <div className="input_container">
                            <img className="search_icon_input" src={search_icon} alt="Поиск" />
                            <input className="second_section_search" placeholder={t('searchPlaceholder')} />
                        </div>
                        <div className="result_grid" style={{marginTop: 20}}>
                            {(!announcements_by_category || announcements_by_category.length === 0) ? (
                                <p className="no-ads-message">Нет объявлений</p>
                            ) : activeSection === 'main' ? (
                                announcements_by_category.flatMap(category =>
                                    category.announcements?.length > 0 ? (
                                        category.announcements.map(ad => <VipAdvertItem key={ad.id} id={ad.id} price={ad.price} date={ad.created_at} name={ad.title} imagePath={ad.main_image_url} />)
                                    ) : []
                                )
                            ) : (
                                (() => {
                                    const activeCategory = announcements_by_category.find(category => category.category_id === activeSection);
                                    return activeCategory?.announcements?.length > 0 ? (
                                        activeCategory.announcements.map(ad => <VipAdvertItem key={ad.id} id={ad.id} price={ad.price} date={ad.created_at} name={ad.title} imagePath={ad.main_image_url} />)
                                    ) : (
                                        <p className="no-ads-message">Нет объявлений в этой категории</p>
                                    );
                                })()
                            )}
                        </div>
                    </div>
                </div>
            )}
            {activeTab === 'about' && (
                <div className="company-about">
                    <div className="section about">
                        <h2 className="section-title">О компании</h2>
                        <p className="section-text">
                            С другой стороны, глубокий уровень погружения однозначно фиксирует необходимость новых предложений.
                            Не следует, однако, забывать, что убеждённость некоторых оппонентов обеспечивает широкому кругу
                            (специалистов) участие в формировании экспериментов, поражающих по своей масштабности и грандиозности!
                            Как уже неоднократно упомянуто, стремящиеся вытеснить традиционное производство, нанотехнологии и по
                            сей день остаются уделом либералов, которые жаждут быть разоблачены. Картельные сговоры не допускают
                            ситуации, при которой базовые сценарии поведения пользователей формируют глобальную экономическую
                            сеть и при этом — представлены в исключительно положительном свете.
                        </p>
                    </div>
                    <div className="section personal-data">
                        <h2 className="section-title">Личные данные</h2>
                        <div className="data-grid">
                            <div className="data-item">
                                <label className="data-label">Петров Алексей</label>
                            </div>
                            <div className="data-item">
                                <label className="data-label">Должность (Директор)</label>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {activeTab === 'contacts' && (
                <div className="company-contacts">
                    <div className="section contacts">
                        <h2 className="section-title">Контакты</h2>
                        <div className="contacts-content">
                            <div className="address-section">
                                <div className="address-info">
                                    <span className="address-icon">📍</span>
                                    <div>
                                        <p className="address-title">Алматы, Медеуский район</p>
                                        <p className="address-subtitle">Алматинская область</p>
                                    </div>
                                </div>
                                <button className="map-button">
                                    <span className="map-icon">🗺️</span>
                                </button>
                            </div>
                            <div className="phone-section">
                                <p>+7 987 654-32-10</p>
                                <p>+7 987 654-32-10</p>
                                <p>+7 987 654-32-10</p>
                                <p>+7 987 654-32-10</p>
                            </div>
                            <div className="links-section">
                                <a href="https://www.companyname.com" target="_blank" rel="noopener noreferrer" className="link-item">
                                    https://www.Companyname.com <span className="link-icon">🌐</span>
                                </a>
                                <a href="https://t.me/companyname" target="_blank" rel="noopener noreferrer" className="link-item">
                                    @Companyname <span className="link-icon">📩</span>
                                </a>
                                <a href="tel:+79876543210" className="link-item">
                                    +7 987 654-32-10 <span className="link-icon">📞</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CompanyContent;