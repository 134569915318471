import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '../../main/components/Header';
import Fooder from '../../main/components/Fooder';
import MobileBottomBar from '../../main/components/MobileBottomBar';
import Path from '../../path/ui/Path';

import { I18nextProvider } from 'react-i18next';
import i18n from '../../../language/i18n';
import PromoteContent from '../components/PromoteContent';

function Main() {
    const [bottomBarHidden, setBottomBarHidden] = useState(false);
    const location = useLocation(); // Retrieve the state from the navigation
    const adData = location.state?.ad || {}; // Get the advertisement data or set a default

    const handleBottomBarToggle = (isHidden) => {
        setBottomBarHidden(isHidden);
    };

    return (
        <I18nextProvider i18n={i18n}>
            <Header onToggleMenu={handleBottomBarToggle} />
            <Path currentPath={`Продвигать`} />
            <PromoteContent data={adData} />
            <Fooder />
            {!bottomBarHidden && <MobileBottomBar activeTabName={'home'} />}
        </I18nextProvider>
    );
}

export default Main;