import React, { useRef, useState, useEffect } from "react";
import "../style/AddressModal.css";

import ymaps from "ymaps";

import { postUserLocation } from "../../../../api/post/postUserLocation";
import Icon from "../../../../common/components/Icon";
import { MAP_API_URL } from "../../../../config/connect";

import closeIcon from '../../../../assets/icons/close.svg';

import { t } from "i18next";

function AddressModal({ onClose, onSubmitted, mode = "add" }) {
    const mapContainerRef = useRef(null);
    const mapsInstanceRef = useRef(null);
    const mapInitializedRef = useRef(false);
    const [marker, setMarker] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [addressData, setAddressData] = useState(null);
    const [resultMessage, setResultMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const debounceTimerRef = useRef(null);

    const initializeMap = () => {
        if (mapInitializedRef.current) return;
        mapInitializedRef.current = true;

        navigator.geolocation.getCurrentPosition(
            (position) => {
                const userCoords = [position.coords.latitude, position.coords.longitude];

                ymaps
                    .load(MAP_API_URL)
                    .then((maps) => {
                        mapsInstanceRef.current = maps;

                        const mapInstance = new maps.Map(mapContainerRef.current, {
                            center: userCoords,
                            zoom: 15,
                            controls: ["geolocationControl", "fullscreenControl", "trafficControl"],
                        });

                        mapsInstanceRef.current.map = mapInstance;

                        const handleMapClick = (e) => {
                            if (marker) return;

                            const coords = e.get("coords");
                            if (maps.Placemark) {
                                const newMarker = new maps.Placemark(coords);
                                mapInstance.geoObjects.add(newMarker);
                                setMarker(newMarker);
                                mapInstance.events.remove("click", handleMapClick);

                                maps.geocode(coords).then((res) => {
                                    const firstGeoObject = res.geoObjects.get(0);
                                    const selectedAddress = {
                                        house: firstGeoObject.getThoroughfare() || "",
                                        street: firstGeoObject.getAddressLine() || "",
                                        country: firstGeoObject.getCountry() || "",
                                        area: firstGeoObject.getAdministrativeAreas()[0] || "",
                                        province: firstGeoObject.getLocalities()[0] || "",
                                        locality: firstGeoObject.getLocalities()[1] || "",
                                        latitude: coords[0],
                                        longitude: coords[1],
                                    };
                                    setAddressData(selectedAddress);
                                    setResultMessage(`${selectedAddress.street}`);
                                });
                            }
                        };

                        mapInstance.events.add("click", handleMapClick);
                        mapsInstanceRef.current.handleMapClick = handleMapClick;
                    })
                    .catch((error) => console.log("Failed to load Yandex Maps", error));
            },
            (error) => {
                console.error("Failed to retrieve location", error);
            }
        );
    };

    const handleReset = () => {
        if (marker && mapsInstanceRef.current.map) {
            mapsInstanceRef.current.map.geoObjects.remove(marker);
            setMarker(null);
            setAddressData(null);
            setResultMessage("");

            mapsInstanceRef.current.map.events.add("click", mapsInstanceRef.current.handleMapClick);
        }
    };

    const handleSearch = () => {
        if (!searchQuery || !mapsInstanceRef.current) {
            // setResultMessage("Введите адрес для поиска.");
            return;
        }

        setLoading(true);
        setResultMessage("");

        mapsInstanceRef.current.geocode(searchQuery).then((res) => {
            setLoading(false);

            const firstGeoObject = res.geoObjects.get(0);
            if (firstGeoObject) {
                const coords = firstGeoObject.geometry.getCoordinates();
                mapsInstanceRef.current.map.setCenter(coords, 15);

                if (marker) {
                    marker.geometry.setCoordinates(coords);
                } else {
                    const newMarker = new mapsInstanceRef.current.Placemark(coords);
                    mapsInstanceRef.current.map.geoObjects.add(newMarker);
                    setMarker(newMarker);
                }

                const selectedAddress = {
                    house: firstGeoObject.getThoroughfare() || "",
                    street: firstGeoObject.getAddressLine() || "",
                    country: firstGeoObject.getCountry() || "",
                    area: firstGeoObject.getAdministrativeAreas()[0] || "",
                    province: firstGeoObject.getLocalities()[0] || "",
                    locality: firstGeoObject.getLocalities()[1] || "",
                    latitude: coords[0],
                    longitude: coords[1],
                };

                setAddressData(selectedAddress);
                setResultMessage(`Найден адрес: ${selectedAddress.street}`);
            } else {
                setResultMessage("Результаты поиска не найдены.");
            }
        }).catch(() => {
            setLoading(false);
            setResultMessage("Ошибка при выполнении поиска.");
        });
    };

    useEffect(() => {
        if (debounceTimerRef.current) clearTimeout(debounceTimerRef.current);

        debounceTimerRef.current = setTimeout(() => {
            handleSearch();
        }, 500);

        return () => clearTimeout(debounceTimerRef.current);
    }, [searchQuery]);

    const handleApply = async () => {
        if (!addressData) {
            console.log("No location selected");
            return;
        }

        try {
            if (mode === "add") {
                await postUserLocation(addressData);
                window.location.reload();
            } else if (mode === "select") {
                onSubmitted(addressData);
            }
            onClose();
        } catch (error) {
            console.error("Failed to handle address data:", error);
        }
    };

    return (
        <div className="location_modal" onLoad={initializeMap}>
            <div className="modal_header">
                <h2>{t('location')}</h2>
                <button className="click mobile_hide navigation_close" onClick={onClose}>
                    <img src={closeIcon} alt="Close" />
                </button>
            </div>

            <div className="search_input_container">
                <div className="input_container">
                    <Icon className={"search_icon_input"} src="search" alt="search" />
                    <input
                        className="second_section_search"
                        placeholder={t('enterAddress')}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
            </div>

            <div className="map_content">
                <div
                    ref={mapContainerRef}
                    id="map"
                    style={{ width: "-webkit-fill-available", height: "300px", borderRadius: 10, marginBottom: 40, maxWidth: 463 }}
                ></div>
                {marker && (
                    <button className="reset_button" onClick={handleReset}>
                        <Icon className={"click"} src="x" alt="x" />
                    </button>
                )}
            </div>

            {resultMessage && <p className="result_message">{resultMessage}</p>}

            <button style={{ width: 463 }} className="submit_button" onClick={handleApply}>
                {mode === "add" ? t('add') : t('apply')}
            </button>
        </div>
    );
}

export default AddressModal;