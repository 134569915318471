import { API_URL } from "../config/connect";

export async function postPromote(id, promotion_package) {
    const endpoint = `${API_URL}/api/v1/announcement/promote/${id}`;

    const promotionOptions = {
        0: "minimal",
        1: "optimal",
        2: "maximal"
    };

    if (!(promotion_package in promotionOptions)) {
        throw new Error("Invalid promotion package");
    }

    try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
            throw new Error("Access token is missing");
        }

        const requestBody = {
            promotion_package: promotionOptions[promotion_package]
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Error: ${response.status} - ${errorData.detail || response.statusText}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Failed to post promotion:', error);
        throw error;
    }
}