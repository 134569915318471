import React, { useState } from "react";
import UserModalItem from "../items/user_modal_item";
import { Link, useNavigate } from "react-router-dom";

import { t } from "i18next";

function ModalList() {
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.clear();
        navigate("/sign");
    };

    return (
        <div>
            <div className="user_modal_list_content">
                <Link to={"/profile#ads"}><UserModalItem className="not_hover" iconPath={"note"} itemName={t('announcements')} /></Link>
                <Link to={"/profile#messages"}><UserModalItem iconPath={"chat"} itemName={t('messages')} /></Link>
                <Link to={"/profile#settings"}><UserModalItem iconPath={"gear"} itemName={t('settings')} /></Link>
                <Link to={"/profile#favorites"}><UserModalItem iconPath={"heart"} itemName={t('favorites')} /></Link>
                <div onClick={() => setShowLogoutModal(true)}>
                    <UserModalItem not_line={"not_line_button"} iconPath={"signout"} itemName={t('logout')} />
                </div>
            </div>
            {showLogoutModal && (
                <div className="logout_modal_overlay">
                    <div className="logout_modal_content">
                        <h2>Выйти из профиля?</h2>
                        <div className="logout_modal_buttons">
                            <button onClick={handleLogout}>Да</button>
                            <button onClick={() => setShowLogoutModal(false)}>Нет</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ModalList;