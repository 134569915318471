import React from "react";
import '../style/Policy.css';

import { t } from "i18next";

const Policy = () => (
    <main className="about_content">
        <div className="content">
            <h1 className="m_0">{t('privacy_policy')}</h1>
        </div>
        <div className="content">
            <section className="policy-section">
                <h2>ОПРЕДЕЛЕНИЯ</h2>
                <ul>
                    <li><strong>Персональные данные</strong> – информация, относящаяся к определенному или определяемому субъекту персональных данных.</li>
                    <li><strong>Сайт (Сервис)</strong> – онлайн-платформа Agroland.kz, доступная по адресу <a href="https://agroland.kz">agroland.kz</a>.</li>
                    <li><strong>Пользователь</strong> – физическое лицо, зарегистрированное на платформе или использующее сервис без регистрации.</li>
                </ul>
            </section>

            <section className="policy-section">
                <h2>1. ОБЛАСТЬ ПРИМЕНЕНИЯ И СОГЛАСИЕ</h2>
                <p>1.1. Персональные данные пользователей Agroland.kz обрабатываются ТОО «Agroland», зарегистрированным в Республике Казахстан.</p>
                <p>1.2. Политика регулирует сбор и обработку персональных данных, включая имя пользователя, email, телефон и регион проживания.</p>
                <p>1.3. Используя сервис, Пользователь соглашается на обработку персональных данных.</p>
            </section>

            <section className="policy-section">
                <h2>2. СБОР ИНФОРМАЦИИ</h2>
                <ul>
                    <li><strong>Учетная запись</strong>: Требуется email, номер телефона и пароль.</li>
                    <li><strong>Объявления</strong>: Пользователь отвечает за публикуемую информацию.</li>
                    <li><strong>Обслуживание клиентов</strong>: В службу поддержки можно передавать дополнительные данные.</li>
                    <li><strong>Техническая информация</strong>: Автоматический сбор данных (IP, устройство, браузер).</li>
                </ul>
            </section>

            <section className="policy-section">
                <h2>3. ИСПОЛЬЗОВАНИЕ ДАННЫХ</h2>
                <p>Agroland.kz использует персональные данные для обеспечения работы сервиса, защиты безопасности, улучшения платформы и анализа статистики.</p>
            </section>

            <section className="policy-section">
                <h2>4. ХРАНЕНИЕ И ЗАЩИТА ДАННЫХ</h2>
                <p>4.1. Данные хранятся не более 24 месяцев с момента последней активности.</p>
                <p>4.2. Используются технические меры защиты от несанкционированного доступа.</p>
            </section>

            <section className="policy-section">
                <h2>5. ОБМЕН ИНФОРМАЦИЕЙ</h2>
                <p>5.1. Agroland.kz не передает данные третьим лицам, кроме случаев, предусмотренных законодательством.</p>
                <p>5.2. Данные могут передаваться партнерам для аналитики и улучшения сервиса.</p>
            </section>

            <section className="policy-section">
                <h2>6. ПРАВА ПОЛЬЗОВАТЕЛЕЙ</h2>
                <ul>
                    <li>Получить информацию о своих данных.</li>
                    <li>Исправить, обновить или удалить данные.</li>
                    <li>Отозвать согласие на обработку.</li>
                </ul>
                <p>Связаться можно через форму обратной связи.</p>
            </section>

            <section className="policy-section">
                <h2>7. ИЗМЕНЕНИЯ В ПОЛИТИКЕ</h2>
                <p>7.1. Политика может быть изменена. Актуальная версия доступна на сайте Agroland.kz.</p>
                <p>7.2. В случае несогласия с изменениями Пользователь должен прекратить использование сервиса.</p>
            </section>
        </div>
    </main>
);

export default Policy;
