import React from 'react';
import FilterModal from '../components/FilterModal';

function Main({ openCategoryModal, openLocationModal, selectedCategory, onClose, onApplyFilters, resetFilters, minPrice, maxPrice, isContract, isBarter }) {
  return (
    <FilterModal
      onApplyFilters={onApplyFilters}
      openCategoryModal={openCategoryModal}
      openLocationModal={openLocationModal}
      selectedCategory={selectedCategory}
      onClose={onClose}
      resetFilters={resetFilters}
      minPrice={minPrice}
      maxPrice={maxPrice}
      isContract={isContract}
      isBarter={isBarter}
    />
  );
}

export default Main;