import { io } from "socket.io-client";
import { API_URL } from "../../../config/connect";

export const initializeSocket = () => {
    const token = localStorage.getItem("access_token");
    const socket = io(API_URL, {
        auth: {
            Authorization: `Bearer ${token}`,
        },
        transports: ["websocket"],
    });

    socket.on("connect", () => {
        console.log("Socket connected");
    });

    socket.on("disconnect", (reason) => {
        console.log("Socket disconnected:", reason);
    });

    socket.on("connect_error", (error) => {
        console.error("Socket connection error:", error);
    });

    return socket;
};