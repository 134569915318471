import { API_URL } from "../config/connect";

export async function getCategories() {
    try {
        const response = await fetch(`${API_URL}/api/v1/categories`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();

        const uniqueCategories = data.filter((category, index, self) =>
            index === self.findIndex((c) => c.name === category.name)
        );

        return uniqueCategories;
    } catch (error) {
        console.error("Error fetching categories:", error);
        throw error;
    }
}