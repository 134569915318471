import React from 'react';

import starIcon from '../../../assets/icons/star.svg';
import userIcon from '../../images/def_user_icon.svg';
import markerIcon from '../../../assets/icons/marker.svg';
import mapIcon from '../../../assets/icons/map.svg';

import { t } from 'i18next';

const Description = ({ title, price, user, locations, location, unit, isBarter, priceType }) => {
    const convertUnit = (unit) => {
        const unitMap = {
            piece: "1 шт",
            kilogram: "1 кг",
            ton: "1 т",
            liter: "1 л"
        };
        return unitMap[unit] || unit;
    };
    return (
        <div className="">
            <div className='content'>
                {/* Title */}
                <h2 className='announcement_title'>{title || 'Без названия'}</h2>

                {/* Price */}
                <div className="announcement_price">
                    <span>
                        {price}
                    </span>
                    <span style={{fontSize: '16px', color: '#939393', fontWeight: '500'}}>
                        <span>{convertUnit(unit)}</span>
                    </span>
                    {
                        isBarter &&
                        <span style={{fontSize: '16px', color: '#939393', fontWeight: '500', marginLeft: '10px'}}>
                            <span>{t('barter')}</span>
                        </span>
                    }
                    {
                        priceType === 'negotiable' &&
                        <span style={{fontSize: '16px', color: '#939393', fontWeight: '500', marginLeft: '10px'}}>
                            <span>{t('negotiable')}</span>
                        </span>
                    }
                </div>
            </div>

            <div className="user_info_content content">
                <h3>Пользователь</h3>
                <div className="user_info_back_border">
                    <div className="user_info_back">
                        <div className="user_info_profile_photo">
                            <img className="user_info_profile_photo_1" src={userIcon} alt="user_icon" />
                            <p className="online_user"></p>
                        </div>
                        <div className="user_info_profile_info">
                            <p className="user_info_profile_info_name">{user?.name || 'Аноним'}</p>
                            <p className="user_info_profile_info_reg_date">
                                На Agroland с {new Date(user?.member_since).toLocaleDateString('ru-RU')}
                            </p>
                            <span className="user_info_profile_info_status">{user?.status || 'Не в сети'}</span>
                        </div>
                    </div>
                    <div className="user_rating">
                        <div className="rating_number">
                            <img src={starIcon} alt='star' />
                            <p className="margin0">{t('rating')}: {user?.rating ? user.rating.toFixed(1) : '4.5'}</p>
                        </div>
                    </div>
                </div>
                <div className="user_position">
                    <h3>Местоположение</h3>
                    <div className="user_info_map_content">
                        <div className="user_info_map_left">
                            <img src={markerIcon} alt='marker' />
                            <div className="user_info_map_left_texts">
                                <p className="user_info_map_left_text1">{locations?.locality || location?.locality || 'Не указано'}</p>
                                <p className="user_info_map_left_text2">{locations?.province || location?.province || 'Не указано'}</p>
                            </div>
                        </div>
                        <img className='map_icon' src={mapIcon} alt='map' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Description;