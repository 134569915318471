import React, { useState } from 'react';
import '../style/FilterModal.css';

import RangeInput from '../../../category/items/RangeInput';
import ToggleSwitch from '../../../category/items/ToggleSwitch';
import Icon from '../../../../common/components/Icon';

import { t } from 'i18next';

function FilterModal({ openCategoryModal, openLocationModal, selectedCategory, onSelect, onClose, onApplyFilters, resetFilters, minPrice, maxPrice, isContract, isBarter }) {
    const [currentMinPrice, setCurrentMinPrice] = useState(minPrice || '');
    const [currentMaxPrice, setCurrentMaxPrice] = useState(maxPrice || '');
    const [currentIsContract, setCurrentIsContract] = useState(isContract || false);
    const [currentIsBarter, setCurrentIsBarter] = useState(isBarter || false);

    const handleResetFilters = () => {
        resetFilters();
        setCurrentMinPrice('');
        setCurrentMaxPrice('');
        setCurrentIsContract(false);
        setCurrentIsBarter(false);
    };

    const handleApplyFilters = () => {
        onApplyFilters({
            minPrice: currentMinPrice,
            maxPrice: currentMaxPrice,
            isVip: currentIsContract,
            barter: currentIsBarter,
        });
        if (onClose) onClose();
    };

    return (
        <div className="filter_section_content_mobile">
            <div className="mobile_white_block filter_section_content_top">
                <div className="filter_section_content_top_text">
                    <Icon src="backicon" alt="back" className="click" onClick={onClose} />
                    <h2>{t('filters')}</h2>
                </div>
                <p className="click" onClick={handleResetFilters}>
                    {t('reset')}
                </p>
            </div>
            <div className="mobile_white_block filter_section_content_card">
                <div className="selection-container">
                    <h3>{t('selectCategory')}</h3>
                    <button
                        className="open_modal_button filter"
                        onClick={openCategoryModal}
                    >
                        {selectedCategory || t('selectCategory')}
                        <Icon src="right_arrow" alt="right" />
                    </button>
                    <hr className="divider" />
                    <h3>{t('location')}</h3>
                    <button
                        className="open_modal_button filter"
                        onClick={openLocationModal}
                    >
                        {t('location')}
                        <Icon src="right_arrow" alt="right" />
                    </button>
                    <hr className="divider" />
                </div>
            </div>
            <div className="mobile_white_block filter_section_content_bottom">
                <h3>{t('price')}</h3>
                <div className="filter_section_content_bottom_inputs">
                    <RangeInput
                        minValue={currentMinPrice}
                        maxValue={currentMaxPrice}
                        onMinChange={setCurrentMinPrice}
                        onMaxChange={setCurrentMaxPrice}
                    />
                </div>
                <ToggleSwitch
                    isContract={currentIsContract}
                    isBarter={currentIsBarter}
                    onContractToggle={(value) => setCurrentIsContract(value)}
                    onBarterToggle={(value) => setCurrentIsBarter(value)}
                />
            </div>
            <button type="button" className="filter_submit submit_button" onClick={handleApplyFilters}>
                {t('apply')}
            </button>
        </div>
    );
}

export default FilterModal;