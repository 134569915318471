import { API_URL } from "../../config/connect";

export async function deleteUserLocationById(locationId) {
    const endpoint = `${API_URL}/api/v1/user/location/${locationId}`;

    try {
        const accessToken = localStorage.getItem("access_token");

        if (!accessToken) {
            throw new Error("Access token is missing");
        }

        const response = await fetch(endpoint, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Failed to delete location:', error);
        throw error;
    }
}