import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/userInfo.css';
import userIcon from '../../images/def_user_icon.svg';
import starIcon from '../../../assets/icons/star.svg';
import markerIcon from '../../../assets/icons/marker.svg';
import arrowIcon from '../../../assets/icons/right_arrow.svg';
import mapIcon from '../../../assets/icons/map.svg';

import { t } from 'i18next';

const UserInfo = ({ user, locations }) => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        if (user?.id) {
            navigate("/company", { state: { user } });
        }
    };

    return (
        <div className="user_info_content">
            <h3>{t('user')}</h3>
            <div className="user_info_back_border">
                <div className="user_info_back">
                    <div className="user_info_profile_photo">
                        <img className="user_info_profile_photo_1" src={userIcon} alt="user_icon" />
                        <p className="online_user"></p>
                    </div>
                    <div className="user_info_profile_info">
                        <p className="user_info_profile_info_name">{user?.name || 'Аноним'}</p>
                        <p className="user_info_profile_info_reg_date">
                            {t("memberSince", { date: user.memberSince })}
                        </p>
                        <span className="user_info_profile_info_status">{user?.status || t('offline')}</span>
                    </div>
                </div>
                <div className="user_rating">
                    <div className="rating_number">
                        <img src={starIcon} alt='star' />
                        <p className="margin0">{t('rating')}: {user?.rating || '4.5'}</p>
                    </div>
                </div>
            </div>
            <div className="user_position">
                <h3>{t('location')}</h3>
                <div className="user_info_map_content">
                    <div className="user_info_map_left">
                        <img src={markerIcon} alt='marker' />
                        <div className="user_info_map_left_texts">
                            <p className="user_info_map_left_text1">{locations?.locality || t('nothingFound')}</p>
                            <p className="user_info_map_left_text2">{locations?.province || t('nothingFound')}</p>
                        </div>
                    </div>
                    <img className='map_icon' src={mapIcon} alt='map' />
                </div>
            </div>
            <div className="announcement_message click" onClick={handleNavigate}>
                <p>{t('userAnnouncements')}</p>
                <img className='arrow' src={arrowIcon} alt='arrow' />
            </div>
        </div>
    );
};

export default UserInfo;