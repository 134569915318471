import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { sendSms, submitSmsCode, loginWithTaskId, saveTokens } from '../../../api/submitSmsCode';
import '../style/SmsCode.css';
import logo from '../../../app_icon.svg';
import Modal from '../../../common/components/MessageModal';

const SmsCode = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { taskId } = location.state || {};
    const { phoneNumber } = location.state || {};
    const { typeAuth } = location.state || {};
    const [code, setCode] = useState(new Array(6).fill(''));
    const [timer, setTimer] = useState(30);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (timer > 0) {
            const countdown = setTimeout(() => setTimer(timer - 1), 1000);
            return () => clearTimeout(countdown);
        }
    }, [timer]);

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            navigate('/profile#walletTopUp');
        } else {
            sendSms(taskId).catch((err) => {
                setError(err.message);
                setShowModal(true);
            });
        }
    }, [navigate, taskId]);

    const handleBackClick = () => {
        navigate('/sign');
    };

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;
        const newCode = [...code];
        newCode[index] = element.value;
        setCode(newCode);

        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !code[index] && e.target.previousSibling) {
            e.target.previousSibling.focus();
        }
    };

    const handleSmsSubmit = async (e) => {
        e.preventDefault();
        const smsCode = code.join('');

        try {
            const task_id_from_response1 = await submitSmsCode(smsCode, taskId, phoneNumber);

            const loginData = await loginWithTaskId(task_id_from_response1, typeAuth);

            saveTokens(loginData.access_token, loginData.refresh_token, loginData.biometric_token);

            navigate('/');
        } catch (error) {
            setError(error.message || 'Ошибка проверки кода.');
            setShowModal(true);
        }
    };

    const handleResendCode = () => {
        sendSms(taskId).catch((err) => {
            setError(err.message || 'Ошибка при отправке SMS.');
            setShowModal(true);
            setTimer(30)
        });
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setError('');
    };

    return (
        <div className="sign_page">
            <div className="left_section"></div>

            <div className="right_section">
                <div className="right_section_header">
                    <button onClick={handleBackClick} className="back_button">
                        <i className="back_icon fa-solid fa-chevron-left"></i>
                        {isMobile ? 'Ввод кода' : 'Назад'}
                    </button>

                    {!isMobile && (
                        <div className="logo_container">
                            <img src={logo} alt="AgroFerma Logo" className="logo" />
                        </div>
                    )}
                </div>
                <form onSubmit={handleSmsSubmit} className="sign_form">
                    {isMobile && (
                        <div className="logo_container">
                            <img src={logo} alt="Logo" className="form_logo" />
                        </div>
                    )}
                    {!isMobile && <h2 className="title">Введите код</h2>}
                    <p className="sms_description">
                        На Ваш телефонный номер выслан СМС код, пожалуйста введите его.
                    </p>
                    <div className="code_form">
                        {code.map((value, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength="1"
                                value={value}
                                onChange={(e) => handleChange(e.target, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                onFocus={(e) => e.target.select()}
                                className="code-input"
                            />
                        ))}
                    </div>
                    <button type="submit" className="submit_button">
                        Далее
                    </button>
                </form>

                <div className="resend_section">
                    <p
                        className="click"
                        onClick={handleResendCode}
                        disabled={timer > 0}
                    >
                        Отправить код повторно
                    </p>
                    <p className="timer">{timer}</p>
                </div>
            </div>

            <Modal show={showModal} message={error} onClose={handleCloseModal} />
        </div>
    );
};

export default SmsCode;