import React, { useState, useEffect } from 'react';
import '../style/Register.css';
import { signup } from '../../../api/reg';
import logo from '../../../app_icon.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import FloatingLabelInput from '../../../common/components/FloatingLabelInput';
import fetchUserCountry from '../../../api/getUserCountry';
import { t } from 'i18next';

const countryMasks = {
    Kazakhstan: '+7 (999) 999 99 99',
    Russia: '+7 (999) 999 99 99',
    China: '+86 (999) 9999 9999',
    default: '+7 (999) 999 99 99',
};

const Register = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const userType = location.state?.userType || 'individual';

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [bin, setBin] = useState('');
    const [isAgreed, setIsAgreed] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [mask, setMask] = useState(countryMasks.default);

    useEffect(() => {
        const fetchCountry = async () => {
            try {
                const country = await fetchUserCountry();
                setMask(countryMasks[country] || countryMasks.default);
            } catch (error) {
                console.error('Error fetching user country:', error);
            }
        };

        fetchCountry();

        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleBackClick = () => {
        navigate('/sign');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!name.trim()) {
            setErrorMessage(t('enter_full_name'));
            return;
        }

        if (!phone.trim()) {
            setErrorMessage(t('enter_phone'));
            return;
        }

        if (userType === 'business' && !bin.trim()) {
            setErrorMessage(t('enter_bin'));
            return;
        }

        try {
            const sanitizedPhoneNumber = phone.replace(/\D/g, '');
            const formattedPhoneNumber = sanitizedPhoneNumber.startsWith('7')
                ? `+${sanitizedPhoneNumber}`
                : `+7${sanitizedPhoneNumber}`;

            const data = await signup(name, formattedPhoneNumber, userType, bin);

            navigate('/sms-code', {
                state: {
                    taskId: data.links[0].href.split('/').pop(),
                    phoneNumber: phone,
                    typeAuth: 'signup',
                },
            });
        } catch (error) {
            console.error('Ошибка:', error.message);
            setErrorMessage(error.message || 'Ошибка сети. Попробуйте позже.');
        }
    };

    return (
        <div className="sign_page">
            <div className="left_section"></div>

            <div className="right_section">
                <div className="right_section_header">
                    <button onClick={handleBackClick} className="back_button">
                        <i className="back_icon fa-solid fa-chevron-left"></i>
                        {isMobile ? t('signUp') : t('back')}
                    </button>

                    {!isMobile &&
                        <div className="logo_container">
                            <img src={logo} alt="AgroFerma Logo" className="logo" />
                        </div>
                    }
                </div>
                <div>
                    {!isMobile && <h2 className="title">{t('signUp')}</h2>}
                    <form onSubmit={handleSubmit} className="register_form">
                        {isMobile &&
                            <div className="logo_container">
                                <img src={logo} alt="Logo" className="form_logo" />
                            </div>
                        }

                        {userType === 'business' && (
                            <FloatingLabelInput
                                label={t('companyId')}
                                value={bin}
                                onChange={(e) => setBin(e.target.value)}
                            />
                        )}
                        <FloatingLabelInput
                            label={t('nameLabel')}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <FloatingLabelInput
                            label={t('enter_phone')}
                            mask={mask}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <div className="consent_section">
                            <input
                                type="checkbox"
                                id="consent"
                                checked={isAgreed}
                                onChange={() => setIsAgreed(!isAgreed)}
                            />
                            <label htmlFor="consent">{t('user_access')}</label>
                        </div>
                        {errorMessage && <div className="error_message">{errorMessage}</div>}
                        <button type="submit" className="submit_button" disabled={!isAgreed}>
                            {t('next')}
                        </button>
                    </form>
                </div>
                <div></div>
            </div>
        </div>
    );
};

export default Register;