import React, { useEffect, useState } from "react";
import '../style/Category.css';
import CategoryItem from "../items/category_item";
import SubCategory from "../../subacategory/ui/Main";
import { getCategories } from '../../../api/getCategories';
import { useTranslation } from "react-i18next";

import Loading from "../../../common/components/Loading";

function Category() {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const handleLanguageChange = (lang) => {
            localStorage.setItem('language', lang);
        };

        i18n.on('languageChanged', handleLanguageChange);
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n]);

    useEffect(() => {
        async function fetchCategories() {
            try {
                const data = await getCategories();
                setCategories(data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching categories:", error);
                setIsLoading(false);
            }
        }
        fetchCategories();

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    const handleCategoryClick = (category) => {
        if (selectedCategory && selectedCategory.id === category.id) {
            setSelectedCategory(null);
        } else {
            setSelectedCategory(category);
        }
    };

    const groupSize = 5;
    const getGroupIndex = (index) => Math.floor(index / groupSize);

    const getCategoryName = (category, language) => {
        switch (language) {
            case 'en':
                return category.eng_name;
            case 'kz':
                return category.kz_name;
            case 'cn':
                return category.ch_name;
            default:
                return category.name;
        }
    };

    return (
        <div className="category_content" id="category_content">
            <h2 className="category_content_header">{t('categories')}</h2>
            <div className="category_items">
                {categories.map((category, index) => {
                    const currentGroupIndex = getGroupIndex(index);
                    const selectedGroupIndex = selectedCategory
                        ? getGroupIndex(categories.findIndex(cat => cat.id === selectedCategory.id))
                        : -1;

                    return (
                        <React.Fragment key={category.id}>
                            <CategoryItem
                                imagePath={category.image_url}
                                categoryText={getCategoryName(category, i18n.language)}
                                onClick={() => handleCategoryClick(category)}
                                selected={selectedCategory && selectedCategory.id === category.id}
                            />
                            {((index % groupSize === groupSize - 1 || index === categories.length - 1) &&
                                selectedCategory &&
                                selectedGroupIndex === currentGroupIndex &&
                                !isMobile) && (
                                    <div className="subcategory_wrapper">
                                        <SubCategory category={selectedCategory} />
                                    </div>
                                )}
                        </React.Fragment>
                    );
                })}
            </div>
            {selectedCategory && isMobile && (
                <div className="subcategory_wrapper">
                    <SubCategory category={selectedCategory} />
                </div>
            )}
        </div>
    );
}

export default Category;