import React from 'react';
import companyImage from '../../../assets/images/company.png';
import '../style/CompanyHeader.css';

function CompanyHeader() {
  return (
    <div className="company_header_content">
        <img src={companyImage} alt='company'/>
    </div>
  )
}

export default CompanyHeader