import React from "react";
import { useTranslation } from "react-i18next";
import '../style/SearchStart.css';
import agro_logo from '../../../images/app_icon_mini.svg';

function SearchStart() {
    const { t } = useTranslation();

    return (
        <div className="search_start_section content">
            <img src={agro_logo} alt="logo" />
            <h2>{t('start_search')}</h2>
            <p>{t('enter_request')}</p>
        </div>
    );
}

export default SearchStart;