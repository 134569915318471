export function tagsFun(customTags, setTagsArray, tagsArray, setCustomTags) {
    const handleAddTag = () => {
        if (customTags.trim() === '') return;
        setTagsArray([...tagsArray, customTags.trim()]);
        setCustomTags('');
    };

    const handleRemoveTag = (index) => {
        setTagsArray(tagsArray.filter((_, i) => i !== index));
    };
    return { handleAddTag, handleRemoveTag };
}