import React from "react";

import { t } from "i18next";

import AddressList from '../components/AddressList';

export default function AddressInfo(locations, handleDeleteAddress) {
    return <div className="menu_right_section_package content tab_content">
        <h2 className="mt_0">{t('addresses')}</h2>
        <hr className="divider"></hr>
        <div className="personal_data_form_content">
            <AddressList userLocations={locations} onDeleteAddress={handleDeleteAddress} />
            <button type="submit" className="save_button">
                {t('add_address')}
            </button>
        </div>
    </div>;
}