import React, { useState, useEffect } from "react";
import "../style/Settings.css";

import { getUserInfo } from "../../../api/usercall";
import { deleteUserLocationById } from "../../../api/post/deleteUserLocation";

import Icon from "../../../common/components/Icon";

import individualTabs from "../tabs/individualTabs";
import businessTabs from "../tabs/businessTabs";

import PersonalInfo from "../tabs/PersonalInfo";
import AddressInfo from "../tabs/AddressInfo";

import GeneralInfo from "../tabs/GeneralInfo";
import AboutCompany from "../tabs/AboutCompany";
import ContactsInfo from "../tabs/ContactsInfo";
import DesignForm from "../tabs/DesignForm";

import { t } from "i18next";

function Settings({ setActiveTab }) {
    const [activeTabV, setActiveTabV] = useState("personal");
    const [userInfo, setUserInfo] = useState(null);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [locations, setLocations] = useState([]);
    const [description, setDescription] = useState(companyInfo?.about.text || "");
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleBackClick = () => {
        setActiveTab(null);
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const data = await getUserInfo();
                setUserInfo(data.user);
                setLocations(data.locations);
                setCompanyInfo(data.company_info)

                if (data.user.user_type === "business") {
                    setActiveTabV("general");
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        fetchUserData();
    }, []);

    const handleDeleteAddress = async (locationId) => {
        try {
            await deleteUserLocationById(locationId);
            setLocations((prevLocations) => prevLocations.filter(location => location.id !== locationId));
        } catch (error) {
            console.error("Error deleting address:", error);
        }
    };

    const handleDescriptionChange = (event) => {
        if (!event || !event.target) return;

        const value = event.target.value.trimStart();

        setDescription(value);
        localStorage.setItem("description", value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitted(true);

        if (!description.trim()) {
            return;
        }
        console.log("Form submitted with description:", description);
    };

    const renderTabs = () => {
        if (userInfo?.user_type === "individual") {
            return (
                individualTabs(activeTabV, setActiveTabV)
            );
        } else {
            return (
                businessTabs(activeTabV, setActiveTabV)
            );
        }
    };

    return (
        <div className="profile_render_container">
            <div className="menu_right_section_header" style={{ marginBottom: 0 }}>
                <Icon src="backicon" onClick={handleBackClick} className={"back_icon_mobile"} />
                <h2 className="menu_right_section_header_text">{t('settings')}</h2>
            </div>

            <div className="settings_tabs">
                {renderTabs()}
            </div>

            {activeTabV === "personal" && userInfo && (
                PersonalInfo(userInfo)
            )}

            {activeTabV === "address" && (
                AddressInfo(locations, handleDeleteAddress)
            )}

            {activeTabV === "general" && (
                GeneralInfo(companyInfo, userInfo)
            )}

            {activeTabV === "about" && (
                AboutCompany(handleSubmit, handleDescriptionChange, isSubmitted, description)
            )}

            {activeTabV === "contacts" && (
                ContactsInfo(companyInfo, userInfo)
            )}

            {activeTabV === "design" && (
                DesignForm(companyInfo, userInfo)
            )}
        </div>
    );
}

export default Settings;