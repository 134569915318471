import React from 'react';

import Header from '../../main/components/Header';
import Path from '../../path/ui/Path';
import MobileBottomBar from '../../main/components/MobileBottomBar';
import Fooder from '../../main/components/Fooder';

const Main = () => {
    return (
        <div className="main_container">
            <Header />
            <Path currentPath="Предпросмотр объявления" />
            <div className="announcement_content">
                <MobileBottomBar />
                <Fooder />
            </div>
        </div>
    );
};

export default Main;