import { API_URL } from '../config/connect';

const getUserTransactions = async (page = 1, limit = 10) => {
    const accessToken = localStorage.getItem("access_token");

    if (!accessToken) {
        throw new Error("Access token is missing");
    } else {
        try {
            const response = await fetch(`${API_URL}/api/v1/user/transactions`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status} - ${response.statusText}`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching announcement:', error);
            throw error;
        }
    }
};

export default getUserTransactions;